////////////////////////////////////
// BaseModal.tsx – заглушка для sheet
////////////////////////////////////
import React from 'react';

interface BaseModalProps {
    children: React.ReactNode;
    onClose: () => void;
}

export function BaseModal({ children, onClose }: BaseModalProps) {
    return (
        <div style={{
            position: 'fixed',
            top: 0, left: 0, right: 0, bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}
             onClick={onClose}
        >
            <div style={{ backgroundColor: 'white', padding: '16px', borderRadius: '8px' }} onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
}
