// src/ui/components/exercise/create_exercise/EditExerciseView.tsx
import React, { useState } from 'react';
import { EditExerciseItemView } from './EditExerciseItemView';
import {Exercise} from "../../../../domain/models";
import {ExerciseCreateInteractor} from "../../../../domain/interfaces/ExerciseCreateInteractor";
import CompositionRoot from "../../../../compositionRoot";
import {SaveExerciseInteractor} from "../../../../domain/interfaces/SaveExerciseInteractor";

interface EditExerciseViewProps {
    exercises: Exercise[];
    setExercises: (exercises: Exercise[]) => void;
    onSave: () => void;
}

const EditExerciseView: React.FC<EditExerciseViewProps> = ({
                                                                      exercises,
                                                                      setExercises,
                                                                      onSave,
                                                                  }) => {
    const [setName, setSetName] = useState<string>('');

    const saveInteractor: SaveExerciseInteractor = CompositionRoot.saveExerciseInteractor;

    const handleDelete = (id: string) => {
        setExercises(exercises.filter((exercise) => exercise.id !== id));
    };

    const addExercise = () => {
        // Implement adding a new exercise
    };

    const save = async () => {
        try {
            const exerciseSetId = crypto.randomUUID().toUpperCase();
            const exerciseSet = {
                id: exerciseSetId,
                name: setName,
                exercises: exercises,
            };
            await saveInteractor.save(exerciseSet);
            onSave();
        } catch (error) {
            console.error('Error saving exercise set:', error);
        }
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <div style={{ overflowY: 'auto' }}>
                <div style={{ width: '400px', padding: '8px' }}>
                    <label>Name</label>
                    <input
                        type="text"
                        placeholder="name"
                        value={setName}
                        onChange={(e) => setSetName(e.target.value)}
                    />
                </div>
                {exercises.map((exercise) => (
                    <EditExerciseItemView
                        key={exercise.id}
                        exercise={exercise}
                        setExercise={(updatedExercise) => {
                            setExercises(
                                exercises.map((e) => (e.id === exercise.id ? updatedExercise : e))
                            );
                        }}
                        onDelete={() => handleDelete(exercise.id)}
                    />
                ))}
            </div>
            <div style={{ position: 'absolute', right: '0', top: '0' }}>
                <div>
                    <button onClick={addExercise}>+</button>
                    <button onClick={save}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default EditExerciseView;
