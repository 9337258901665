// ========== BaseComponents/LabelButton.tsx ==========
// SwiftUI: Label("Добавить пару", systemImage: "plus.circle")
// Аналог: Кнопка с иконкой слева и текстом справа.
import React from 'react';
import { BaseIcon } from './BaseIcon';

interface LabelButtonProps {
    text: string;
    systemImage: string;
    onClick: () => void;
}

export function LabelButton({ text, systemImage, onClick }: LabelButtonProps) {
    return (
        <button onClick={onClick} style={{ display: 'inline-flex', alignItems: 'center', gap: '4px' }}>
            <BaseIcon name={systemImage} />
            <span>{text}</span>
        </button>
    );
}
