// ui/components/lesson/ExerciseRowView.tsx
import React from "react";
import { Exercise } from "../../../domain/models";
import {createViewable} from "./exerciseViewableFactory";

/**
 * Аналог SwiftUI ExerciseRowView.
 * "exercise.viewData.renderView" в React — это метод класса ExerciseViewable,
 * который возвращает JSX. Предположим, он доступен в exercise.viewData.
 */

interface Props {
    exercise: Exercise;
}

export const ExerciseRowView = ({ exercise }: Props) => {
    const viewable = createViewable(exercise);

    return <div style={{ flex: 1 }}>{viewable.renderView(exercise)}</div>;
};