// BaseComponents/BaseText.tsx
import React from 'react';

type FontVariant = 'headline' | 'title' | 'largeTitle' | 'subheadline'; // Добавили 'subheadline'

interface BaseTextProps {
    children?: React.ReactNode;
    font?: FontVariant;
    paddingBottom?: number;
    backgroundColor?: string;
    cornerRadius?: number;
    frameWidth?: number;
    frameHeight?: number;
    onClick?: () => void;
}

export function BaseText({ children, font, paddingBottom, backgroundColor, cornerRadius, frameWidth, frameHeight, onClick }: BaseTextProps) {
    let fontSize: string | undefined;
    switch (font) {
        case 'headline':
            fontSize = '1.25rem'; break;
        case 'title':
            fontSize = '1.5rem'; break;
        case 'largeTitle':
            fontSize = '2rem'; break;
        case 'subheadline':
            fontSize = '1rem'; break;
    }

    return <div
        style={{
            fontSize,
            paddingBottom: paddingBottom ? `${paddingBottom}px` : undefined,
            backgroundColor,
            borderRadius: cornerRadius ? `${cornerRadius}px` : undefined,
            width: frameWidth ? `${frameWidth}px` : undefined,
            height: frameHeight ? `${frameHeight}px` : undefined,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxSizing: 'border-box',
            textAlign: 'center'
        }}
        onClick={onClick}
    >
        {children}
    </div>;
}
