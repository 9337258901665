// src/components/start/StartScreen.tsx

import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BaseButton, {ButtonVariant} from '../BaseButton';
import CompositionRoot from '../../../compositionRoot';
import {AppUser} from '../../../domain/models/AppUser';
import WordsViewModel from '../../viewmodels/WordsViewModel';
import ShortStatsView from '../statistics/ShortStatsView';

const StartScreen: React.FC = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState<AppUser | null>(null);
    const [wordsViewModel, setWordsViewModel] = useState<WordsViewModel | null>(null);

    useEffect(() => {
        // Получаем информацию о текущем пользователе из AppUserManager
        const appUserManager = CompositionRoot.getAppUserManager();
        setUser(appUserManager.currentUser);

        // Получаем WordsServiceProtocol через CompositionRoot и создаем WordsViewModel
        const wordsService = CompositionRoot.getWordsService();
        const viewModel = new WordsViewModel(wordsService);
        setWordsViewModel(viewModel);

        // Подписываемся на изменения текущего пользователя
        const userPublisher = appUserManager.currentUserPublisher();
        const subscription = userPublisher.subscribe(setUser);

        // Отписываемся при размонтировании и освобождаем ресурсы ViewModel
        return () => {
            subscription.unsubscribe();
            viewModel.dispose();
        };
    }, []);

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            {/* 1. Кнопки Settings / Statistics + ShortStatsView в правом верхнем углу */}
            <div
                style={{
                    position: 'absolute',
                    top: '16px',
                    right: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    gap: '10px',
                }}
            >
                <BaseButton color="#ff9800" onClick={() => navigate('/settings')}>
                    Settings
                </BaseButton>
                <BaseButton color="#ff9800" onClick={() => navigate('/mainStat')}>
                    Statistics
                </BaseButton>
                {wordsViewModel && <ShortStatsView wordsViewModel={wordsViewModel} />}
            </div>

            {/* 2. Две основные группы кнопок по центру (первая чуть левее, вторая чуть правее) */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    gap: '100px',
                }}
            >
                {/* Левая группа: Add words / Study words */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <BaseButton color="#00bcd4" onClick={() => navigate('/mainAdd')} variant={ ButtonVariant.Big }>
                        Add words
                    </BaseButton>
                    <BaseButton color="#00bcd4" onClick={() => navigate('/mainStudy')} variant={ ButtonVariant.Big }>
                        Study words
                    </BaseButton>
                </div>

                {/* Правая группа: All exercises / Create lesson / Practice */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <BaseButton color="#0070d2" onClick={() => navigate('/createLesson')} variant={ ButtonVariant.Big }>
                        Create lesson
                    </BaseButton>
                    <BaseButton color="#0070d2" onClick={() => navigate('/lessonsList')} variant={ ButtonVariant.Big }>
                        Practice
                    </BaseButton>
                </div>
            </div>

            {/* 3. Все остальные кнопки в нижний правый угол, серого цвета */}
            <div
                style={{
                    position: 'absolute',
                    bottom: '16px',
                    right: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                }}
            >
                <BaseButton color="#0070d2" onClick={() => navigate('/allExercises')}>
                    All exercises
                </BaseButton>
                <BaseButton color="#9e9e9e" onClick={() => navigate('/delayedSets')}>
                    Delayed
                </BaseButton>
                <BaseButton color="#9e9e9e" onClick={() => navigate('/students')}>
                    Students
                </BaseButton>
                <BaseButton color="#9e9e9e" onClick={() => navigate('/practiceResultsList')}>
                    Practice results
                </BaseButton>
                <BaseButton color="#9e9e9e" onClick={() => navigate('/createExercise')}>
                    Create exercise
                </BaseButton>
                <BaseButton color="#9e9e9e" onClick={() => navigate('/createFillBlanks')}>
                    Create fill blanks
                </BaseButton>
                <BaseButton color="#9e9e9e" onClick={() => navigate('/fillBlanksList')}>
                    Fill blank exercises list
                </BaseButton>
            </div>
        </div>
    );

};

export default StartScreen;
