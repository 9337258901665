import {
    Exercise,
    ExerciseAnagram,
    ExerciseHangman,
    ExerciseMatchingPairs,
    ExerciseMissingWord,
    ExerciseTable
} from "../../../domain/models";
import {ExerciseViewable} from "../exercises_types/ExerciseViewable";
import {ExerciseAnagramViewable} from "../exercises_types/Anagram/Preview/AnagramViewable";
import {MissingWordViewable} from "../exercises_types/MissingWord/Preview/MissingWordViewable";
import {ExerciseTableViewable} from "../exercises_types/Table/Preview/TableViewable";
import {ExerciseHangmanViewable} from "../exercises_types/Hangman/Preview/HangmanViewable";
import {ExerciseMatchingPairsViewable} from "../exercises_types/MatchingPairs/Preview/MatchingPairsViewable";

export function createViewable(exercise: Exercise): ExerciseViewable {
    switch (exercise.type.kind) {
        case "anagram":
            return new ExerciseAnagramViewable(exercise.type.data as ExerciseAnagram);
        case "missingWord":
            return new MissingWordViewable(exercise.type.data as ExerciseMissingWord);
        case "table":
            return new ExerciseTableViewable(exercise.type.data as ExerciseTable)
        case "hangman":
            return new ExerciseHangmanViewable(exercise.type.data as ExerciseHangman)
        case "matchingPairs":
            return new ExerciseMatchingPairsViewable(exercise.type.data as ExerciseMatchingPairs)
        default:
            // fallback, либо класс-заглушка
            return {
                renderView: () => <div>Unsupported exercise type</div>,
            };
    }
}