// AnagramLearnView.swift -> AnagramLearnView.tsx
import React from 'react';
import { AnagramLearnViewModel } from './AnagramLearnViewModel';
import {AnagramViewBase} from "../Base/AnagramViewBase";

interface AnagramLearnViewProps {
    viewModel: AnagramLearnViewModel;
}

export function AnagramLearnView({ viewModel }: AnagramLearnViewProps) {
    return (
        <AnagramViewBase
            displayedWord={viewModel.displayedWord}
            currentAnswer={Array.from(viewModel.state.currentAnswer)}
            scrambledLetters={Array.from(viewModel.state.scrambledLetters)}
            onLetterTap={(index) => viewModel.selectLetter(index)}
            onAnswerTap={(index) => viewModel.removeLetterFromAnswer(index)}
        />
    );
}
