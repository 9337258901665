// Preview/TableViewable.tsx
import React from 'react';
import {Exercise, ExerciseHangman, ExerciseTable} from "../../../../../domain/models";
import {ExerciseViewable} from "../../ExerciseViewable";
import {Resizer} from "../../../Resizer";
import {PracticeHangmanViewModel} from "../Practice/PracticeHangmanViewModel";
import {PracticeHangmanView} from "../Practice/PracticeHangmanView";

export class ExerciseHangmanViewable implements ExerciseViewable {
    constructor(private exerciseData: ExerciseHangman) {}

    renderView(exercise: Exercise): JSX.Element {
        if (exercise.type.kind !== 'hangman') {
            throw new Error('Unsupported exercise type for ExerciseHangman: ExerciseViewable');
        }

        return (
            <Resizer targetSize={{ width: 300, height: 150 }}>
                <PracticeHangmanView viewModel={ new PracticeHangmanViewModel(exercise, undefined, undefined)} />
            </Resizer>
        );
    }
}