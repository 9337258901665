// NotesInteractor.ts

import { makeAutoObservable, runInAction } from 'mobx';
import { NotesState, NotesItem } from './NotesState';
import { NoteRepository } from './NoteRepository';
import {MediaUploading} from "../../../domain/interfaces/MediaTypes";
import {ImageUploading} from "../../../data/implementations/Files/ImageUploader";

export class NotesInteractor {
    private repository: NoteRepository;
    private noteId: string;
    private mediaUploader: MediaUploading;
    private imageUploader: ImageUploading;

    // Наша "обсервабельная" часть состояния:
    public state: NotesState;

    constructor(
        noteId: string,
        initialState: NotesState,
        repository: NoteRepository,
        mediaUploader: MediaUploading,
        imageUploader: ImageUploading
    ) {
        makeAutoObservable(this);
        this.noteId = noteId;
        this.state = initialState;
        this.repository = repository;
        this.mediaUploader = mediaUploader;
        this.imageUploader = imageUploader;

        this.subscribeToServerUpdates();
    }

    // Храним функцию отписки, если нужно впоследствии остановить подписку
    private unsubscribeFn: (() => void) | null = null;

    private subscribeToServerUpdates() {
        // Теперь метод subscribeToUpdates возвращает функцию для отписки
        this.unsubscribeFn = this.repository.subscribeToUpdates(
            this.noteId,
            (newState) => {
                if (!newState) {
                    return;
                }
                // Если у полученного состояния версия выше, чем локальная,
                // обновляем локальное состояние, но без инкремента.
                if (newState.version > this.state.version) {
                    this.updateState(newState, false);
                }
            }
        );
    }

    // Если когда-то нужно отписаться (например, при разрушении объекта),
    // можно вызвать этот метод:
    public unsubscribe() {
        if (this.unsubscribeFn) {
            this.unsubscribeFn();
            this.unsubscribeFn = null;
        }
    }

    public addTextItem() {
        const newState = { ...this.state };
        const newItem: NotesItem = {
            id: crypto.randomUUID(),
            content: { kind: 'text', text: '' },
        };
        newState.items = [...newState.items, newItem];
        this.updateState(newState);
    }

    /**
     * Добавляет файл (изображение/аудио/видео/др.) в NotesState
     * @param file - Выбранный пользователем файл
     */
    public async addFileItem(file: File): Promise<void> {
        // Получаем расширение из имени файла
        const ext = file.name.split('.').pop()?.toLowerCase() || '';
        let remoteURL: string;
        const newState = { ...this.state };

        // Проверяем, является ли расширение изображением
        if (['jpg', 'jpeg', 'png', 'gif', 'heic', 'heif', 'tiff', 'bmp'].includes(ext)) {
            // Загружаем как изображение
            const wordId = crypto.randomUUID();
            remoteURL = await this.imageUploader.uploadImage(file, wordId);
            newState.items.push({
                id: crypto.randomUUID(),
                content: { kind: 'image', url: new URL(remoteURL) },
            });
        }
        // Проверяем, является ли расширение видео
        else if (['mp4', 'mov', 'm4v'].includes(ext)) {
            const fileName = crypto.randomUUID();
            remoteURL = await this.mediaUploader.uploadFile(file, fileName, ext);
            newState.items.push({
                id: crypto.randomUUID(),
                content: { kind: 'video', url: new URL(remoteURL) },
            });
        }
        // Проверяем, является ли расширение аудио
        else if (['mp3', 'wav', 'm4a', 'aac'].includes(ext)) {
            const fileName = crypto.randomUUID();
            remoteURL = await this.mediaUploader.uploadFile(file, fileName, ext);
            newState.items.push({
                id: crypto.randomUUID(),
                content: { kind: 'audio', url: new URL(remoteURL) },
            });
        }
        // Если формат неизвестен, добавляем как "link" (или любая логика по вашему желанию)
        else {
            // Для наглядности создадим link на локальный file://
            newState.items.push({
                id: crypto.randomUUID(),
                content: { kind: 'link', url: new URL('file://' + file.name) },
            });
        }

        this.updateState(newState);
    }

    /**
     * Обновляет текст в NotesItem, если его content — это kind: 'text'
     */
    public updateText(itemID: string, newText: string) {
        const index = this.state.items.findIndex((item) => item.id === itemID);
        if (index < 0) return;

        const item = this.state.items[index];
        if (item.content.kind === 'text') {
            const newState = { ...this.state };
            newState.items = [...newState.items];
            newState.items[index] = {
                ...item,
                content: {
                    kind: 'text',
                    text: newText,
                },
            };
            this.updateState(newState);
        }
    }

    /**
     * Перемещаем элементы внутри массива items
     */
    public moveItems(fromOffsets: number[], toOffset: number) {
        const newState = { ...this.state };
        newState.items = moveFromOffsets(newState.items, fromOffsets, toOffset);
        this.updateState(newState);
    }

    /**
     * Удаляет items по индексам
     */
    public deleteItems(offsets: number[]) {
        const newState = { ...this.state };
        newState.items = removeAtOffsets(newState.items, offsets);
        this.updateState(newState);
    }

    private updateState(newState: NotesState, incrementVersion = true) {
        const modifiedState = { ...newState };
        if (incrementVersion) {
            modifiedState.version += 1;
        }

        runInAction(() => {
            this.state = modifiedState;
        });

        // Сохраняем новое состояние в репозиторий
        this.repository.saveState(this.noteId, this.state);
    }
}

function removeAtOffsets<T>(arr: T[], offsets: number[]): T[] {
    // Sort offsets descending so we remove from the end first
    const sorted = [...offsets].sort((a, b) => b - a);
    const copy = [...arr];
    for (const offset of sorted) {
        if (offset < copy.length) {
            copy.splice(offset, 1);
        }
    }
    return copy;
}

function moveFromOffsets<T>(arr: T[], offsets: number[], toOffset: number): T[] {
    const copy = [...arr];
    const elements = offsets
        .filter((off) => off < copy.length)
        .map((off) => copy[off]);

    // Remove from original positions (descending order)
    for (const offset of [...offsets].sort((a, b) => b - a)) {
        if (offset < copy.length) {
            copy.splice(offset, 1);
        }
    }

    // Insert elements at the new position
    const safeToOffset = Math.max(0, Math.min(toOffset, copy.length));
    copy.splice(safeToOffset, 0, ...elements);

    return copy;
}
