// LessonsList.tsx
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { LessonsListViewModel } from './LessonListViewModel';
import ShareButton from "../exercises_types/BaseComponents/ShareButton";

const LessonsList: React.FC = observer(() => {
    const navigate = useNavigate();
    const [viewModel] = useState(() => new LessonsListViewModel(navigate));

    useEffect(() => {
        viewModel.loadLessons();
    }, [viewModel]);

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Lessons list</h2>
            {viewModel.lessons.map((lesson) => (
                <div key={lesson.id} style={styles.card}>
                    <h3 style={styles.lessonTitle}>{lesson.name}</h3>
                    <ul style={styles.exerciseList}>
                        {lesson.exercises.map((ex) => (
                            <li key={ex.id} style={styles.exerciseItem}>
                                {ex.info}
                            </li>
                        ))}
                    </ul>
                    <div style={styles.buttonRow}>
                        <button
                            style={styles.lessonButton}
                            onClick={() => viewModel.onLessonCardClick(lesson.id)}
                        >
                            Open lesson
                        </button>
                        <ShareButton link={`${window.location.origin}/lessonPractice/${lesson.id}`} />
                    </div>
                </div>
            ))}
        </div>
    );
});

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '16px',
    },
    header: {
        marginBottom: '24px',
        textAlign: 'center',
        color: '#fff',
    },
    card: {
        border: '1px solid #444',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '16px',
        backgroundColor: '#1C1C1C',
    },
    lessonTitle: {
        margin: '0 0 8px 0',
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#fff',
    },
    exerciseList: {
        margin: '0 0 16px',
        padding: '0 0 0 16px',
        color: '#ccc',
    },
    exerciseItem: {
        marginBottom: '4px',
    },
    buttonRow: {
        display: 'flex',
        gap: '8px',
    },
    lessonButton: {
        padding: '8px 16px',
        fontSize: '16px',
        cursor: 'pointer',
        backgroundColor: '#2E2E2E',
        color: '#fff',
        border: '1px solid #555',
        borderRadius: '4px',
    },
    shareButton: {
        padding: '8px 16px',
        fontSize: '16px',
        cursor: 'pointer',
        backgroundColor: '#444',
        color: '#fff',
        border: '1px solid #666',
        borderRadius: '4px',
    },
};

export default LessonsList;