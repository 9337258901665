// SmallPreview/TableTypePreview.tsx
import React from 'react';
import {Resizer} from "../../../Resizer";
import HangmanGameView from "../Base/HangmanGameView";
import {PracticeHangmanState} from "../Practice/PracticeHangmanState";

export function HangmanTypePreview() {
    const mockData: PracticeHangmanState = createMockData();

    return (
        <Resizer targetSize={{ width: 200, height: 150 }}>
            <HangmanGameView
                // Передаём нужные данные из state
                word={mockData.targetWord}
                hint={mockData.hint}
                // Когда игра завершилась, шлём сигнал во ViewModel
                onGameEnd={() => {}}
                // А также передаём метод, чтобы HangmanGameView мог «угадать букву»
                guessLetter={(letter) => {}}
                // Остальные параметры для отрисовки (currentStage и т.д.)
                currentStage={mockData.currentStage}
                displayedWord={mockData.displayed}
                isGameOver={mockData.isGameOver}
                isWin={mockData.isWin}
            />
        </Resizer>
    );
}

function createMockData(): PracticeHangmanState {
    return {
        targetWord: "EXAMPLE", // Загаданное слово
        hint: "A sample word for demonstration", // Подсказка
        guessedLetters: ["E", "X", "A"], // Угаданные буквы
        mistakes: 5, // Количество ошибок
        maxMistakes: 6, // Максимально допустимое количество ошибок
        isGameOver: false, // Флаг окончания игры
        isWin: false, // Флаг победы

        // Отображение для UI
        displayed: "E X A _ _ _ _", // Слово с подчеркиваниями для неугаданных букв
        currentStage: 5, // Текущая стадия игры (например, 2 из 6)
    };
}
