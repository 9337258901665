// CreateTableUseCase.ts

import {TableRequester, TableRequesting} from './TableRequester';
import {TableModel} from "./Models/TableModel";
import {TableTextElement} from "./Models/TableTextElement";

/**
 * Аналог Swift public protocol CreateTableUseCaseProtocol
 */
export interface CreateTableUseCaseProtocol {
    fill(model: TableModel, text: string): Promise<TableModel>;
    fillRow(model: TableModel, rowIndex: number, text: string): Promise<TableTextElement[]>;
}

/**
 * Аналог Swift public struct CreateTableUseCase
 */
export class CreateTableUseCase implements CreateTableUseCaseProtocol {
    private tableRequester: TableRequesting;

    constructor(tableRequester: TableRequesting) {
        this.tableRequester = tableRequester;
    }

    public async fill(model: TableModel, text: string): Promise<TableModel> {
        // Swift: try await tableRequester.fill(tableModel: model, with: text)
        const filledModel = await this.tableRequester.fill(model, text);

        // Swift: merge columns
        const mergedColumns = model.columns.map((originalCol, i) => {
            const filledCol = filledModel.columns[i];
            // merge header
            const mergedHeader = this.mergeElements(originalCol.header, filledCol.header);
            // merge items
            const mergedItems = originalCol.items.map((origItem, idx) => {
                const fillItem = filledCol.items[idx] || { kind: 'editor', value: '' };
                return this.mergeElements(origItem, fillItem);
            });
            return { header: mergedHeader, items: mergedItems };
        });

        return { columns: mergedColumns, stableCells: model.stableCells };
    }

    /**
     * Аналог Swift: fillRow(...)
     * 1) makeSingleRowModel
     * 2) tableRequester.fill(partialModel)
     * 3) слияем/возвращаем Array<TableTextElement> (newRowItems)
     */
    public async fillRow(model: TableModel, rowIndex: number, text: string): Promise<TableTextElement[]> {
        // console.log("[fillRow] Called with rowIndex =", rowIndex, ", text =", text);
        // console.log("[fillRow] Current TableModel (model):", JSON.stringify(model, null, 2));

        // 1) partialModel
        const partialModel = this.makeSingleRowModel(model, rowIndex);
        // console.log("[fillRow] partialModel columnsCount:", partialModel.columns.length);
        // partialModel.columns.forEach((col, idx) => {
            // console.log(`[fillRow] partialModel.col[${idx}].header =`, col.header);
            // console.log(`[fillRow] partialModel.col[${idx}].items =`, JSON.stringify(col.items));
        // });

        // 2) tableRequester.fill(...)
        // console.log("[fillRow] Calling tableRequester.fill(...) with partialModel + text");
        const filledRowModel = await this.tableRequester.fill(partialModel, text);
        // console.log("[fillRow] Received filledRowModel:", JSON.stringify(filledRowModel, null, 2));

        // console.log("[fillRow] Start merging old items with newly filled items...");

        // 3) Merge
        const newRowItems: TableTextElement[] = [];
        // используем min, чтоб не вылететь за границы
        const columnsCount = Math.min(model.columns.length, filledRowModel.columns.length);

        for (let colIndex = 0; colIndex < columnsCount; colIndex++) {
            const origCol = model.columns[colIndex];
            const filledCol = filledRowModel.columns[colIndex];
            // console.log(`[fillRow] merge step colIndex=${colIndex}, filledCol=`, JSON.stringify(filledCol));

            // oldItem
            const oldItemAvailable = rowIndex < origCol.items.length;
            // console.log(`[fillRow] oldItemAvailable=${oldItemAvailable}, rowIndex=${rowIndex}, model.columns[${colIndex}]?`);
            const oldItem: TableTextElement = oldItemAvailable
                ? origCol.items[rowIndex]
                : { kind: "editor", value: "" };
            // console.log("[fillRow] oldItem:", oldItem);

            // filledItem
            const fillItem: TableTextElement = filledCol.items[0] || { kind: "editor", value: "" };
            // console.log("[fillRow] fillItem:", fillItem);

            // merge
            const merged = this.mergeElements(oldItem, fillItem);
            // console.log("[fillRow] merged result:", merged);

            newRowItems.push(merged);
        }

        // console.log("[fillRow] Final newRowItems:", JSON.stringify(newRowItems, null, 2));
        return newRowItems;
    }

    // Helpers

    /**
     * Создаёт частичную модель (partialModel),
     * где у каждого столбца items = [ rowIndex-й item ],
     * либо пустой .editor("") если нет
     */
    private makeSingleRowModel(model: TableModel, rowIndex: number): TableModel {
        const singleRowCols = model.columns.map((col, cIndex) => {
            const rowItem =
                rowIndex < col.items.length
                    ? col.items[rowIndex]
                    : { kind: "editor", value: "" };
            return { header: col.header, items: [rowItem] };
        });
        return <TableModel>{
            columns: singleRowCols,
            stableCells: model.stableCells,
        };
    }

    /**
     * Аналог Swift mergeElements:
     * if original= .text => keep original,
     * else => use filled
     */
    private mergeElements(original: TableTextElement, filled: TableTextElement): TableTextElement {
        if (original.kind === "text") {
            return original;
        } else {
            return filled;
        }
    }
}