// SimilarTableUseCase.ts

import {TableRequester, TableRequesting} from './TableRequester';
import {ExerciseTable} from "../../models";
import {exerciseTableToModel, modelToExerciseTable} from "./Models/Mapping";

/**
 * Аналог Swift протокола SimilarTableUseCaseProtocol
 */
export interface SimilarTableUseCaseProtocol {
    createSimilarExerciseTable(fromBase: ExerciseTable): Promise<ExerciseTable>;
}

/**
 * Аналог Swift struct SimilarTableUseCase
 */
export class SimilarTableUseCase implements SimilarTableUseCaseProtocol {
    private tableRequester: TableRequesting;

    constructor(tableRequester: TableRequesting) {
        this.tableRequester = tableRequester;
    }

    public async createSimilarExerciseTable(fromBase: ExerciseTable): Promise<ExerciseTable> {
        // 1) Преобразуем ExerciseTable -> TableModel
        const model = exerciseTableToModel(fromBase);
        // 2) Вызываем createSimilar(tableModel)
        const similarModel = await this.tableRequester.createSimilar(model);
        // 3) Превращаем назад в ExerciseTable
        const similarExercise = modelToExerciseTable(similarModel);
        return similarExercise;
    }
}