// Заглушки для интерактора и прочих зависимостей
import {WordItem} from "../../models";
import {AnagramState} from "./AnagramState";

export class AnagramLearnInteractor {
    state: AnagramState;
    onStateChange: ((newState: AnagramState) => void) | null = null;

    constructor(args: { wordItem: WordItem, delegate?: any, uiHandler?: any, useCase?: any }) {
        this.state = {
            displayedWord: args.wordItem.translation,
            currentAnswer: '_'.repeat(args.wordItem.word.length),
            scrambledLetters: args.wordItem.word.split('').sort(() => Math.random() - 0.5).join('')
        };
    }

    selectLetter(at: number) {
        // Логика обновления state по выбору буквы
        // Для примера упростим
        this.onStateChange && this.onStateChange(this.state);
    }

    removeLetterFromAnswer(at: number) {
        // Логика удаления буквы из ответа
        this.onStateChange && this.onStateChange(this.state);
    }

    goToNext() {
        // Логика перехода к следующему
        this.onStateChange && this.onStateChange(this.state);
    }
}