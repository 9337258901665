// ========== BaseComponents/BaseTextField.tsx ==========
import React, { useRef, useEffect } from 'react';

interface BaseTextFieldProps {
    placeholder?: string;
    value: string;
    onChange: (newValue: string) => void;
    onEnter?: () => void;
    fontSize?: number;
    controlSize?: 'large' | 'default';
    frameMaxWidth?: number;
    autoFocus?: boolean;
}

export function BaseTextField({
                                  placeholder,
                                  value,
                                  onChange,
                                  onEnter,
                                  fontSize,
                                  controlSize,
                                  frameMaxWidth,
                                  autoFocus
                              }: BaseTextFieldProps) {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (autoFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [autoFocus]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onEnter) {
            onEnter();
        }
    };

    return (
        <input
            ref={inputRef}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={handleKeyDown}
            style={{
                fontSize: fontSize ? `${fontSize}px` : undefined,
                maxWidth: frameMaxWidth ? `${frameMaxWidth}px` : undefined,
                padding: controlSize === 'large' ? '10px' : '5px',
                borderRadius: '4px',
                border: '1px solid #ccc'
            }}
        />
    );
}