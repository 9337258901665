// ui/components/exercise/AllExercisesPickerView.tsx

import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { ExercisesListPickerViewModel } from "./ExercisesListPickerViewModel";
import { Exercise } from "../../../domain/models";
import {FilterSegmented} from "../AllExercises/FilterSegmented";
import {ExerciseTextPreview} from "../AllExercises/ExerciseTextPreview";

interface AllExercisesPickerViewProps {
    onSelect: (exercise: Exercise) => void;
    onClose: () => void;
}

export const AllExercisesPickerView = observer((props: AllExercisesPickerViewProps) => {
    // Локальный ViewModel (как StateObject в Swift)
    const [viewModel] = useState(() => new ExercisesListPickerViewModel());

    // Загрузим упражнения при маунте компонента (аналог .onAppear)
    useEffect(() => {
        viewModel.load();
    }, [viewModel]);

    return (
        <div>
            {/* Аналог Picker("Filter", selection: $viewModel.selectedFilter) */}
            <div style={{ padding: 16 }}>
                <FilterSegmented
                    value={viewModel.selectedFilter}
                    onChange={(newVal) => {
                        viewModel.selectedFilter = newVal;
                    }}
                />
            </div>

            {/* Сетка упражнений (аналог LazyVGrid) */}
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                    gap: "16px",
                    padding: "16px",
                }}
            >
                {viewModel.filteredExercises.map((exercise) => (
                    <div
                        key={exercise.id}
                        style={{ border: "1px solid #ccc", cursor: "pointer", padding: "8px" }}
                        onClick={() => props.onSelect(exercise)}
                    >
                        <ExerciseTextPreview exercise={exercise} />
                    </div>
                ))}
            </div>

            {/* Кнопка "Close" (если нужна, зависит от UI) */}
            <div style={{ marginTop: 16, textAlign: "right" }}>
                <button onClick={props.onClose}>Close</button>
            </div>
        </div>
    );
});