// ========== MissingWord/Base/MissingWordViewBase.tsx ==========

import React, { useEffect, useState } from 'react';
import { BaseView } from '../../BaseComponents/BaseView';
import { BaseVStack } from '../../BaseComponents/BaseVStack';
import { BaseText } from '../../BaseComponents/BaseText';
import { BaseTextField } from '../../BaseComponents/BaseTextField';
import { BaseButton } from '../../BaseComponents/BaseButton';

interface MissingWordViewBaseProps {
    sentence: string;
    inputText: string;
    isCorrect: boolean | null;
    correctAnswer: string;
    onInputChange: (value: string) => void;
    onEnterPressed: () => void;
    onCheckPressed: () => void;
    onNextPressed: () => void;
}

export function MissingWordViewBase({
                                        sentence,
                                        inputText,
                                        isCorrect,
                                        correctAnswer,
                                        onInputChange,
                                        onEnterPressed,
                                        onCheckPressed,
                                        onNextPressed
                                    }: MissingWordViewBaseProps) {

    // В SwiftUI был @FocusState и .onAppear для фокуса
    // В React мы используем autoFocus в BaseTextField
    // Если нужно имитировать delayed focus:
    const [autoFocus, setAutoFocus] = useState(false);
    useEffect(() => {
        // Аналог DispatchQueue.main.async { isFocused = true }
        // Выполним фокус после монтирования
        setAutoFocus(true);
    }, []);

    return (
        <BaseVStack>
            <BaseText font="largeTitle">
                {sentence}
            </BaseText>

            <BaseTextField
                placeholder="Введите слово"
                value={inputText}
                onChange={onInputChange}
                onEnter={onEnterPressed}
                fontSize={20}
                controlSize="large"
                frameMaxWidth={400}
                autoFocus={autoFocus}
            />

            {isCorrect === null && (
                <BaseButton text="check" onPress={onCheckPressed} />
            )}
            {isCorrect === false && (
                <>
                    <BaseText>Wrong</BaseText>
                    <BaseText>Correct answer: {correctAnswer}</BaseText>
                    <BaseButton text="Next" onPress={onNextPressed} />
                </>
            )}
        </BaseVStack>
    );
}
