// BaseComponents/BaseHStack.tsx
import React from 'react';

interface BaseHStackProps {
    children?: React.ReactNode;
    padding?: number;
    paddingBottom?: number;
    style?: React.CSSProperties; // Добавлено
}

export function BaseHStack({ children, padding, paddingBottom, style }: BaseHStackProps) {
    return <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        padding: padding ? `${padding}px` : undefined,
        marginBottom: paddingBottom ? `${paddingBottom}px` : undefined,
        ...style // Раскладываем style
    }}>
        {children}
    </div>;
}