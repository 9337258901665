// Base/TableRowView.tsx
import React from 'react';
import { ElementView } from './ElementView';
import {CellCoordinate} from "../../../../../domain/Exercises/Table/Models/CellCoordinate";
import {TableTextElement} from "../../../../../domain/Exercises/Table/Models/TableTextElement";
import {TableModel, TableModelColumn} from "../../../../../domain/Exercises/Table/Models/TableModel";

interface TableRowViewProps {
    columns: TableModel['columns'];
    rowIndex: number | null;
    stableCells: Set<CellCoordinate>;

    toggleStable: (rowIndex: number, columnIndex: number) => void;
    updatePlaceholder: (columnIndex: number, rowIndex: number | null, userInput: string) => void;
    confirmPressed: (columnIndex: number, rowIndex: number | null) => void;
    editPressed: (columnIndex: number, rowIndex: number | null) => void;
    fillRow: (rowIndex: number) => void;
    removeRow: (rowIndex: number) => void;
    isLoading: boolean;

    columnWidth: number;
    rowHeight: number;
    lineColor: string;
    lineThickness: number;
    actionsColumnWidth: number;
}

export function TableRowView(props: TableRowViewProps) {
    const {
        columns,
        rowIndex,
        stableCells,
        toggleStable,
        updatePlaceholder,
        confirmPressed,
        editPressed,
        fillRow,
        removeRow,
        isLoading,
        actionsColumnWidth,
    } = props;

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            {/* Левый столбец (кнопка удаления или пустое место, если это заголовок) */}
            <div
                style={{
                    width: 60,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // Здесь никаких бордеров, чтобы кнопка казалась отдельной
                }}
            >
                {rowIndex !== null && (
                    <button onClick={() => removeRow(rowIndex)} style={{ color: "red" }}>
                        🗑
                    </button>
                )}
            </div>

            {/* Основная часть строки (столбцы + actions-колонка) */}
            <div style={{ display: "flex", flexDirection: "row" }}>
                {/* Столбцы */}
                {columns.map((col, colIndex) => {
                    const { element, stable } = getElementAndStable(
                        col,
                        colIndex,
                        rowIndex,
                        stableCells
                    );

                    return (
                        <div
                            key={colIndex}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                border: "1px solid #ccc",
                            }}
                        >
                            <ElementView
                                element={element}
                                isStable={stable}
                                updatePlaceholder={(newVal) =>
                                    updatePlaceholder(colIndex, rowIndex, newVal)
                                }
                                onTap={() => {
                                    if (rowIndex !== null) {
                                        toggleStable(rowIndex, colIndex);
                                    }
                                }}
                            />
                        </div>
                    );
                })}

                {/* Actions-колонка (подтвердить/редактировать, fillRow и т.д.) */}
                <div
                    style={{
                        width: actionsColumnWidth,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingLeft: 20,
                        paddingRight: 20,
                    }}
                >
                    {rowIndex === null
                        ? renderHeaderActions()
                        : renderBodyActions(rowIndex)}
                </div>
            </div>
        </div>
    );

    function renderHeaderActions() {
        const hasPlaceholder = columns.some((col) => col.header.kind === "editor");
        return hasPlaceholder ? (
            <button
                onClick={() => {
                    for (let colIndex = 0; colIndex < columns.length; colIndex++) {
                        confirmPressed(colIndex, null);
                    }
                }}
            >
                ✓
            </button>
        ) : (
            <button
                onClick={() => {
                    for (let colIndex = 0; colIndex < columns.length; colIndex++) {
                        editPressed(colIndex, null);
                    }
                }}
            >
                ✎
            </button>
        );
    }

    function renderBodyActions(rIndex: number) {
        const hasPlaceholder = columns.some(
            (col) => rIndex < col.items.length && col.items[rIndex].kind === "editor"
        );

        return (
            <>
                {/* confirm or edit */}
                {hasPlaceholder ? (
                    <button
                        onClick={() => {
                            for (let colIndex = 0; colIndex < columns.length; colIndex++) {
                                confirmPressed(colIndex, rIndex);
                            }
                        }}
                    >
                        ✓
                    </button>
                ) : (
                    <button
                        onClick={() => {
                            for (let colIndex = 0; colIndex < columns.length; colIndex++) {
                                editPressed(colIndex, rIndex);
                            }
                        }}
                    >
                        ✎
                    </button>
                )}

                {/* fill row */}
                {isLoading ? (
                    <span>Loading...</span>
                ) : (
                    <button onClick={() => fillRow(rIndex)}>Fill Row</button>
                )}
            </>
        );
    }
}


// Helper
function getElementAndStable(col: TableModelColumn, colIndex: number, rowIndex: number | null, stableCells: Set<CellCoordinate>) {
    if (rowIndex === null) {
        // header
        return {
            element: col.header,
            stable: true  // Swift code sets "header is stable"
        };
    } else {
        if (rowIndex < col.items.length) {
            const item = col.items[rowIndex];
            const coord: CellCoordinate = CellCoordinate.create(colIndex, rowIndex);
            const stable = stableCells.has(coord);
            return { element: item, stable };
        } else {
            // out-of-bounds => editor("") or something
            return {
                element: { kind: 'editor', value: '' } as TableTextElement,
                stable: false
            };
        }
    }
}