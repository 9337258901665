import React, {useRef, useLayoutEffect, useState, useEffect, useCallback, CSSProperties} from 'react';
import { observer } from "mobx-react-lite";
import { BaseView } from '../../BaseComponents/BaseView';
import { BaseVStack } from '../../BaseComponents/BaseVStack';
import { BaseText } from '../../BaseComponents/BaseText';
import { BaseModal } from '../../BaseComponents/BaseModal';
import { ItemsGridView } from '../Base/Supporting/ItemsGridView';
import { ZoomableImageView } from '../../BaseComponents/ZoomableImageView';
import { VideoPlayerView } from '../../BaseComponents/VideoPlayerView';
import { ContentType } from '../../../../../domain/models';
import { MatchingPairsExerciseViewModel } from './MatchingPairsExerciseViewModel';
import {useResizeObserver} from "../../BaseComponents/useResizeObserver";

interface MatchingPairsExerciseViewProps {
    viewModel: MatchingPairsExerciseViewModel;
}

export const MatchingPairsExerciseView = observer(({ viewModel }: MatchingPairsExerciseViewProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [itemPositions, setItemPositions] = useState<Record<string, { x: number; y: number }>>({});
    const [popupContent, setPopupContent] = useState<ContentType | null>(null);

    const pairsCount = viewModel.pairsCount;
    const { columnsCount } = computeLayout(pairsCount);
    const spacing = 16;

    const recalcPositions = useCallback(() => {
        if (!containerRef.current) return;

        const newPositions: Record<string, { x: number; y: number }> = {};

        // Находим все элементы, у которых data-item-id
        const elements = containerRef.current.querySelectorAll<HTMLDivElement>('[data-item-id]');
        elements.forEach(el => {
            const id = el.getAttribute('data-item-id');
            if (!id) return;

            // Используем offsetLeft, offsetTop, offsetWidth и offsetHeight
            newPositions[id] = {
                x: el.offsetLeft + el.offsetWidth / 2,
                y: el.offsetTop + el.offsetHeight / 2,
            };
        });

        if (!positionsAreEqual(itemPositions, newPositions)) {
            setItemPositions(newPositions);
        }
    }, [itemPositions]);
    // Следим за изменениями размеров через ResizeObserver
    useResizeObserver(containerRef, recalcPositions);

    // Изначально вызываем пересчёт (после первого рендера)
    useLayoutEffect(() => {
        recalcPositions();
    }, [recalcPositions]);

    // const lines = drawLines(viewModel.matchedPairs, itemPositions);
    const lines = drawDivLines(viewModel.matchedPairs, itemPositions);

    return (
        <BaseView
            style={{
                padding: 16,
                margin: '0 auto',
                boxSizing: 'border-box',
                width: '100%',
                maxWidth: '1200px',
            }}
        >
            {/* Общий контейнер */}
            <div
                ref={containerRef}
                style={{
                    position: 'relative',
                    width: '100%',
                    minHeight: '400px',
                }}
            >
                {lines}

                {/*/!* SVG для линий *!/*/}
                {/*<svg*/}
                {/*    style={{*/}
                {/*        position: 'absolute',*/}
                {/*        top: 0,*/}
                {/*        left: 0,*/}
                {/*        width: '100%',*/}
                {/*        height: '100%',*/}
                {/*        pointerEvents: 'none',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {lines}*/}
                {/*</svg>*/}

                <BaseVStack style={{ width: '100%', minHeight: '400px' }}>
                    {/* Верхняя сетка (Left items) */}
                    <ItemsGridView
                        items={viewModel.leftItems}
                        columnsCount={columnsCount}
                        spacing={spacing}
                        isSelected={item => viewModel.selectedLeft?.id === item.id}
                        isMatched={item => viewModel.isMatched(item)}
                        isWrong={item => viewModel.isWrong(item)}
                        onSelect={item => viewModel.selectLeftItem(item)}
                        onLongPressImageOrVideo={c => setPopupContent(c)}
                    />

                    <div style={{ margin: `${spacing * 2}px 0` }} />

                    {/* Нижняя сетка (Right items) */}
                    <ItemsGridView
                        items={viewModel.rightItems}
                        columnsCount={columnsCount}
                        spacing={spacing}
                        isSelected={item => viewModel.selectedRight?.id === item.id}
                        isMatched={item => viewModel.isMatched(item)}
                        isWrong={item => viewModel.isWrong(item)}
                        onSelect={item => viewModel.selectRightItem(item)}
                        onLongPressImageOrVideo={c => setPopupContent(c)}
                    />

                    {viewModel.matchedPairs.length === pairsCount && (
                        <BaseText font="title" backgroundColor="green" cornerRadius={8}>
                            Упражнение завершено!
                        </BaseText>
                    )}
                </BaseVStack>
            </div>

            {popupContent && (
                <BaseModal onClose={() => setPopupContent(null)}>
                    {popupView(popupContent)}
                </BaseModal>
            )}
        </BaseView>
    );
});

/** Создаём элементы <line .../> между парными элементами */
function drawLines(
    matchedPairs: Array<{ left: any; right: any }>,
    positions: Record<string, { x: number; y: number }>
) {
    return matchedPairs.map((pair, i) => {
        const leftPos = positions[pair.left.id];
        const rightPos = positions[pair.right.id];
        if (!leftPos || !rightPos) return null;
        return (
            <line
                key={i}
                x1={leftPos.x}
                y1={leftPos.y}
                x2={rightPos.x}
                y2={rightPos.y}
                stroke="green"
                strokeWidth={3}
            />
        );
    });
}
function drawDivLines(
    matchedPairs: Array<{ left: any; right: any }>,
    positions: Record<string, { x: number; y: number }>
) {
    return matchedPairs.map((pair, i) => {
        const leftPos = positions[pair.left.id];
        const rightPos = positions[pair.right.id];
        if (!leftPos || !rightPos) return null;

        // Вычисляем длину
        const dx = rightPos.x - leftPos.x;
        const dy = rightPos.y - leftPos.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        // Угол в радианах, затем в градусах
        const angleRad = Math.atan2(dy, dx);
        const angleDeg = (angleRad * 180) / Math.PI;

        // Стили для <div>
        const lineStyle: CSSProperties = {
            position: 'absolute',
            top: leftPos.y,        // Линию ставим в координаты leftPos
            left: leftPos.x,
            width: distance,
            height: 3,
            backgroundColor: 'green',
            transformOrigin: '0 50%', // Повернуть вокруг левого края
            transform: `rotate(${angleDeg}deg)`,
            // Можно скруглить края через borderRadius
            borderRadius: 2,
        };

        return <div key={i} style={lineStyle} />;
    });
}


function computeLayout(pairsCount: number) {
    if (pairsCount > 6) {
        return { columnsCount: 3 };
    } else if (pairsCount > 3) {
        return { columnsCount: 2};
    } else {
        return { columnsCount: pairsCount};
    }
}

function popupView(content: ContentType) {
    switch (content.kind) {
        case 'image':
            return <ZoomableImageView url={content.url} />;
        case 'video':
            return <VideoPlayerView url={content.url} />;
        default:
            return <BaseText font="headline">Нет превью</BaseText>;
    }
}

/** Проверка равенства позиций, чтобы не лишний раз делать setState */
function positionsAreEqual(
    a: Record<string, { x: number; y: number }>,
    b: Record<string, { x: number; y: number }>
): boolean {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);
    if (keysA.length !== keysB.length) return false;

    for (const key of keysA) {
        if (!b[key]) return false;
        if (a[key].x !== b[key].x || a[key].y !== b[key].y) {
            return false;
        }
    }
    return true;
}