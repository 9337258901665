import React, { useEffect, useState } from 'react';
import {BaseText} from "../../exercises_types/BaseComponents/BaseText";

export function PracticeWordSearchView({ vm }: { vm: any }) {
    return <BaseText>PracticeWordSearchView</BaseText>;
}
export function PracticeCrosswordView({ vm }: { vm: any }) {
    return <BaseText>PracticeCrosswordView</BaseText>;
}
export function FillBlanksExerciseView({ viewModel }: { viewModel: any }) {
    return <BaseText>FillBlanksExerciseView</BaseText>;
}
export function JustContentPracticeView({ viewModel }: { viewModel: any }) {
    return <BaseText>JustContentPracticeView</BaseText>;
}
