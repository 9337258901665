// ui/viewmodels/LessonCreationViewModel.ts
import { makeAutoObservable } from "mobx";
import { Exercise, Lesson } from "../../../domain/models";
import {LessonService, LessonServiceProtocol} from "./LessonService";
import CompositionRoot from "../../../compositionRoot";

// Аналог Swift: LessonCreationViewModel
export class LessonCreationViewModel {
    setName: string = "";
    selectedExercises: Exercise[] = [];

    private lessonService: LessonServiceProtocol;

    constructor() {
        makeAutoObservable(this);
        this.lessonService = new LessonService(CompositionRoot.saveExerciseInteractor);
    }

    addExercises(exercises: Exercise[]) {
        this.selectedExercises.push(...exercises);
    }

    // Удалить по индексу (упрощённая версия deleteSelectedExercise(at offsets: IndexSet))
    deleteSelectedExercise(index: number) {
        this.selectedExercises.splice(index, 1);
    }

    // Переместить элемент (упрощённая версия moveSelectedExercise)
    moveSelectedExercise(fromIndex: number, toIndex: number) {
        const item = this.selectedExercises.splice(fromIndex, 1)[0];
        this.selectedExercises.splice(toIndex, 0, item);
    }

    saveLesson() {
        const newLesson: Lesson = {
            id: crypto.randomUUID().toUpperCase(), // или uuidv4()
            name: this.setName,
            exercises: this.selectedExercises,
        };
        console.log("LessonCreationViewModel saving lesson: ", newLesson)
        this.lessonService.saveLesson(newLesson);
    }
}