// PairElementView.tsx
import React from 'react';
import { ElementContentView } from './ElementContentView';
import { ContentData } from "../../../../../../domain/models";

interface PairElementViewProps {
    itemId: string;
    data: ContentData;
    isSelected: boolean;
    isMatched: boolean;
    isWrongAttempt: boolean;
    onLongPressImageOrVideo: (content: any) => void;
    action: () => void;
}

export function PairElementView(props: PairElementViewProps) {
    const {
        itemId,
        data,
        isSelected,
        isMatched,
        isWrongAttempt,
        onLongPressImageOrVideo,
        action,
    } = props;

    const backgroundColor = isMatched
        ? 'rgba(0,255,0,0.3)'
        : isSelected
            ? 'yellow'
            : 'rgba(128,128,128,0.2)';

    const handleContextMenu = (e: React.MouseEvent) => {
        e.preventDefault();
        if (!isMatched && data.content) {
            const kind = data.content.kind;
            if (kind === 'image' || kind === 'video') {
                onLongPressImageOrVideo(data.content);
            }
        }
    };

    return (
        <div
            data-item-id={itemId}
            style={{
                backgroundColor,
                borderRadius: 8,
                cursor: 'pointer',
                padding: 8,
                margin: 0,
            }}
            onClick={() => {
                if (!isMatched) {
                    action();
                }
            }}
            onContextMenu={handleContextMenu}
        >
            <ElementContentView data={data} />
        </div>
    );
}