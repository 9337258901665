
// Аналог протокола LessonServiceProtocol
import {Lesson} from "../../../domain/models";
import {SaveExerciseInteractor} from "../../../domain/interfaces/SaveExerciseInteractor";

export interface LessonServiceProtocol {
    saveLesson(lesson: Lesson): void;
}

// Аналог class LessonService из Swift
export class LessonService implements LessonServiceProtocol {
    private saveInteractor: SaveExerciseInteractor;

    constructor(saveInteractor: SaveExerciseInteractor) {
        this.saveInteractor = saveInteractor;
    }

    saveLesson(lesson: Lesson): void {
        // Swift: saveInteractor.save(lesson: lesson)
        // Здесь — смотря как именно реализован ваш SaveExerciseInteractor:
        this.saveInteractor.save(lesson);
    }
}