// AnagramPreview.swift -> AnagramPreview.tsx
import React from 'react';
import {AnagramViewBase} from "../Base/AnagramViewBase";
import {ExerciseAnagram} from "../../../../../domain/models";

interface AnagramPreviewProps {
    exercise: ExerciseAnagram;
}

export function AnagramPreview({ exercise }: AnagramPreviewProps) {
    const word = exercise.word;
    return (
        <AnagramViewBase
            displayedWord={word.translation}
            currentAnswer={Array.from("_".repeat(word.word.length))}
            scrambledLetters={Array.from(word.word).sort(() => Math.random() - 0.5)}
        />
    );
}
