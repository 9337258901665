import {Exercise, ExerciseTable} from "../models";
import {SimilarTableUseCaseProtocol} from "../Exercises/Table/SimilarTableUseCase";

export interface CreateSimilarInteractor {
    execute(exercise: Exercise): Promise<Exercise>;
}

export class CreateSimilarInteractorStub implements CreateSimilarInteractor {
    async execute(exercise: Exercise): Promise<Exercise> {
        // Возвращаем копию с новыми UUID и т.п.
        console.log("Stub: Creating similar exercise based on", exercise);
        return {
            ...exercise,
            id: crypto.randomUUID().toUpperCase(), // или другая генерация
        };
    }
}

export class CreateSimilarInteractorMock implements CreateSimilarInteractor {
    private similarTableUseCase: SimilarTableUseCaseProtocol;

    constructor(similarTableUseCase: SimilarTableUseCaseProtocol) {
        this.similarTableUseCase = similarTableUseCase;
    }

    async execute(exercise: Exercise): Promise<Exercise> {
        switch (exercise.type.kind) {
            case "table": {
                // 1) берем exercise.type.data как ExerciseTable
                const tableData: ExerciseTable = exercise.type.data;
                // 2) вызываем createSimilarExerciseTable
                const similarTable = await this.similarTableUseCase.createSimilarExerciseTable(tableData);
                // 3) возвращаем новый Exercise c новым id
                const newExercise: Exercise = {
                    id: crypto.randomUUID().toUpperCase(),  // или любая генерация UUID
                    type: {
                        kind: "table",
                        data: similarTable
                    }
                };
                return newExercise;
            }
            default:
                // Для остальных типов: просто новое id
                return {
                    ...exercise,
                    id: crypto.randomUUID().toUpperCase()
                };
        }
    }
}