// MatchingPairsUseCaseProtocol.ts
import { MatchingPairsState } from "./MatchingPairsState";
import { Exercise } from "../../../models";
import { PairElement } from "../PairElement";

export interface MatchingPairsUseCaseProtocol {
    initializeState(exerciseData: any, id: string): MatchingPairsState;
    selectLeftItem(state: MatchingPairsState, item: PairElement): MatchingPairsState;
    selectRightItem(state: MatchingPairsState, item: PairElement): MatchingPairsState;
}

// Пример реализации
export class MatchingPairsUseCase implements MatchingPairsUseCaseProtocol {
    initializeState(exerciseData: any, id: string): MatchingPairsState {
        // формируем начальный MatchingPairsState
        return new MatchingPairsState({
            exerciseId: id,
            pairs: exerciseData.pairs ?? [],
            rightItemsOrder: exerciseData.rightItemsOrder ?? [],
        });
    }

    selectLeftItem(state: MatchingPairsState, item: PairElement): MatchingPairsState {
        // Копируем state, меняем selectedLeft
        const newState = state.clone();
        if (newState.selectedLeft === item.id) {
            newState.selectedLeft = undefined;
        } else {
            newState.selectedLeft = item.id;
        }
        return this.tryMatchIfPossible(newState);
    }

    selectRightItem(state: MatchingPairsState, item: PairElement): MatchingPairsState {
        const newState = state.clone();
        if (newState.selectedRight === item.id) {
            newState.selectedRight = undefined;
        } else {
            newState.selectedRight = item.id;
        }
        return this.tryMatchIfPossible(newState);
    }

    private tryMatchIfPossible(oldState: MatchingPairsState): MatchingPairsState {
        const { selectedLeft, selectedRight } = oldState;
        if (!selectedLeft || !selectedRight) return oldState;

        // Проверяем, лежат ли эти элементы в одном PairItem
        const isMatch = oldState.pairs.some(
            p => p.left.id === selectedLeft && p.right.id === selectedRight
        );

        const newState = oldState.clone();

        if (isMatch) {
            newState.matchedPairs.push({ leftId: selectedLeft, rightId: selectedRight });
            newState.selectedLeft = undefined;
            newState.selectedRight = undefined;
            newState.lastWrongAttempt = undefined;
        } else {
            newState.lastWrongAttempt = { leftId: selectedLeft, rightId: selectedRight };
        }

        return newState;
    }
}