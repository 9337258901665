// MatchingPairsUIHandler.ts
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { ExerciseUIHandler } from "../../../Multiplayer/ExerciseUIHandler";
import { SessionStateInteractor } from "../../../Multiplayer/SessionStateInteractor";
import { ExerciseUIState } from "../../../Multiplayer/ExerciseUIState";
import { MatchingPairsUIState, createDefaultMatchingPairsUIState } from "./MatchingPairsUIState";
import { MatchedPair } from "./MatchedPair";

/**
 * UIHandler для MatchingPairs, по аналогии с PracticeHangmanUIHandler / PracticeTableUIHandler.
 * - Хранит локальное состояние (mobx) в this.uiState.
 * - При изменениях this.uiState отправляет их в SessionStateInteractor (updateExerciseState).
 * - Пришедшие «с сервера» обновления принимает через updateUIState(...).
 */
export class MatchingPairsUIHandler implements ExerciseUIHandler {
    /**
     * Локальное «MobX-состояние» интерфейса.
     * При изменениях триггерит `reaction`, чтобы отправить обновление в Firestore.
     */
    public uiState: MatchingPairsUIState;

    private exerciseId: string;                 // ID упражнения
    private interactor?: SessionStateInteractor; // Для вызова updateExerciseState(...)
    private isUpdatingFromServer = false;       // Флаг, чтобы отличать локальные изменения от «серверных»
    private currentUIState: MatchingPairsUIState; // Последнее «зафиксированное» состояние

    constructor(
        exerciseId: string,
        interactor?: SessionStateInteractor,
        initialState?: MatchingPairsUIState
    ) {
        this.exerciseId = exerciseId;
        this.interactor = interactor;

        // Начальное состояние (если не передали, создаём дефолтное)
        this.currentUIState = initialState ?? createDefaultMatchingPairsUIState();
        // MobX-поле uiState копируем из currentUIState
        this.uiState = { ...this.currentUIState };

        // Делаем все поля наблюдаемыми (this.uiState и все поля UIHandler)
        makeAutoObservable(this);

        // Следим за изменениями ключевых полей: matchedPairs, selectedLeft, selectedRight, lastWrongAttempt
        reaction(
            () => ({
                matchedPairs: this.uiState.matchedPairs.slice(),
                selectedLeft: this.uiState.selectedLeft,
                selectedRight: this.uiState.selectedRight,
                lastWrongAttempt: this.uiState.lastWrongAttempt,
            }),
            () => {
                if (!this.isUpdatingFromServer) {
                    this.sendUpdateIfNeeded();
                }
            }
        );
    }

    /**
     * Вызывается SessionStateInteractor (или кто-то ещё),
     * когда приходит новое состояние matchingPairs из Firestore.
     */
    public updateUIState(uiState: ExerciseUIState | undefined): void {
        console.log("MatchingPairsUIHandler updateUIState start")
        if (!uiState || uiState.type !== "matchingPairs") {
            return;
        }
        // Декодируем MatchingPairsUIState
        const newState = uiState.data as MatchingPairsUIState;

        console.log("MatchingPairsUIHandler updateUIState newState: ", uiState)
        // Если реальных изменений нет, выходим
        if (isMatchingPairsUIEqual(this.currentUIState, newState)) {
            console.log("MatchingPairsUIHandler updateUIState states are equal")
            return;
        }

        // Ставим флаг, чтобы не вызвать sendUpdateIfNeeded() рекурсивно
        this.isUpdatingFromServer = true;
        console.log("MatchingPairsUIHandler updateUIState start updating from server")
        try {
            runInAction(() => {
                // Обновляем локальные структуры
                this.currentUIState = newState;
                this.uiState = { ...newState };
            });
        } finally {
            this.isUpdatingFromServer = false;
        }
    }

    /**
     * Сравниваем uiState c currentUIState.
     * Если отличаются, вызываем interactor.updateExerciseState(...).
     */
    private sendUpdateIfNeeded() {
        const newState: MatchingPairsUIState = {
            matchedPairs: [...this.uiState.matchedPairs],
            selectedLeft: this.uiState.selectedLeft,
            selectedRight: this.uiState.selectedRight,
            lastWrongAttempt: this.uiState.lastWrongAttempt,
        };

        // Если не менялось — уходим
        if (isMatchingPairsUIEqual(this.currentUIState, newState)) {
            return;
        }

        // Фиксируем новое локальное состояние
        this.currentUIState = newState;

        // Сообщаем interactor о нововведениях
        this.interactor?.updateExerciseState(this.exerciseId, {
            type: "matchingPairs",
            data: newState,
        });
    }
}

/**
 * Утилита: Сравнение MatchingPairsUIState (список matchedPairs, поля selectedLeft/Right, lastWrongAttempt).
 */
export function isMatchingPairsUIEqual(a: MatchingPairsUIState, b: MatchingPairsUIState): boolean {
    // Сравниваем matchedPairs
    if (a.matchedPairs.length !== b.matchedPairs.length) return false;
    for (let i = 0; i < a.matchedPairs.length; i++) {
        const mpA = a.matchedPairs[i];
        const mpB = b.matchedPairs[i];
        if (mpA.leftId !== mpB.leftId || mpA.rightId !== mpB.rightId) {
            return false;
        }
    }
    // Сравнение selectedLeft / selectedRight
    if (a.selectedLeft !== b.selectedLeft) return false;
    if (a.selectedRight !== b.selectedRight) return false;

    // Сравнение lastWrongAttempt
    if (a.lastWrongAttempt?.leftId !== b.lastWrongAttempt?.leftId) return false;
    if (a.lastWrongAttempt?.rightId !== b.lastWrongAttempt?.rightId) return false;

    return true;
}
