import { MatchingPairsState } from "./MatchingPairsState";
import { Exercise } from "../../../models";
import { PairElement } from "../PairElement";
import { MatchingPairsUIHandler } from "./MatchingPairsUIHandler";
import { MatchingPairsUseCase, MatchingPairsUseCaseProtocol } from "./MatchingPairsUseCase";
import { MatchingPairsAttemptRecorder, MatchingPairsAttemptRecorderProtocol } from "./MatchingPairsAttemptRecorder";
import { PracticeSessionDelegate } from "../../PracticeSessionDelegate";
import {reaction} from "mobx";

export class MatchingPairsLearnInteractor {
    private _state: MatchingPairsState;
    private delegate?: PracticeSessionDelegate;
    private uiHandler?: MatchingPairsUIHandler;
    private useCase: MatchingPairsUseCaseProtocol;
    private attemptProcessor: MatchingPairsAttemptRecorderProtocol;
    private exercise: Exercise;

    onStateChange: ((newState: MatchingPairsState) => void) | null = null;

    constructor(args: {
        exercise: Exercise;
        delegate?: PracticeSessionDelegate;
        uiHandler?: MatchingPairsUIHandler;
        useCase?: MatchingPairsUseCaseProtocol;
    }) {
        this.exercise = args.exercise;
        this.delegate = args.delegate;
        this.uiHandler = args.uiHandler;
        this.useCase = args.useCase ?? new MatchingPairsUseCase();
        this.attemptProcessor = new MatchingPairsAttemptRecorder(args.delegate);

        if (this.exercise.type.kind !== "matchingPairs") {
            throw new Error("Unsupported exercise type for MatchingPairsLearnInteractor");
        }

        // Инициализация MatchingPairsState из Exercise
        const exerciseData = this.exercise.type.data; // { pairs, rightItemsOrder }
        const initial = this.useCase.initializeState(exerciseData, this.exercise.id);

        this._state = initial;
        this.setupBindings()
        setTimeout(() => {
            this.syncFromUIHandler();
        }, 0);
    }

    get state(): MatchingPairsState {
        return this._state;
    }
    set state(newVal: MatchingPairsState) {
        this._state = newVal;
        this.onStateChange?.(newVal);
    }

    public setupBindings() {
        if (!this.uiHandler) return;

        reaction(
            () => ({
                matchedPairs: this.uiHandler!.uiState.matchedPairs.slice(),
                selectedLeft: this.uiHandler!.uiState.selectedLeft,
                selectedRight: this.uiHandler!.uiState.selectedRight,
                lastWrongAttempt: this.uiHandler!.uiState.lastWrongAttempt,
            }),
            () => {
                // Каждый раз, когда поля uiHandler.uiState изменяются
                // — подтягиваем их в локальный MatchingPairsState
                this.syncFromUIHandler();
            }
        );
    }


    selectLeftItem(item: PairElement) {
        this.syncFromUIHandler();
        const oldState = this._state;

        const newState = this.useCase.selectLeftItem(this._state, item);

        if (!MatchingPairsState.equals(oldState, newState)) {
            this.attemptProcessor.processAttempt(oldState, newState, this.exercise);
            this.state = newState;
            this.checkFinish(newState);
            this.syncToUIHandler(newState);
        }
    }

    selectRightItem(item: PairElement) {
        this.syncFromUIHandler();
        const oldState = this._state;

        const newState = this.useCase.selectRightItem(this._state, item);

        if (!MatchingPairsState.equals(oldState, newState)) {
            this.attemptProcessor.processAttempt(oldState, newState, this.exercise);
            this.state = newState;
            this.checkFinish(newState);
            this.syncToUIHandler(newState);
        }
    }

    private checkFinish(newState: MatchingPairsState) {
        if (newState.matchedPairs.length === newState.pairsCount) {
            this.delegate?.currentStepDone();
        }
    }

    private syncFromUIHandler() {
        if (!this.uiHandler) return;

        // Клонируем текущее состояние
        const newState = this._state.clone();

        // IMPORTANT: теперь поля берем из this.uiHandler.uiState
        // вместо this.uiHandler.matchedPairs
        if (newState.matchedPairs !== this.uiHandler.uiState.matchedPairs) {
            newState.matchedPairs = [...this.uiHandler.uiState.matchedPairs];
        }
        newState.selectedLeft = this.uiHandler.uiState.selectedLeft;
        newState.selectedRight = this.uiHandler.uiState.selectedRight;
        newState.lastWrongAttempt = this.uiHandler.uiState.lastWrongAttempt;

        if (!MatchingPairsState.equals(this._state, newState)) {
            this.state = newState;
        }
    }

    private syncToUIHandler(newState: MatchingPairsState) {
        if (!this.uiHandler) return;

        // Аналогично, пишем в this.uiHandler.uiState
        this.uiHandler.uiState.matchedPairs = newState.matchedPairs;
        this.uiHandler.uiState.selectedLeft = newState.selectedLeft;
        this.uiHandler.uiState.selectedRight = newState.selectedRight;
        this.uiHandler.uiState.lastWrongAttempt = newState.lastWrongAttempt;
    }
}