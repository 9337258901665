////////////////////////////////////
// AudioPlayerView.tsx
////////////////////////////////////
interface AudioPlayerViewProps {
    audioData: ArrayBuffer;
}
export function AudioPlayerView({ audioData }: AudioPlayerViewProps) {
    // Заглушка: нельзя напрямую проиграть ArrayBuffer без URL.createObjectURL
    const blob = new Blob([audioData], { type: 'audio/mpeg' });
    const objectUrl = URL.createObjectURL(blob);
    return <audio src={objectUrl} controls />;
}