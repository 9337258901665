import {TableModel} from "../../../../../domain/Exercises/Table/Models/TableModel";
import {CellCoordinate} from "../../../../../domain/Exercises/Table/Models/CellCoordinate";
import {TableTextElement} from "../../../../../domain/Exercises/Table/Models/TableTextElement";

export interface TableEditingUseCaseProtocol {
    toggleStable(model: TableModel, rowIndex: number, columnIndex: number): TableModel;
    setRandomStableCells(model: TableModel): TableModel;
    mergeRowIntoModel(model: TableModel, rowIndex: number, newItems: TableTextElement[]): TableModel;
    addColumn(model: TableModel): TableModel;
    removeColumn(model: TableModel): TableModel;
    addRow(model: TableModel): TableModel;
    removeRow(model: TableModel, rowIndex: number): TableModel;
}

export class TableEditingUseCase implements TableEditingUseCaseProtocol {
    toggleStable(model: TableModel, rowIndex: number, columnIndex: number): TableModel {
        const newModel: TableModel = {...model, columns: [...model.columns]};

        if (columnIndex < 0 || columnIndex >= newModel.columns.length) return newModel;
        const col = newModel.columns[columnIndex];
        if (rowIndex < 0 || rowIndex >= col.items.length) return newModel;

        const coord = CellCoordinate.create(columnIndex, rowIndex);
        if (newModel.stableCells.has(coord)) {
            newModel.stableCells.delete(coord);
        } else {
            newModel.stableCells.add(coord);
        }
        return newModel;
    }

    mergeRowIntoModel(
        model: TableModel,
        rowIndex: number,
        newItems: TableTextElement[]
    ): TableModel {
        const newModel: TableModel = {...model, columns: model.columns.map(c => ({...c}))};

        for (let colIndex = 0; colIndex < newModel.columns.length; colIndex++) {
            if (colIndex >= newItems.length) break;
            const col = newModel.columns[colIndex];
            if (rowIndex < col.items.length) {
                const oldItem = col.items[rowIndex];
                // Оригинальная логика — если было .text, оставляем как есть
                if (oldItem.kind === 'editor') {
                    col.items[rowIndex] = newItems[colIndex];
                }
            }
        }
        return newModel;
    }

    addColumn(model: TableModel): TableModel {
        const newModel: TableModel = {...model, columns: [...model.columns]};
        const maxRowCount = Math.max(...newModel.columns.map(c => c.items.length), 0);
        const newHeader: TableTextElement = {kind: 'editor', value: ''};
        const newItems: TableTextElement[] = Array.from({length: maxRowCount}, () => ({
            kind: 'editor',
            value: ''
        }));

        newModel.columns.push({header: newHeader, items: newItems});
        return newModel;
    }

    removeColumn(model: TableModel): TableModel {
        const newModel: TableModel = {...model, columns: [...model.columns]};
        if (newModel.columns.length > 0) {
            newModel.columns.pop();
        }
        return newModel;
    }

    addRow(model: TableModel): TableModel {
        const newModel: TableModel = {...model, columns: model.columns.map(c => ({...c}))};
        for (const col of newModel.columns) {
            col.items = [...col.items, {kind: 'editor', value: ''}];
        }
        return newModel;
    }

    removeRow(model: TableModel, rowIndex: number): TableModel {
        const newModel: TableModel = {...model, columns: model.columns.map(c => ({...c}))};
        for (const col of newModel.columns) {
            if (rowIndex >= 0 && rowIndex < col.items.length) {
                col.items.splice(rowIndex, 1);
            }
        }
        return newModel;
    }

    setRandomStableCells(model: TableModel): TableModel {
        const newModel: TableModel = {
            ...model,
            columns: model.columns.map((c) => ({ ...c })),
        };
        // Копируем Set, чтобы не мутировать оригинальный
        newModel.stableCells = new Set(model.stableCells);

        // Определяем общее число строк
        const maxRows = newModel.columns.reduce(
            (acc, col) => Math.max(acc, col.items.length),
            0
        );

        // Для каждой строки проверяем, нет ли в ней уже stable-ячейки
        for (let rowIndex = 0; rowIndex < maxRows; rowIndex++) {
            // Проверяем, есть ли stable-ячейка в этой строке
            let rowHasStable = false;
            for (let colIndex = 0; colIndex < newModel.columns.length; colIndex++) {
                const coord = CellCoordinate.create(colIndex, rowIndex);
                if (newModel.stableCells.has(coord)) {
                    rowHasStable = true;
                    break;
                }
            }

            // Если нет ни одной stable-ячейки — выбираем случайный столбец
            if (!rowHasStable) {
                // Соберём все «доступные» столбцы (у которых есть item в rowIndex)
                const possibleCols = newModel.columns
                    .map((col, idx) => ({ col, colIndex: idx }))
                    .filter(({ col }) => rowIndex < col.items.length);

                if (possibleCols.length > 0) {
                    const randomIndex = Math.floor(Math.random() * possibleCols.length);
                    const { colIndex } = possibleCols[randomIndex];

                    const randomCoord = CellCoordinate.create(colIndex, rowIndex);
                    newModel.stableCells.add(randomCoord);
                }
            }
        }

        return newModel;
    }
}