// ========== BaseComponents/BaseList.tsx ==========
import React from 'react';

interface BaseListProps {
    children?: React.ReactNode;
}

export function BaseList({ children }: BaseListProps) {
    // Аналог SwiftUI List - просто вертикальный контейнер
    return <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>{children}</div>;
}
