// MatchingPairsState.ts
import { MatchedPair } from "./MatchedPair";
import { PairElement } from "../PairElement";
import {MatchingPairItem} from "../../../models";

// Аналог Swift MatchingPairsState
export class MatchingPairsState {
    exerciseId: string;
    pairs: MatchingPairItem[];
    matchedPairs: MatchedPair[];
    selectedLeft?: string;
    selectedRight?: string;
    lastWrongAttempt?: MatchedPair;
    rightItemsOrder?: string[];

    constructor(args: {
        exerciseId: string,
        pairs: MatchingPairItem[],
        matchedPairs?: MatchedPair[],
        selectedLeft?: string,
        selectedRight?: string,
        lastWrongAttempt?: MatchedPair,
        rightItemsOrder?: string[],
    }) {
        this.exerciseId = args.exerciseId;
        this.pairs = args.pairs;
        this.matchedPairs = args.matchedPairs ?? [];
        this.selectedLeft = args.selectedLeft;
        this.selectedRight = args.selectedRight;
        this.lastWrongAttempt = args.lastWrongAttempt;
        this.rightItemsOrder = args.rightItemsOrder;
    }

    // Аналоги Swift-геттеров
    get pairsCount(): number {
        return this.pairs.length;
    }

    leftItems(): PairElement[] {
        return this.pairs.map(p => p.left);
    }

    orderedRightItems(): PairElement[] {
        if (!this.rightItemsOrder) {
            return this.pairs.map(p => p.right);
        }
        const dict = new Map<string, PairElement>();
        this.pairs.forEach(item => dict.set(item.right.id, item.right));
        // Собираем по order
        return this.rightItemsOrder.map(id => dict.get(id)!).filter(Boolean);
    }

    isMatched(item: PairElement): boolean {
        return this.matchedPairs.some(mp => mp.leftId === item.id || mp.rightId === item.id);
    }

    isWrong(item: PairElement): boolean {
        if (!this.lastWrongAttempt) return false;
        return (
            this.lastWrongAttempt.leftId === item.id ||
            this.lastWrongAttempt.rightId === item.id
        );
    }

    public clone(): MatchingPairsState {
        return new MatchingPairsState({
            exerciseId: this.exerciseId,
            pairs: this.pairs, // Если необходимо, скопируйте массив
            matchedPairs: [...this.matchedPairs], // копируем массив
            selectedLeft: this.selectedLeft,
            selectedRight: this.selectedRight,
            lastWrongAttempt: this.lastWrongAttempt
                ? { ...this.lastWrongAttempt } // поверхностная копия
                : undefined,
            rightItemsOrder: this.rightItemsOrder ? [...this.rightItemsOrder] : undefined,
        });
    }

    public static equals(a: MatchingPairsState, b: MatchingPairsState): boolean {
        // сравниваем поля
        if (a.exerciseId !== b.exerciseId) return false;
        if (a.selectedLeft !== b.selectedLeft) return false;
        if (a.selectedRight !== b.selectedRight) return false;
        if (a.rightItemsOrder?.length !== b.rightItemsOrder?.length) return false;
        // и т.д. для matchedPairs
        if (a.matchedPairs.length !== b.matchedPairs.length) return false;
        for (let i = 0; i < a.matchedPairs.length; i++) {
            const aMP = a.matchedPairs[i], bMP = b.matchedPairs[i];
            if (aMP.leftId !== bMP.leftId || aMP.rightId !== bMP.rightId) {
                return false;
            }
        }
        // Если всё совпало, возвращаем true
        return true;
    }
}