// ========== MatchingPairsCreateView.tsx ==========

import React, { useState } from 'react';
import { BaseVStack } from '../../BaseComponents/BaseVStack';
import { BaseHStack } from '../../BaseComponents/BaseHStack';
import { BaseText } from '../../BaseComponents/BaseText';
import { BaseButton } from '../../BaseComponents/BaseButton';
import { BaseView } from '../../BaseComponents/BaseView';
import { BaseList } from '../../BaseComponents/BaseList';
import { BaseDivider } from '../../BaseComponents/BaseDivider';
import { BaseFileImporter } from '../../BaseComponents/BaseFileImporter';
import { LabelButton } from '../../BaseComponents/LabelButton';
import { BaseIcon } from '../../BaseComponents/BaseIcon';
import { MatchingPairsCreateViewModel } from './MatchingPairsCreateViewModel';
import { PairElementEditor } from './PairElementEditor';
import {Exercise} from "../../../../../domain/models";
import { observer } from 'mobx-react-lite';

interface MatchingPairsCreateViewProps {
    onExercisesCreated: (exercises: Exercise[]) => void;
}

//export const ExerciseCreationFlowView = observer(({ onSelect, onClose }: Props) => {
//const LessonPractice: React.FC = observer(() => {
export const MatchingPairsCreateView = observer(({ onExercisesCreated }: MatchingPairsCreateViewProps) => {
    const [viewModel] = useState(() => new MatchingPairsCreateViewModel(onExercisesCreated));
    const [isImportingFile, setIsImportingFile] = useState(false);
    const [currentImportTarget, setCurrentImportTarget] = useState<{ pairId: string, isLeft: boolean } | null>(null);

    const handleFileSelected = (url: URL) => {
        if (!currentImportTarget) return;
        viewModel.handleImportedFile(url, currentImportTarget.isLeft, currentImportTarget.pairId);
        setCurrentImportTarget(null);
    };

    const handleFileError = (error: Error) => {
        console.error(error);
        setCurrentImportTarget(null);
    };

    return (
        <BaseView padding={16}>
            <BaseVStack>
                <BaseList>
                    {viewModel.pairs.map(pair => (
                        <BaseHStack key={pair.id} padding={8} style={{ alignItems: 'flex-start' }}>
                            <BaseVStack style={{ flex: 1 }}>
                                <PairElementEditor
                                    element={pair.left}
                                    onElementChange={(newElem) => {
                                        viewModel.updatePairLeftText(pair.id, newElem.text);
                                        if (newElem.fileURL !== pair.left.fileURL) {
                                            // fileURL обновляется handleImportedFile
                                        }
                                    }}
                                    onSelectFile={() => {
                                        setCurrentImportTarget({ pairId: pair.id, isLeft: true });
                                        setIsImportingFile(true);
                                    }}
                                />
                            </BaseVStack>

                            <BaseDivider />

                            <BaseVStack style={{ flex: 1 }}>
                                <PairElementEditor
                                    element={pair.right}
                                    onElementChange={(newElem) => {
                                        viewModel.updatePairRightText(pair.id, newElem.text);
                                    }}
                                    onSelectFile={() => {
                                        setCurrentImportTarget({ pairId: pair.id, isLeft: false });
                                        setIsImportingFile(true);
                                    }}
                                />
                            </BaseVStack>

                            <div style={{ flex: 0 }}>
                                <button onClick={() => viewModel.removePair(pair.id)} style={{ marginLeft: '8px', background: 'none', border: 'none', cursor: 'pointer' }}>
                                    <BaseIcon name="trash" color="red" />
                                </button>
                            </div>
                        </BaseHStack>
                    ))}
                </BaseList>

                <BaseHStack padding={8}>
                    <LabelButton
                        text="Add pair"
                        systemImage="plus.circle"
                        onClick={() => viewModel.addPair()}
                    />
                    <div style={{ flex: 1 }} />
                    <BaseButton
                        text="Save exercise"
                        onPress={() => { viewModel.createExercise(); }}
                    />
                </BaseHStack>
            </BaseVStack>

            <BaseFileImporter
                isPresented={isImportingFile}
                onFileSelected={handleFileSelected}
                onError={handleFileError}
                onDismissed={() => setIsImportingFile(false)}
            />
        </BaseView>
    );
})
