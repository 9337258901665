// Step3_ExerciseEditingView.tsx
import React from "react";
import {Exercise} from "../../../../domain/models";

interface Props {
    exercises: Exercise[];
    onSelect: (exercise: Exercise) => void;
}

export function ExerciseEditingView({ exercises, onSelect }: Props) {
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <h3>Exercise editing</h3>
            <div style={{ overflowY: "auto", maxHeight: 300 }}>
                {exercises.map((ex) => (
                    <div key={ex.id} style={{ border: "1px solid #ccc", padding: 8, marginBottom: 8 }}>
                        {/* Swift: ex.editData.renderEditView(...).
                У нас можно заглушку: */}
                        <div>EditingView for type: {ex.type.kind}</div>

                        {/* Кнопки */}
                        <button
                            style={{ marginRight: 8 }}
                            onClick={() => {
                                // "remove" is done in parent with a callback, or you can pass full state
                                // If you want to remove from local array, you'd need setState
                            }}
                        >
                            Remove
                        </button>
                        <button
                            onClick={() => {
                                onSelect(ex);
                            }}
                        >
                            Confirm
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}