// PracticeTableViewModel.ts

import { makeAutoObservable } from "mobx";
import { Exercise } from "../../../../../domain/models";
import { PracticeSessionDelegate } from "../../../../../domain/Exercises/PracticeSessionDelegate";
import {PracticeTableState} from "../../../../../domain/Exercises/Table/Practice/PracticeTableState";
import {PracticeTableInteractor} from "../../../../../domain/Exercises/Table/Practice/PracticeTableInteractor";
import {PracticeTableUIHandler} from "../../../../../domain/Exercises/Table/Practice/PracticeTableUIHandler";

// Аналог Swift PracticeTableViewModel — теперь он «тонкий»
export class PracticeTableViewModel {
    // ViewModel хранит (наблюдаемый) state, чтобы React / MobX-компоненты могли
    // подписываться и перерисовываться.
    state: PracticeTableState;

    // Ссылка на интерактор
    private interactor: PracticeTableInteractor;

    // Чтобы идентифицировать упражнение
    id: string;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: PracticeTableUIHandler
    ) {
        if (exercise.type.kind !== "table") {
            throw new Error("Not a table exercise");
        }
        this.id = exercise.id;

        // Создаём интерактор
        const interactor = new PracticeTableInteractor(exercise, delegate, uiHandler);
        this.interactor = interactor;

        // Изначальное состояние
        this.state = interactor.state;

        // Подписываемся на обновление стейта
        interactor.onStateChange = (newState) => {
            this.state = newState;
        };

        makeAutoObservable(this); // если нужно отслеживать изменения в this.state
    }

    // Вызывается из UI, когда пользователь что-то вводит в ячейку
    updateUserInput(colIndex: number, rowIndex: number, newValue: string) {
        this.interactor.updateUserInput(colIndex, rowIndex, newValue);
    }
}