// ========== MissingWord/Preview/MissingWordViewable.tsx ==========

import { ExerciseViewable } from '../../ExerciseViewable';
import React from 'react';
import { MissingWordViewBase } from '../Base/MissingWordViewBase';
import {Exercise, ExerciseMissingWord} from "../../../../../domain/models";

export class MissingWordViewable implements ExerciseViewable {
    private exercise: ExerciseMissingWord;

    constructor(exercise: ExerciseMissingWord) {
        this.exercise = exercise;
    }

    renderView(_exercise: Exercise): JSX.Element {
        return (
            <MissingWordViewBase
                sentence={this.exercise.sentence}
                inputText=""
                isCorrect={null}
                correctAnswer={this.exercise.correctForm}
                onInputChange={() => {}}
                onEnterPressed={() => {}}
                onCheckPressed={() => {}}
                onNextPressed={() => {}}
            />
        );
    }
}

