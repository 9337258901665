// PracticeHangmanView.tsx

import React from 'react';
import { observer } from 'mobx-react-lite';
import { PracticeHangmanViewModel } from './PracticeHangmanViewModel';
import HangmanGameView from "../Base/HangmanGameView";

interface PracticeHangmanViewProps {
    viewModel: PracticeHangmanViewModel;
}

export const PracticeHangmanView: React.FC<PracticeHangmanViewProps> = observer(({ viewModel }) => {
    const onGameEnd = (didWin: boolean) => {
        viewModel.handleGameEnd(didWin);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <HangmanGameView
                // Передаём нужные данные из state
                word={viewModel.state.targetWord}
                hint={viewModel.state.hint}
                // Когда игра завершилась, шлём сигнал во ViewModel
                onGameEnd={onGameEnd}
                // А также передаём метод, чтобы HangmanGameView мог «угадать букву»
                guessLetter={(letter) => viewModel.guessLetter(letter)}
                // Остальные параметры для отрисовки (currentStage и т.д.)
                currentStage={viewModel.state.currentStage}
                displayedWord={viewModel.state.displayed}
                isGameOver={viewModel.state.isGameOver}
                isWin={viewModel.state.isWin}
            />

            {viewModel.state.isGameOver && (
                <div style={{ marginTop: 12 }}>
                    {viewModel.state.isWin ? (
                        <span style={{ color: "green" }}>Success!</span>
                    ) : (
                        <span style={{ color: "red" }}>Dead</span>
                    )}
                </div>
            )}
        </div>
    );
});