// LessonCreationView.tsx
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { LessonCreationViewModel } from "./LessonCreationViewModel";
import { LessonPreview } from "./LessonPreview";
import { ExerciseCreationFlowView } from "../ExerciseCreation/ExerciseCreationFlowView";
import { Exercise } from "../../../domain/models";
import { AllExercisesPickerView } from "./AllExercisesPickerView";

export const LessonCreationView = observer(() => {
    const [lessonViewModel] = useState(() => new LessonCreationViewModel());
    const [creatingExercise, setCreatingExercise] = useState(false);
    const [pickingExistingExercise, setPickingExistingExercise] = useState(false);

    return (
        // Общий фон приложения — тёмный
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                width: "100%",
                backgroundColor: "#1f1f1f",
                position: "relative",
            }}
        >
            {/* Основной блок со свёрстанным превью урока */}
            <div style={{ width: "60%", minWidth: 800, padding: 16 }}>
                <LessonPreview
                    viewModel={lessonViewModel}
                    onCreateExercise={() => setCreatingExercise(true)}
                    onAddFromExisting={() => setPickingExistingExercise(true)}
                />
            </div>

            {/* Модалка для создания упражнения */}
            {creatingExercise && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                    }}
                >
                    <div
                        style={{
                            width: "80%",
                            height: "80%",
                            backgroundColor: "#2f2f2f",
                            borderRadius: 8,
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            padding: 16,
                            overflow: "auto",
                        }}
                    >
                        <ExerciseCreationFlowView
                            onSelect={(newExercise: Exercise) => {
                                lessonViewModel.addExercises([newExercise]);
                                setCreatingExercise(false);
                            }}
                            onClose={() => setCreatingExercise(false)}
                        />
                    </div>
                </div>
            )}

            {/* Модалка для выбора существующего упражнения */}
            {pickingExistingExercise && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                    }}
                >
                    <div
                        style={{
                            width: "80%",
                            height: "80%",
                            backgroundColor: "#2f2f2f",
                            borderRadius: 8,
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            padding: 16,
                            overflow: "auto",
                        }}
                    >
                        <AllExercisesPickerView
                            onSelect={(existingExercise: Exercise) => {
                                const copy = {
                                    ...existingExercise,
                                    id: crypto.randomUUID().toUpperCase(),
                                };
                                lessonViewModel.addExercises([copy]);
                                setPickingExistingExercise(false);
                            }}
                            onClose={() => setPickingExistingExercise(false)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
});