// ExerciseCreationFlowViewModel.ts

import { makeAutoObservable } from "mobx";
// Или без mobx: используйте обычные useState в React

import { CreateExerciseFlowStep, getFlowStepTitle } from "./CreateExerciseFlowStep";
import { Exercise } from "../../../domain/models";
import {ExerciseCreationType} from "../../../domain/models/ExerciseCreate"; // куда-то указывает

export class ExerciseCreationFlowViewModel {
    createExerciseFlowStep: CreateExerciseFlowStep = CreateExerciseFlowStep.selectType;
    selectedExerciseType: ExerciseCreationType | null = null;
    tempExercises: Exercise[] = [];

    // Для Swift-like bindings:
    grammarCreationMethod: string | null = null;    // GrammarCreationMethod ( theme / recognition )
    fillBlanksCreationMethod: string | null = null; // FillBlanksCreationMethod ( words / file )

    constructor() {
        makeAutoObservable(this);
    }

    get stepTitle(): string {
        return getFlowStepTitle(this.createExerciseFlowStep);
    }

    exerciseGenerated(exercises: Exercise[]) {
        this.tempExercises = exercises;
        this.createExerciseFlowStep = CreateExerciseFlowStep.editing;
    }

    reset() {
        this.tempExercises = [];
        this.selectedExerciseType = null;
        this.createExerciseFlowStep = CreateExerciseFlowStep.selectType;
        this.grammarCreationMethod = null;
        this.fillBlanksCreationMethod = null;
    }
}