import React, { useState } from 'react';

export enum ButtonVariant {
    Classic = 'classic',
    Big = 'big',
}

interface ButtonProps {
    color?: string;
    onClick: () => void;
    children: React.ReactNode;
    variant?: ButtonVariant; // выбор стиля из енума
}

const BaseButton: React.FC<ButtonProps> = ({
                                               color = '#007bff',
                                               onClick,
                                               children,
                                               variant = ButtonVariant.Classic,
                                           }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isPressed, setIsPressed] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => {
        setIsHovered(false);
        setIsPressed(false);
    };
    const handleMouseDown = () => setIsPressed(true);
    const handleMouseUp = () => setIsPressed(false);

    // Подбираем стиль в зависимости от состояния и выбранного варианта
    const currentStyle = isPressed
        ? getActiveStyle(color, variant)
        : isHovered
            ? getHoverStyle(color, variant)
            : getBaseStyle(color, variant);

    return (
        <button
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            style={currentStyle}
        >
            {children}
        </button>
    );
};

/** Базовый стиль, который “подмешивается” разным вариантам. */
function getBaseStyle(color: string, variant: ButtonVariant): React.CSSProperties {
    // Размер шрифта и отступы зависят от варианта
    const variantStyles =
        variant === ButtonVariant.Big
            ? {
                fontSize: '24px',
                padding: '15px 25px',
            }
            : {
                fontSize: '18px',
                padding: '10px 20px',
            };

    return {
        color: '#ffffff',
        backgroundColor: color,
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        fontWeight: 'bold',
        textAlign: 'center',
        ...variantStyles,
    };
}

/** Стиль при наведении */
function getHoverStyle(color: string, variant: ButtonVariant): React.CSSProperties {
    return {
        ...getBaseStyle(color, variant),
        backgroundColor: darkenColor(color, 0.1),
        boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
    };
}

/** Стиль при нажатии */
function getActiveStyle(color: string, variant: ButtonVariant): React.CSSProperties {
    return {
        ...getBaseStyle(color, variant),
        transform: 'scale(0.95)',
        backgroundColor: darkenColor(color, 0.15),
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    };
}

/** Функция для затемнения цвета */
function darkenColor(color: string, amount: number): string {
    const colorValue = parseInt(color.slice(1), 16);
    const r = Math.max(0, (colorValue >> 16) - Math.round(255 * amount));
    const g = Math.max(0, ((colorValue >> 8) & 0x00ff) - Math.round(255 * amount));
    const b = Math.max(0, (colorValue & 0x0000ff) - Math.round(255 * amount));
    return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
}

export default BaseButton;