import {TableModel, TableModelColumn} from "../../../../../domain/Exercises/Table/Models/TableModel";
import {TableTextElement} from "../../../../../domain/Exercises/Table/Models/TableTextElement";
import {CellCoordinate} from "../../../../../domain/Exercises/Table/Models/CellCoordinate";

// export function makeEmptyModel(
//     columnsCount: number = 3,
//     rowsCount: number = 6
// ): TableModel {
//     // 1) Создаём массив столбцов
//     const columns: TableModel["columns"] = [];
//
//     for (let colIndex = 0; colIndex < columnsCount; colIndex++) {
//         // Заголовок = .editor("")
//         const header: TableTextElement = { kind: "editor", value: "" };
//
//         // Строки (rowsCount) => [.editor("")]
//         const items: TableTextElement[] = [];
//         for (let rowIndex = 0; rowIndex < rowsCount; rowIndex++) {
//             items.push({ kind: "editor", value: "" });
//         }
//
//         columns.push({ header, items });
//     }
//
//     // 2) stableCells = пустое множество
//     const stableCells = new Set<CellCoordinate>();
//
//     // 3) Собираем и возвращаем TableModel
//     return {
//         columns,
//         stableCells
//     };
// }

/**
 * Фабрика пустого TableModel (аналог static func empty(...))
 */
export function makeEmptyTableModel(columnsCount = 3, rowsCount = 6): TableModel {
    const columns: TableModelColumn[] = [];
    for (let col = 0; col < columnsCount; col++) {
        const header: TableTextElement = { kind: 'editor', value: '' };
        const items: TableTextElement[] = [];
        for (let row = 0; row < rowsCount; row++) {
            items.push({ kind: 'editor', value: '' });
        }
        columns.push({ header, items });
    }
    return {
        columns,
        stableCells: new Set<CellCoordinate>()
    };
}

export function makeEmptyModel(
    columnsCount: number = 3,
    rowsCount: number = 6
): TableModel {
    // 1) Создаём массив столбцов
    const columns: TableModel["columns"] = [];

    const header0 = "verbos"
    const header1 = "sustantivos"
    const header2 = "adjetivos"
    for (let colIndex = 0; colIndex < columnsCount; colIndex++) {
        // Заголовок = .editor("")
        let hederValue = ""
        if (colIndex === 0) {
            hederValue = header0
        }
        if (colIndex === 1) {
            hederValue = header1
        }
        if (colIndex === 2) {
            hederValue = header2
        }
        const header: TableTextElement = {kind: "editor", value: hederValue};

        // Строки (rowsCount) => [.editor("")]
        const items: TableTextElement[] = [];
        for (let rowIndex = 0; rowIndex < rowsCount; rowIndex++) {
            items.push({kind: "editor", value: ""});
        }

        columns.push({header, items});
    }

    // 2) stableCells = пустое множество
    const stableCells = new Set<CellCoordinate>();

    // 3) Собираем и возвращаем TableModel
    return {
        columns,
        stableCells
    };
}

// /**
//  * Аналог tempTestTableModel, чтобы показать пример.
//  */
// export function getTempTestTableModel(): TableModel {
//     // Здесь логика Swift:
//     // (см. var tempTestTableModel в Swift)
//     const verbosHeader = { kind: 'editor', value: 'verbos' };
//     const sustHeader = { kind: 'editor', value: 'sustantivos' };
//     const adjHeader = { kind: 'editor', value: 'adjetivos' };
//
//     const verbosItems = [
//         { kind: 'editor', value: 'ofrecer' },
//         { kind: 'editor', value: 'mejorar' },
//         { kind: 'editor', value: '' },
//         { kind: 'editor', value: 'personalizar' },
//         { kind: 'editor', value: '' },
//         { kind: 'editor', value: '' },
//         { kind: 'editor', value: '' },
//         { kind: 'editor', value: '' },
//     ];
//     const sustItems = [
//         { kind: 'editor', value: '' },
//         { kind: 'editor', value: '' },
//         { kind: 'editor', value: '' },
//         { kind: 'editor', value: '' },
//         { kind: 'editor', value: 'innovaciòn' },
//         { kind: 'editor', value: '' },
//         { kind: 'editor', value: 'oportunidad' },
//         { kind: 'editor', value: 'enseñanza' },
//     ];
//     const adjetivosItems = [
//         { kind: 'editor', value: '' },
//         { kind: 'editor', value: '' },
//         { kind: 'editor', value: 'digital' },
//         { kind: 'editor', value: '' },
//         { kind: 'editor', value: '' },
//         { kind: 'editor', value: 'impulsado' },
//         { kind: 'editor', value: '' },
//         { kind: 'editor', value: '' },
//     ];
//
//     return <TableModel>{
//         columns: [
//     {header: verbosHeader, items: verbosItems},
//     {header: sustHeader, items: sustItems},
//     {header: adjHeader, items: adjetivosItems},
//         ],
//         stableCells: new Set()
//     }
//         }