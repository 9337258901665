// Create/TableCreateViewModel.tsx
import {CreateTableUseCase} from "../../../../../domain/Exercises/Table/CreateTableUseCase";
import {SaveTableUseCase} from "../../../../../domain/Exercises/Table/SaveTableUseCase";
import {TableModel} from "../../../../../domain/Exercises/Table/Models/TableModel";
import CompositionRoot from "../../../../../compositionRoot";
import {TableEditingUseCase, TableEditingUseCaseProtocol} from "./TableEditingUseCase";
import {TablePlaceholderUseCase, TablePlaceholderUseCaseProtocol} from "./TablePlaceholderUseCase";

export class TableCreateViewModel {
    public model: TableModel;
    public fillText: string = '';
    public loadingRows: Set<number> = new Set<number>();

    private createUseCase: CreateTableUseCase = new CreateTableUseCase(CompositionRoot.tableRequester);
    private saveUseCase: SaveTableUseCase = new SaveTableUseCase();
    private tableEditingUseCase: TableEditingUseCaseProtocol = new TableEditingUseCase();
    private placeholderUseCase: TablePlaceholderUseCaseProtocol = new TablePlaceholderUseCase();

    private onExercisesCreated: (exs: any[]) => void;
    private onForceUpdate?: () => void;

    constructor(
        model: TableModel,
        onExercisesCreated: (exs: any[]) => void,
    ) {
        this.model = model;
        this.onExercisesCreated = onExercisesCreated;
    }

    public setForceUpdateCallback(cb: () => void) {
        this.onForceUpdate = cb;
    }

    savePressed() {
        const exData = this.saveUseCase.createExerciseTable(this.model);
        const exercise = {
            id: crypto.randomUUID().toUpperCase(),
            type: {
                kind: 'table' as const,
                data: exData
            }
        };
        this.onExercisesCreated([exercise]);
    }

    async fill() {
        try {
            this.model = await this.createUseCase.fill(this.model, this.fillText);
        } catch (error) {
            console.error("Error filling table: ", error);
        }
    }

    async fillRow(rowIndex: number) {
        if (this.loadingRows.has(rowIndex)) return;
        this.loadingRows.add(rowIndex);
        this.onForceUpdate?.();

        try {
            const newRowItems = await this.createUseCase.fillRow(this.model, rowIndex, this.fillText);
            this.model = this.tableEditingUseCase.mergeRowIntoModel(this.model, rowIndex, newRowItems);
        } catch (error) {
            console.error("Error filling row: ", error);
        }
        this.loadingRows.delete(rowIndex);
        this.onForceUpdate?.();
    }

    toggleStable(rowIndex: number, columnIndex: number) {
        this.model = this.tableEditingUseCase.toggleStable(this.model, rowIndex, columnIndex);
    }

    setRandomStableCells() {
        this.model = this.tableEditingUseCase.setRandomStableCells(this.model);
    }

    addColumn() {
        this.model = this.tableEditingUseCase.addColumn(this.model);
    }

    removeColumn() {
        this.model = this.tableEditingUseCase.removeColumn(this.model);
    }

    addRow() {
        this.model = this.tableEditingUseCase.addRow(this.model);
    }

    removeRow(rowIndex: number) {
        this.model = this.tableEditingUseCase.removeRow(this.model, rowIndex);
    }

    updatePlaceholder(columnIndex: number, rowIndex: number | null, userInput: string) {
        this.model = this.placeholderUseCase.updatePlaceholder(
            this.model,
            columnIndex,
            rowIndex,
            userInput
        );
    }

    confirmPressed(columnIndex: number, rowIndex: number | null) {
        this.model = this.placeholderUseCase.confirmPressed(
            this.model,
            columnIndex,
            rowIndex
        );
    }

    confirmTablePressed() {
        this.model = this.placeholderUseCase.confirmTablePressed(this.model);
        this.setRandomStableCells()
    }

    editPressed(columnIndex: number, rowIndex: number | null) {
        this.model = this.placeholderUseCase.editPressed(
            this.model,
            columnIndex,
            rowIndex
        );
    }
}