// MatchingPairsAttemptRecorder.ts
import { MatchingPairsState } from "./MatchingPairsState";
import { Exercise } from "../../../models";
import { PairElement } from "../PairElement";
import {PracticeAttempt, PracticeSessionDelegate} from "../../PracticeSessionDelegate";

export interface MatchingPairsAttemptRecorderProtocol {
    processAttempt(oldState: MatchingPairsState, newState: MatchingPairsState, exercise: Exercise): void;
}

export class MatchingPairsAttemptRecorder implements MatchingPairsAttemptRecorderProtocol {
    private delegate?: PracticeSessionDelegate;

    constructor(delegate?: PracticeSessionDelegate) {
        this.delegate = delegate;
    }

    processAttempt(oldState: MatchingPairsState, newState: MatchingPairsState, exercise: Exercise) {
        // Проверяем, была ли попытка соотнести пару
        // Аналог Swift:
        // "if oldState.selectedLeft != newState.selectedLeft && oldState.selectedRight != newState.selectedRight"

        if (
            oldState.selectedLeft !== newState.selectedLeft &&
            oldState.selectedRight !== newState.selectedRight
        ) {
            // Это значит, что мы совершили попытку
            const lId = oldState.selectedLeft;
            const rId = oldState.selectedRight;
            if (!lId || !rId) return; // Нет попытки

            const wasCorrect = newState.matchedPairs.some(
                mp => mp.leftId === lId && mp.rightId === rId
            );
            this.recordMatchingPairsAttempt(exercise, newState, lId, rId, wasCorrect);
        }
    }

    private recordMatchingPairsAttempt(
        exercise: Exercise,
        state: MatchingPairsState,
        leftId: string,
        rightId: string,
        wasCorrect: boolean
    ) {
        const allItems = [...state.leftItems(), ...state.orderedRightItems()];
        const leftEl = allItems.find(el => el.id === leftId);
        const rightEl = allItems.find(el => el.id === rightId);

        const leftText = leftEl?.text ?? "?";
        const rightText = rightEl?.text ?? "?";

        const answerString = `left: ${leftText}, right: ${rightText}`;

        const attempt: PracticeAttempt = {
            subItemId: exercise.id, // analog to exercise.id.uuidString
            answer: answerString,
            wasCorrect,
            timestamp: new Date()
        };
        this.delegate?.saveAttempt(exercise, attempt);
    }
}