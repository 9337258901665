import React from "react";

export function MissingWordTypePreview() {
    return (
        <div style={{
            backgroundColor: "rgba(128,128,128,0.3)",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
      <span style={{ fontSize: 12, textAlign: "center" }}>
        {"Missing Word\nPreview"}
      </span>
        </div>
    );
}

// Остальные можно сделать аналогично:
export function SelectOptionTypePreview() { /* ... */ return <div>...</div> }
export function WordSearchTypePreview() { return <div>Word Search Preview</div> }
export function AnagramTypePreview() { return <div>Anagram Preview</div> }
export function CrosswordTypePreview() { return <div>Crossword Preview</div> }
export function FillBlanksTypePreview() { return <div>FillBlanks Preview</div> }
export function MatchingPairsTypePreview() { return <div>MatchingPairs Preview</div> }
export function JustContentTypePreview() { return <div>JustContent Preview</div> }

interface GenericTypePreviewProps {
    label: string; // Название типа упражнения, например "Missing Word"
}

/**
 * Отображает прямоугольник (серый) с надписью label по центру.
 */
export function GenericTypePreview({ label }: GenericTypePreviewProps) {
    return (
        <div style={containerStyle}>
            <span style={textStyle}>{label}</span>
        </div>
    );
}

const containerStyle: React.CSSProperties = {
    backgroundColor: 'rgba(128,128,128,0.3)',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

const textStyle: React.CSSProperties = {
    fontSize: 12,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',  // если нужно переносить строки
    color: '#fff'
};