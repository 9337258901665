// Base/ElementView.tsx
import React from 'react';
import {TableTextElement} from "../../../../../domain/Exercises/Table/Models/TableTextElement";

interface ElementViewProps {
    element: TableTextElement;
    isStable: boolean;
    updatePlaceholder: (newVal: string) => void;
    onTap: () => void;
}

export function ElementView({ element, isStable, updatePlaceholder, onTap }: ElementViewProps) {
    const containerStyle: React.CSSProperties = {
        width: 120,
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    switch (element.kind) {
        case "text":
            return (
                <div style={containerStyle} onClick={onTap}>
                    <span style={{ color: isStable ? "green" : "white" }}>
                        {element.value}
                    </span>
                </div>
            );

        case "editor":
            return (
                <div style={containerStyle}>
                    <input
                        style={{
                            width: "70%",
                            height: "70%",
                            boxSizing: "border-box", // учитываем padding/border в размерах
                            textAlign: "center",
                        }}
                        type="text"
                        value={element.value}
                        onChange={(e) => updatePlaceholder(e.target.value)}
                    />
                </div>
            );

        default:
            return null;
    }
}