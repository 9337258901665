// FirestoreCellTextsArray.ts

import { CellCoordinate } from "./CellCoordinate";

export type FirestoreCellTextsArray = Array<{ columnIndex: number; rowIndex: number } | string>;

/**
 * Превращаем Map<CellCoordinate, string> → массив FirestoreCellTextsArray
 */
export function encodeCellTexts(
    map: Map<CellCoordinate, string>
): FirestoreCellTextsArray {
    const arr: FirestoreCellTextsArray = [];
    // @ts-ignore
    for (const [coord, textVal] of map.entries()) {
        // Кладём сначала объект {columnIndex, rowIndex}, потом строку
        arr.push({
            columnIndex: coord.columnIndex,
            rowIndex: coord.rowIndex,
        });
        arr.push(textVal);
    }
    return arr;
}

/**
 * Превращаем FirestoreCellTextsArray (массив пар) → Map<CellCoordinate, string>.
 */
export function decodeCellTexts(
    arr: FirestoreCellTextsArray
): Map<CellCoordinate, string> {
    const map = new Map<CellCoordinate, string>();
    // Шагаем по массиву парами
    for (let i = 0; i < arr.length; i += 2) {
        const maybeCoord = arr[i];
        const maybeText = arr[i + 1];
        if (
            typeof maybeCoord === "object" &&
            maybeCoord != null &&
            typeof maybeText === "string"
        ) {
            const col = (maybeCoord as any).columnIndex;
            const row = (maybeCoord as any).rowIndex;
            // Восстанавливаем CellCoordinate через ваш cache
            const coordObj = CellCoordinate.create(col, row);
            map.set(coordObj, maybeText);
        } else {
            console.warn(
                "decodeCellTexts: unexpected pair at indices",
                i,
                i + 1,
                maybeCoord,
                maybeText
            );
        }
    }
    return map;
}