// ==================== ImageUploader.ts ====================
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

/**
 * Интерфейс для загрузки изображений
 */
export interface ImageUploading {
    /**
     * Загружает изображение в Firebase Storage
     * @param data - бинарные данные (Blob или File) изображения
     * @param wordId - идентификатор (имя) файла
     * @returns URL загруженного файла
     */
    uploadImage(data: Blob, wordId: string): Promise<string>;
}

/**
 * Класс для загрузки изображений в Firebase Storage
 */
export class ImageUploader implements ImageUploading {
    private storage;

    constructor() {
        // Предполагается, что Firebase уже инициализирован где-то в вашем приложении
        this.storage = getStorage();
    }

    public async uploadImage(data: Blob, wordId: string): Promise<string> {
        const storageRef = ref(this.storage, `images/${wordId}.jpg`);

        // Загружаем blob-данные
        await uploadBytes(storageRef, data, { contentType: 'image/jpeg' });
        // Получаем доступный для скачивания URL
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
    }
}


