// Step2_ExerciseSettingsView.tsx

import React from "react";
import {ExerciseCreationType} from "../../../../domain/models/ExerciseCreate";
import {CreateExerciseFlowStep} from "../CreateExerciseFlowStep";
import {Exercise} from "../../../../domain/models";
import {TableCreateView} from "../../exercises_types/Table/Create/TableCreateView";
import {HangmanCreateView} from "../../exercises_types/Hangman/Create/HangmanCreateView";
import {MatchingPairsCreateView} from "../../exercises_types/MatchingPairs/Create/MatchingPairsCreateView";

interface Props {
    selectedExerciseType: ExerciseCreationType;
    grammarCreationMethod: string | null;      // e.g. "theme" or "recognition"
    fillBlanksCreationMethod: string | null;   // e.g. "words" or "file"
    createExerciseFlowStep: CreateExerciseFlowStep;

    onGrammarMethodChange: (val: string | null) => void;
    onFillBlanksMethodChange: (val: string | null) => void;
    onStepChange: (step: CreateExerciseFlowStep) => void;
    onExercisesGenerated: (exs: Exercise[]) => void;
    onBack: () => void;
}

export function ExerciseCreatingSettingsView({
                                                 selectedExerciseType,
                                                 grammarCreationMethod,
                                                 fillBlanksCreationMethod,
                                                 onGrammarMethodChange,
                                                 onFillBlanksMethodChange,
                                                 onStepChange,
                                                 onExercisesGenerated,
                                                 onBack,
                                             }: Props) {
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            {/* Switch by selectedExerciseType */}
            {renderSettings()}

            <div style={{ marginTop: "auto" }}>
                <button onClick={onBack}>Back</button>
            </div>
        </div>
    );

    function renderSettings() {
        switch (selectedExerciseType) {
            case "missingWord":
            case "selectOption":
                return grammarSettingsView();
            case "wordSearch":
            case "anagram":
            case "crossword":
                return wordsSettingsView();
            case "fillBlanks":
                return fillBlanksSettingsView();
            case "matchingPairs":
                return matchingPairsSettingsView();
            case "justContent":
                return justContentSettingsView();
            case "table":
                return tableSettingsView();
            case "hangman":
                return hangmanCreationView()
            default:
                return <div>Not implemented</div>;
        }
    }

    function tableSettingsView() {
        return (
            <div>
                <TableCreateView
                    onExercisesCreated={(exs) => {
                        onExercisesGenerated(exs);
                    }}
                />
            </div>
        );
    }

    function hangmanCreationView() {
        return (
            <HangmanCreateView onExercisesCreated={(exs) => onExercisesGenerated(exs)} />
        )
    }

    function justContentSettingsView() {
        return (
            <JustContentCreateView
                onExercisesCreated={(exs) => onExercisesGenerated(exs)}
            />
        );
    }

    function matchingPairsSettingsView() {
        return (
            <MatchingPairsCreateView
                onExercisesCreated={(exs) => onExercisesGenerated(exs)}
            />
        );
    }

    function fillBlanksSettingsView() {
        // Swift: if fillBlanksCreationMethod == nil => FillBlanksMethodSelectionView
        if (!fillBlanksCreationMethod) {
            return (
                <FillBlanksMethodSelectionView
                    onSelectWords={() => onFillBlanksMethodChange("words")}
                    onSelectFile={() => onFillBlanksMethodChange("file")}
                />
            );
        } else if (fillBlanksCreationMethod === "words") {
            return wordsSettingsView();
        } else if (fillBlanksCreationMethod === "file") {
            return (
                <AudioRecognizerView
                    onExercisesCreated={(exs) => {
                        onExercisesGenerated(exs);
                    }}
                />
            );
        }
    }

    function grammarSettingsView() {
        // if grammarCreationMethod == nil => GrammarMethodSelectionView
        if (!grammarCreationMethod) {
            return (
                <GrammarMethodSelectionView
                    onSelectTheme={() => onGrammarMethodChange("theme")}
                    onSelectRecognition={() => onGrammarMethodChange("recognition")}
                />
            );
        } else if (grammarCreationMethod === "theme") {
            return (
                <SentencesGeneratorView
                    creationType={selectedExerciseType}
                    onExercisesCreated={(exs) => onExercisesGenerated(exs)}
                />
            );
        } else if (grammarCreationMethod === "recognition") {
            return (
                <GrammarRecognizerView
                    creationType={selectedExerciseType}
                    onExercisesCreated={(exs) => onExercisesGenerated(exs)}
                />
            );
        }
    }

    function wordsSettingsView() {
        return (
            <WordsGeneratorView
                creationType={selectedExerciseType}
                onExercisesCreated={(exs) => onExercisesGenerated(exs)}
            />
        );
    }
}

function JustContentCreateView(props: { onExercisesCreated: (exs: Exercise[]) => void }) { return <div>JustContentCreateView</div>;}
function FillBlanksMethodSelectionView(props: { onSelectWords:()=>void; onSelectFile:()=>void;}){ return <div>FillBlanksMethodSelectionView <button onClick={props.onSelectWords}>words</button><button onClick={props.onSelectFile}>file</button></div>}
function AudioRecognizerView(props: { onExercisesCreated: (exs: Exercise[]) => void }){ return <div>AudioRecognizerView</div>;}
function GrammarMethodSelectionView(props:{ onSelectTheme:()=>void; onSelectRecognition:()=>void;}){ return <div>GrammarMethodSelectionView <button onClick={props.onSelectTheme}>theme</button><button onClick={props.onSelectRecognition}>recognition</button></div>}
function SentencesGeneratorView(props:{creationType:ExerciseCreationType; onExercisesCreated:(exs:Exercise[])=>void}){ return <div>SentencesGeneratorView</div>}
function GrammarRecognizerView(props:{creationType:ExerciseCreationType; onExercisesCreated:(exs:Exercise[])=>void}){ return <div>GrammarRecognizerView</div>}
function WordsGeneratorView(props:{creationType:ExerciseCreationType; onExercisesCreated:(exs:Exercise[])=>void}){ return <div>WordsGeneratorView</div>}