import {makeAutoObservable, reaction, runInAction} from "mobx";
import { PracticeTableState } from "./PracticeTableState";
import { TableModel } from "../Models/TableModel";
import { PracticeSessionDelegate } from "../../PracticeSessionDelegate";
import { Exercise, ExerciseTable } from "../../../models";
import { PracticeTableUseCaseProtocol, PracticeTableUseCase } from "../PracticeTableUseCase";
import { PracticeTableUIHandler } from "./PracticeTableUIHandler";

export class PracticeTableInteractor {
    private _state: PracticeTableState;
    private correctModel: TableModel;

    private delegate?: PracticeSessionDelegate;
    private useCase: PracticeTableUseCaseProtocol;
    public uiHandler: PracticeTableUIHandler;
    onStateChange?: (newState: PracticeTableState) => void;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: PracticeTableUIHandler,
        useCase: PracticeTableUseCaseProtocol = new PracticeTableUseCase()
    ) {
        if (exercise.type.kind !== "table") {
            throw new Error("Not a table exercise");
        }
        this.delegate = delegate;
        this.useCase = useCase

        this.uiHandler = uiHandler ?? new PracticeTableUIHandler(exercise.id);
        const fullModel = useCase.createTableModel(
            exercise.type.data as ExerciseTable
        );
        this.correctModel = fullModel;

        const practiceModel = useCase.createPracticeModel(fullModel);

        this._state = { practiceModel };

        makeAutoObservable(this);

        this.setupBindings();

        setTimeout(() => {
            this.syncFromUIHandler();
        }, 0);
    }

    get state(): PracticeTableState {
        return this._state;
    }

    private setState(newState: PracticeTableState) {
        this._state = newState;
        this.onStateChange?.(newState);
    }

    private setupBindings(): void {
        reaction(
            () => Array.from(this.uiHandler.cellTexts.entries()),
            () => { this.syncFromUIHandler(); }
        );
    }

    public updateUserInput(colIndex: number, rowIndex: number, newValue: string) {
        this.syncFromUIHandler();

        const newState = this.useCase.updateUserInput(
            this._state,
            this.correctModel,
            colIndex,
            rowIndex,
            newValue,
            this.delegate
        );

        if (newState !== this._state) {
            this.setState(newState);
            this.syncToUIHandler(newState);
        }
    }

    private syncFromUIHandler() {
        const updatedTexts = this.uiHandler.cellTexts;
        const newPracticeModel = this.useCase.updatePracticeModel(this._state.practiceModel, this.correctModel, updatedTexts);
        if (newPracticeModel !== this._state.practiceModel) {
            this.setState({ ...this._state, practiceModel: newPracticeModel });
        }
    }

    private syncToUIHandler(newState: PracticeTableState) {
        const newCellTexts = this.useCase.updateCellTexts(newState.practiceModel, this.uiHandler.cellTexts);
        this.uiHandler.cellTexts = newCellTexts;
    }
}