// SmallPreview/TableTypePreview.tsx
import React from 'react';
import { TableView } from '../Base/TableView';
import {TableModel} from "../../../../../domain/Exercises/Table/Models/TableModel";
import {TableTextElement} from "../../../../../domain/Exercises/Table/Models/TableTextElement";
import {CellCoordinate} from "../../../../../domain/Exercises/Table/Models/CellCoordinate";
import {Resizer} from "../../../Resizer";

export function TableTypePreview() {
    const mockData: TableModel = createMockData();

    return (
        <Resizer targetSize={{ width: 200, height: 200 }}>
            <TableView
                columns={mockData.columns}
                stableCells={mockData.stableCells}
                toggleStable={() => {}}
                updatePlaceholder={() => {}}
                confirmPressed={() => {}}
                editPressed={() => {}}
                fillRow={() => {}}
                removeRow={() => {}}
                loadingRows={new Set()}
            />
        </Resizer>
    );
}

// Swift-стиль создание mockData
function createMockData(): TableModel {
    const emptyPlaceholder: TableTextElement = { kind: 'editor', value: '' };

    const ofrecer: TableTextElement = { kind: 'editor', value: 'ofrecer' };
    const mejorar: TableTextElement = { kind: 'editor', value: 'mejorar' };
    const personalizar: TableTextElement = { kind: 'editor', value: 'personalizar' };

    const verbos = {
        header: { kind: 'text', value: 'verbos' } as TableTextElement,
        items: [
            ofrecer,
            mejorar,
            emptyPlaceholder,
            personalizar,
            emptyPlaceholder,
            emptyPlaceholder,
        ]
    };

    const innovacion: TableTextElement = { kind: 'editor', value: 'innovaciòn' };
    const oportunidad: TableTextElement = { kind: 'editor', value: 'oportunidad' };
    const ensenanza: TableTextElement = { kind: 'editor', value: 'enseñanza' };

    const sustantivos = {
        header: { kind: 'text', value: 'sustantivos' } as TableTextElement,
        items: [
            emptyPlaceholder,
            emptyPlaceholder,
            emptyPlaceholder,
            emptyPlaceholder,
            innovacion,
            emptyPlaceholder,
        ]
    };

    const digital: TableTextElement = { kind: 'editor', value: 'digital' };
    const impulsado: TableTextElement = { kind: 'editor', value: 'impulsado' };

    const adjetivos = {
        header: { kind: 'text', value: 'adjetivos' } as TableTextElement,
        items: [
            emptyPlaceholder,
            emptyPlaceholder,
            digital,
            emptyPlaceholder,
            emptyPlaceholder,
            impulsado,
        ]
    };

    // stableCells

    const coord: CellCoordinate = CellCoordinate.create(0, 0);
    const coord2: CellCoordinate = CellCoordinate.create(1, 4);
    const coord3: CellCoordinate = CellCoordinate.create(1, 6);
    const coord4: CellCoordinate = CellCoordinate.create(1, 7);

    return {
        columns: [verbos, sustantivos, adjetivos],
        stableCells: new Set([coord, coord2, coord3, coord4])
    };
}