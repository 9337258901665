// CreateExerciseModal.tsx
import React from "react";
import { Exercise } from "../../../domain/models";
import {ExerciseCreationFlowView} from "../ExerciseCreation/ExerciseCreationFlowView";

interface Props {
    onClose: () => void;
    onSelect: (ex: Exercise) => void;
}

export function CreateExerciseModal({ onClose, onSelect }: Props) {
    return (
        <div style={modalOverlayStyle}>
            <div style={modalContentStyle}>
                {/* Внутри – ExerciseCreationFlowView */}
                <ExerciseCreationFlowView
                    onSelect={(newEx: Exercise) => {
                        // Пользователь выбрал / создал упражнение
                        onSelect(newEx);
                    }}
                    onClose={() => {
                        onClose();
                    }}
                />
            </div>
        </div>
    );
}

const modalOverlayStyle: React.CSSProperties = {
    position: "fixed",
    top: 0, left: 0, right: 0, bottom: 0,
    backgroundColor: "rgba(0,0,0,0.8)",  // более тёмная подложка
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
};

const modalContentStyle: React.CSSProperties = {
    backgroundColor: "#1f1f1f",  // как вы просили — тёмный фон
    color: "#fff",
    width: 800,
    height: 900,
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    border: "1px solid #444", // чтобы отделять от overlay
    borderRadius: 8,
    padding: 8
};