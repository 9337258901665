// SessionStateCodec.ts

import { PracticeTableUIState } from "./PracticeTableUIState";
import { SessionState } from "../../../Multiplayer/SessionState";
import { ExerciseUIState } from "../../../Multiplayer/ExerciseUIState";
import { CellCoordinate } from "../Models/CellCoordinate";
import {PracticeHangmanUIState, PracticeHangmanUIStateUtils} from "../../Hangman/Practice/PracticeHangmanUIState";
import {MatchingPairsUIState} from "../../MatchingPairs/Practice/MatchingPairsUIState";
import {MatchedPair} from "../../MatchingPairs/Practice/MatchedPair";

/**
 * Преобразуем наш SessionState в «Swift-подобный» формат:
 *   {
 *     exercises: [...],
 *     currentExerciseIndex: N,
 *     version: N,
 *     uiStateByExerciseId: {
 *       UPPERCASE_ID: { table: { _0: { cellTexts: [...] } } },
 *       ...
 *     }
 *   }
 */
export function encodeSessionState(state: SessionState): Record<string, unknown> {
    return {
        exercises: state.exercises.map((exercise) => exercise.toUpperCase()),
        currentExerciseIndex: state.currentExerciseIndex,
        version: state.version,
        uiStateByExerciseId: encodeUiStateMap(state.uiStateByExerciseId),
    };
}

/**
 * Обратное преобразование: берём Firestore-объект (как в Swift JSON)
 * и восстанавливаем SessionState (где, например, { type: "table", data: PracticeTableUIState }).
 */
export function decodeSessionState(obj: any): SessionState {
    return {
        exercises: obj.exercises ?? [],
        currentExerciseIndex: obj.currentExerciseIndex ?? 0,
        version: obj.version ?? 0,
        uiStateByExerciseId: decodeUiStateMap(obj.uiStateByExerciseId),
    };
}

/* =========================
   ВСПОМОГАТЕЛЬНЫЕ ФУНКЦИИ
========================= */

/**
 * Кодируем uiStateByExerciseId: { [exerciseId]: ExerciseUIState },
 * но ключ записываем в верхнем регистре.
 */
function encodeUiStateMap(
    uiMap: Record<string, ExerciseUIState>
): Record<string, unknown> {
    const result: Record<string, unknown> = {};

    for (const exerciseId of Object.keys(uiMap)) {
        const uiState = uiMap[exerciseId];

        // ВАЖНО: переводим ключ в верхний регистр:
        const uppercaseId = exerciseId.toUpperCase();

        switch (uiState.type) {
            case "table": {
                // Кодируем PracticeTableUIState -> { table: { _0: { cellTexts: [...] } } }
                const encodedTable = encodePracticeTable(uiState.data);
                result[uppercaseId] = { table: { _0: encodedTable } };
                break;
            }
            case "hangman": {
                // Кодируем PracticeHangmanUIState -> { hangman: { _0: {...} } }
                const encodedHangman = encodePracticeHangman(uiState.data);
                result[uppercaseId] = { hangman: { _0: encodedHangman } };
                break;
            }

            case "matchingPairs": {
                // Вот новая ветка
                // uiState.data — это MatchingPairsUIState
                const encodedMp = encodeMatchingPairs(uiState.data);
                // Сохраняем под ключом matchingPairs: { _0: ... }
                result[uppercaseId] = {matchingPairs: {_0: encodedMp}};
                break;
            }

            default:
                console.warn(`Unknown UI state type: ${(uiState as any).type}`);
                break;
        }
    }
    return result;
}

/** Декодируем uiStateByExerciseId: { UPPERCASE_ID: { table: { _0: ... } }, ... } */
function decodeUiStateMap(raw: any): Record<string, ExerciseUIState> {
    const result: Record<string, ExerciseUIState> = {};

    for (const exerciseId of Object.keys(raw || {})) {
        const val = raw[exerciseId];
        // Ищем формат { table: { _0: { cellTexts: [...] } } }
        if (val && val.table && val.table._0 && val.table._0.cellTexts) {
            const practiceTableUI = decodePracticeTable(val.table._0);
            result[exerciseId] = {
                type: "table",
                data: practiceTableUI,
            };
            continue
        }

        // 2) hangman
        if (val.hangman && val.hangman._0) {
            const practiceHangmanUI = decodePracticeHangman(val.hangman._0);
            result[exerciseId] = {
                type: "hangman",
                data: practiceHangmanUI,
            };
            continue;
        }

        // 3) MatchingPairs
        // Проверяем, есть ли matchingPairs: { _0: {...} }
        if (val?.matchingPairs?._0) {
            const matchingPairsData = decodeMatchingPairs(val.matchingPairs._0);
            result[exerciseId] = {
                type: "matchingPairs",
                data: matchingPairsData,
            };
            continue;
        }

        console.warn(`decodeUiStateMap: неизвестный формат`, val);

    }
    return result;
}

/** Кодируем PracticeTableUIState -> { cellTexts: [ {col,row}, "Text", {col,row}, "Text", ... ] } */
function encodePracticeTable(tableData: PracticeTableUIState): Record<string, unknown> {
    return {
        cellTexts: encodeCellTexts(tableData.cellTexts),
    };
}

/** Декодируем { cellTexts: [...] } обратно в PracticeTableUIState */
function decodePracticeTable(raw: any): PracticeTableUIState {
    const map = decodeCellTexts(raw.cellTexts);
    return new PracticeTableUIState(map);
}

/**
 * Превращаем Map<CellCoordinate, string> → массив пар [ {col,row}, text, ... ].
 * При этом храним { col,row } как plain-объект, чтобы Firestore не ругался.
 */
function encodeCellTexts(
    cellMap: Map<CellCoordinate, string> | any
): Array<{ columnIndex: number; rowIndex: number } | string> {
    if (!(cellMap instanceof Map)) {
        console.warn("encodeCellTexts: cellMap не является Map. Преобразуем из объекта.", cellMap);
        cellMap = convertObjectToMap(cellMap);
    }
    const entries = [...cellMap.entries()];

    // Сортируем, чтобы в JSON был стабильный порядок (опционально)
    entries.sort(([coordA], [coordB]) => {
        if (coordA.rowIndex !== coordB.rowIndex) {
            return coordA.rowIndex - coordB.rowIndex;
        }
        return coordA.columnIndex - coordB.columnIndex;
    });

    const array: Array<{ columnIndex: number; rowIndex: number } | string> = [];
    for (const [coord, text] of entries) {
        // Firestore не принимает класс CellCoordinate, поэтому пишем plain-объект
        array.push({ columnIndex: coord.columnIndex, rowIndex: coord.rowIndex }, text);
    }
    return array;
}

/**
 * Массив вида [ { col, row }, "Text", { col, row }, "Text", ... ] -> Map<CellCoordinate, string>.
 */
function decodeCellTexts(cellsArray: Array<any>): Map<CellCoordinate, string> {
    const map = new Map<CellCoordinate, string>();
    for (let i = 0; i < (cellsArray?.length ?? 0); i += 2) {
        const coordPart = cellsArray[i];
        const textPart = cellsArray[i + 1];
        if (!coordPart || typeof textPart !== "string") {
            continue;
        }
        // Восстанавливаем CellCoordinate через .create()
        const coord: CellCoordinate = CellCoordinate.create(
            coordPart.columnIndex ?? 0,
            coordPart.rowIndex ?? 0
        );
        map.set(coord, textPart);
    }
    return map;
}

/**
 * Если cellMap оказался обычным объектом (например {"0,0": "Hello"}), переводим в Map<CellCoordinate,string>.
 */
function convertObjectToMap(obj: any): Map<CellCoordinate, string> {
    if (!obj || typeof obj !== "object" || Array.isArray(obj)) {
        return new Map<CellCoordinate, string>();
    }
    const map = new Map<CellCoordinate, string>();
    for (const key of Object.keys(obj)) {
        const textValue = obj[key];
        if (typeof textValue !== "string") {
            continue;
        }
        // Преобразуем ключ "col,row" в CellCoordinate
        const [colStr, rowStr] = key.split(",");
        const col = parseInt(colStr, 10) || 0;
        const row = parseInt(rowStr, 10) || 0;
        map.set(CellCoordinate.create(col, row), textValue);
    }
    return map;
}


function encodePracticeHangman(hangmanData: PracticeHangmanUIState): Record<string, unknown> {
    return PracticeHangmanUIStateUtils.toFirestore(hangmanData);
}

/**
 * Обратное: { guessedLetters: [...], mistakes: N, ... } -> PracticeHangmanUIState
 */
function decodePracticeHangman(raw: any): PracticeHangmanUIState {
    return PracticeHangmanUIStateUtils.fromFirestore(raw);
}

function encodeMatchingPairs(mpData: MatchingPairsUIState): Record<string, unknown> {
    // mpData содержит поля:
    // {
    //   matchedPairs: MatchedPair[],
    //   selectedLeft?: string,
    //   selectedRight?: string,
    //   lastWrongAttempt?: MatchedPair
    // }
    // Нужно вернуть объект, который Firestore сохранит
    return {
        matchedPairs: mpData.matchedPairs.map(mp => ({
            leftId: mp.leftId,
            rightId: mp.rightId
        })),
        selectedLeft: mpData.selectedLeft ?? null,
        selectedRight: mpData.selectedRight ?? null,
        lastWrongAttempt: mpData.lastWrongAttempt
            ? {
                leftId: mpData.lastWrongAttempt.leftId,
                rightId: mpData.lastWrongAttempt.rightId
            }
            : null
    };
}

function decodeMatchingPairs(raw: any): MatchingPairsUIState {
    // raw = {
    //   matchedPairs: [ { leftId, rightId }, ... ],
    //   selectedLeft: "...",
    //   selectedRight: "...",
    //   lastWrongAttempt: { leftId, rightId }
    // }
    const matchedPairs: MatchedPair[] = Array.isArray(raw?.matchedPairs)
        ? raw.matchedPairs.map((mp: any) => ({
            leftId: mp.leftId,
            rightId: mp.rightId,
        }))
        : [];

    return {
        matchedPairs,
        selectedLeft: raw?.selectedLeft || undefined,
        selectedRight: raw?.selectedRight || undefined,
        lastWrongAttempt: raw?.lastWrongAttempt
            ? {
                leftId: raw.lastWrongAttempt.leftId,
                rightId: raw.lastWrongAttempt.rightId,
            }
            : undefined,
    };
}