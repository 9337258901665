// ui/viewmodels/AllExercisesViewModel.ts

import { makeAutoObservable, runInAction } from "mobx";
import {Exercise} from "../../../domain/models";
import {ExercisesListInteractor} from "../../../domain/AllExercises/ExercisesListInteractor";
import {SaveSingleExerciseInteractor} from "../../../domain/AllExercises/SaveSingleExerciseInteractor";
import {CreateSimilarInteractor} from "../../../domain/AllExercises/CreateSimilarInteractor";
// или можно без mobx, на чистых useState

export enum ExerciseTypeFilter {
    all = "all",
    missingWord = "missingWord",
    selectOption = "selectOption",
    wordSearch = "wordSearch",
    anagram = "anagram",
    crossword = "crossword",
    fillBlanks = "fillBlanks",
    matchingPairs = "matchingPairs",
    justContent = "justContent",
    table = "table"
}

export class AllExercisesViewModel {
    exercises: Exercise[] = [];
    selectedFilter: ExerciseTypeFilter = ExerciseTypeFilter.all;
    previewingExercise: Exercise | null = null;
    editingExercise: Exercise | null = null;

    private exercisesListInteractor: ExercisesListInteractor;
    private saveExerciseInteractor: SaveSingleExerciseInteractor;
    private createSimilarInteractor: CreateSimilarInteractor;

    constructor(
        exercisesListInteractor: ExercisesListInteractor,
        saveExerciseInteractor: SaveSingleExerciseInteractor,
        createSimilarInteractor: CreateSimilarInteractor
    ) {
        // mobx-like
        makeAutoObservable(this);

        this.exercisesListInteractor = exercisesListInteractor;
        this.saveExerciseInteractor = saveExerciseInteractor;
        this.createSimilarInteractor = createSimilarInteractor;

        // Загрузим сеты:
        this.loadAllExercises();
    }

    async loadAllExercises() {
        try {
            const all = await this.exercisesListInteractor.getAllExercises();
            runInAction(() => {
                this.exercises = all;
            });
        } catch (error) {
            console.error("Failed to load exercises:", error);
        }
    }

    get filteredExercises(): Exercise[] {
        if (this.selectedFilter === ExerciseTypeFilter.all) {
            return this.exercises;
        }

        // Фильтруем, если совпадает kind
        return this.exercises.filter((ex) => {
            switch (ex.type.kind) {
                case "missingWord":
                    return this.selectedFilter === ExerciseTypeFilter.missingWord;
                case "selectOption":
                    return this.selectedFilter === ExerciseTypeFilter.selectOption;
                case "wordSearch":
                    return this.selectedFilter === ExerciseTypeFilter.wordSearch;
                case "anagram":
                    return this.selectedFilter === ExerciseTypeFilter.anagram;
                case "crossword":
                    return this.selectedFilter === ExerciseTypeFilter.crossword;
                case "fillBlanks":
                    return this.selectedFilter === ExerciseTypeFilter.fillBlanks;
                case "matchingPairs":
                    return this.selectedFilter === ExerciseTypeFilter.matchingPairs;
                case "justContent":
                    return this.selectedFilter === ExerciseTypeFilter.justContent;
                case "table":
                    return this.selectedFilter === ExerciseTypeFilter.table;
                default:
                    return false;
            }
        });
    }

    addExercise(exercise: Exercise) {
        this.saveExerciseInteractor.saveExercise(exercise);
        this.exercises.push(exercise);
    }

    removeExercise(exercise: Exercise) {
        this.exercises = this.exercises.filter((e) => e.id !== exercise.id);
    }

    editExercise(exercise: Exercise) {
        this.previewingExercise = null;
        this.editingExercise = exercise;
    }

    saveEditedExercise(exercise: Exercise) {
        const idx = this.exercises.findIndex((e) => e.id === exercise.id);
        if (idx >= 0) {
            this.exercises[idx] = exercise;
        } else {
            this.exercises.push(exercise);
        }
        this.saveExerciseInteractor.saveExercise(exercise);
    }

    async generateSimilarExercise(basedOn: Exercise) {
        try {
            const newEx = await this.createSimilarInteractor.execute(basedOn);
            // откроем редактор для него
            this.previewingExercise = null;
            this.editingExercise = newEx;
        } catch (error) {
            console.error("Failed to generate similar exercise:", error);
        }
    }
}