// PracticeHangmanViewModel.ts

import { makeAutoObservable } from 'mobx';
import { Exercise } from '../../../../../domain/models';
import { PracticeSessionDelegate } from '../../../../../domain/Exercises/PracticeSessionDelegate';
import { PracticeHangmanState } from './PracticeHangmanState';
import {PracticeHangmanInteractor} from "../../../../../domain/Exercises/Hangman/Practice/PracticeHangmanInteractor";
import {PracticeHangmanUIHandler} from "../../../../../domain/Exercises/Hangman/Practice/PracticeHangmanUIHandler";

/**
 * Аналог Swift PracticeHangmanViewModel — «тонкий» класс,
 * который содержит ссылку на интерактор, и наблюдаемый state (MobX).
 */
export class PracticeHangmanViewModel {
    state: PracticeHangmanState;
    private interactor: PracticeHangmanInteractor;
    id: string;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: PracticeHangmanUIHandler
    ) {
        if (exercise.type.kind !== "hangman") {
            throw new Error("Not a hangman exercise");
        }
        this.id = exercise.id;

        // Если нужен онлайн-режим, прокидываем uiHandler
        this.interactor = new PracticeHangmanInteractor(exercise, delegate, uiHandler);
        this.state = this.interactor.state;

        this.interactor.onStateChange = (newState) => {
            this.state = newState;
        };

        makeAutoObservable(this);
    }

    guessLetter(letter: string) {
        this.interactor.guessLetter(letter);
    }

    handleGameEnd(didWin: boolean) {
        this.interactor.handleGameEnd(didWin);
    }
}