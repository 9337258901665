import React, { useEffect } from "react";
import {
    Exercise,
    ExerciseMatchingPairs,
    MatchingPairItem,
    PairElement,
} from "../../../../../domain/models";
import {MatchingPairsExerciseViewModel} from "../Practice/MatchingPairsExerciseViewModel";
import {MatchingPairsExerciseView} from "../Practice/MatchingPairsExerciseView";
import {Resizer} from "../../../Resizer";

/**
 * Компонент-превью, который показывает MatchingPairsExerciseView
 * с некоторыми «насильно» предвыбранными (matched) парами.
 */
export function MatchingPairsTypePreview() {
    const pairs: MatchingPairItem[] = [
        new MatchingPairItem({ id: "1", text: "Apple" },   { id: "r1", text: "Manzana" }),
        new MatchingPairItem({ id: "2", text: "Book" },    { id: "r2", text: "Libro" }),
        new MatchingPairItem({ id: "3", text: "House" },   { id: "r3", text: "Casa" }),
        new MatchingPairItem({ id: "4", text: "Tree" },    { id: "r4", text: "Árbol" }),
        new MatchingPairItem({ id: "5", text: "Cat" },     { id: "r5", text: "Gato" }),
        new MatchingPairItem({ id: "6", text: "Dog" },     { id: "r6", text: "Perro" }),
        new MatchingPairItem({ id: "7", text: "Car" },     { id: "r7", text: "Coche" }),
        new MatchingPairItem({ id: "8", text: "School" },  { id: "r8", text: "Escuela" }),
        new MatchingPairItem({ id: "9", text: "Table" },   { id: "r9", text: "Mesa" }),
    ];

    const rightItemsOrder = ["r6","r1","r4","r2","r3","r7","r9","r5","r8"];

    const mockExercise: Exercise = {
        id: "mock-exercise-1",
        type: {
            kind: "matchingPairs",
            data: new ExerciseMatchingPairs(pairs, rightItemsOrder),
        },
    };

    const viewModel = new MatchingPairsExerciseViewModel(mockExercise);
    autoMatchPair("7", "r7");  // Apple -> Manzana
    autoMatchPair("6", "r6");  // Tree -> Árbol

    function autoMatchPair(leftId: string, rightId: string) {
        const leftItem: PairElement = { id: leftId };
        const rightItem: PairElement = { id: rightId };

        viewModel.selectLeftItem(leftItem);
        viewModel.selectRightItem(rightItem);
    }

    return (
        <Resizer targetSize={{ width: 200, height: 160 }}>
            <div style={{ padding: 16 }}>
                <MatchingPairsExerciseView viewModel={viewModel} />
            </div>
        </Resizer>
    );
}