// BaseView.tsx
import React, { HTMLAttributes } from 'react';

interface BaseViewProps extends HTMLAttributes<HTMLDivElement> {
    padding?: number;
    frameWidth?: number;
    frameHeight?: number;
    style?: React.CSSProperties;
}

export function BaseView({
                             children,
                             padding,
                             frameWidth,
                             frameHeight,
                             style,
                             ...divProps // остальные пропсы
                         }: BaseViewProps) {
    return (
        <div
            {...divProps} // <-- прокидываем события, onContextMenu и пр.
            style={{
                padding: padding ? `${padding}px` : undefined,
                width: frameWidth ? `${frameWidth}px` : undefined,
                height: frameHeight ? `${frameHeight}px` : undefined,
                boxSizing: 'border-box',
                ...style
            }}
        >
            {children}
        </div>
    );
}