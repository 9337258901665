

// 8. NotesWindowViewModel (mirrors SwiftUI's NotesWindowViewModel):
// We'll keep a separate ViewModel class that the React UI can observe.
import {makeAutoObservable} from "mobx";
import {NotesState} from "./NotesState";
import {NotesInteractor} from "./NotesInteractor";

export class NotesWindowViewModel {
    // currentState is derived from the interactor's state.
    // In Swift, we used Combine to receive updates; here we'll just get from mobx's state.
    public get currentState(): NotesState {
        return this.interactor.state;
    }

    constructor(private interactor: NotesInteractor) {
        makeAutoObservable(this);
    }

    public addTextItem() {
        this.interactor.addTextItem();
    }

    public async addFileItem(file: File) {
        try {
            await this.interactor.addFileItem(file);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    }

    public updateText(itemID: string, newText: string) {
        this.interactor.updateText(itemID, newText);
    }

    public moveItems(fromOffsets: number[], toOffset: number) {
        this.interactor.moveItems(fromOffsets, toOffset);
    }

    public deleteItems(offsets: number[]) {
        this.interactor.deleteItems(offsets);
    }
}