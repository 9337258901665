import React from "react";

interface BodyPartViewProps {
    // Координата верхней точки
    x: number;
    y: number;
    // длина (по вертикали)
    height: number;
    // толщина линии
    lineWidth: number;
    // угол поворота (в градусах)
    rotation?: number;
}

const BodyPartView: React.FC<BodyPartViewProps> = ({
                                                       x,
                                                       y,
                                                       height,
                                                       lineWidth,
                                                       rotation = 0,
                                                   }) => {
    // Линия от (x, y) до (x, y + height)
    const x2 = x;
    const y2 = y + height;

    // Трансформация поворота вокруг (x,y)
    const transform = `rotate(${rotation} ${x} ${y})`;

    const pathData = `
    M ${x} ${y}
    L ${x2} ${y2}
  `;

    return (
        <path
            d={pathData}
            stroke="black"
            strokeWidth={lineWidth}
            fill="none"
            transform={transform}
        />
    );
};

export default BodyPartView;