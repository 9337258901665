// SaveTableUseCase.ts


import {ExerciseTable} from "../../models";
import {TableModel} from "./Models/TableModel";
import {modelToExerciseTable} from "./Models/Mapping";

/**
 * Аналог Swift протокола SaveTableUseCaseProtocol
 */
export interface SaveTableUseCaseProtocol {
    createExerciseTable(model: TableModel): ExerciseTable;
}

/**
 * Аналог Swift struct SaveTableUseCase
 */
export class SaveTableUseCase implements SaveTableUseCaseProtocol {
    constructor() {}

    public createExerciseTable(model: TableModel): ExerciseTable {
        // Swift: return model.exerciseData
        // TS: modelToExerciseTable(model)
        return modelToExerciseTable(model);
    }
}