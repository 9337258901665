// ./domain/models/Exercise.ts
import {WordItem} from "./WordItem";

export interface Exercise {
    id: string;
    type: ExerciseType;
}

export type ExerciseType =
    | { kind: 'missingWord'; data: ExerciseMissingWord }
    | { kind: 'selectOption'; data: ExerciseSelectOption }
    | { kind: 'wordSearch'; data: ExerciseWordSearch }
    | { kind: 'anagram'; data: ExerciseAnagram }
    | { kind: 'crossword'; data: ExerciseCrossword }
    | { kind: 'fillBlanks'; data: ExerciseFillBlanks }
    | { kind: 'matchingPairs'; data: ExerciseMatchingPairs }
    | { kind: 'justContent'; data: ExerciseJustContent }
    | { kind: 'table'; data: ExerciseTable }
    | { kind: 'hangman'; data: ExerciseHangman };

export interface ExerciseMissingWord {
    sentence: string;
    correctForm: string
}

export interface ExerciseSelectOption {
    sentence: string;
    correctOption: string;
    option1: string;
    option2: string;
    option3: string;
    option4: string;
}

export interface ExerciseWordSearch {
    words: WordItem[];
    grid: string[][];  // двумерный массив
    // Можно добавить геттер size? В TS обычно делается методом или computed property.
}

export interface ExerciseCrossword {
    words: WordItem[];
    // rowsCount: number; columnsCount: number; // Можно хранить явно
    // flattenedGrid: string[];                // Либо напрямую grid: string[][]
    placedWords: PlacedWord[];
    occupiedPositions: Position[];
    startPositions: Record<string, number>; // Swift: [Position: Int]
    // Для grid – можно хранить так:
    grid: string[][];
}

export interface PlacedWord {
    word: WordItem;
    horizontal: boolean;
    startRow: number;
    startCol: number;
    wordIndex: number;
}

// Position.ts
export interface Position {
    row: number;
    col: number;
}

export type FillBlanksCreationType = 'generated' | 'recognized';

export interface ExerciseFillBlanks {
    words: WordItem[];
    text: string;
    audioURL?: string; // или URL? В TS обычно string
    creationType?: FillBlanksCreationType;
}

export interface ExerciseJustContent {
    contents: ContentType[];
}

export interface DBColumn {
    header: string;
    items: string[];
}

export interface ExerciseTable {
    columns: DBColumn[];
    stableCells: Array<{ [key: string]: number }>;
}

export interface ExerciseHangman {
    word: string;
    hint: string;
}

export interface ExerciseAnagram {
    word: WordItem;
}

export interface PairElement {
    id: string;       // UUID в виде строки
    text?: string;
    contentURL?: string; // например, ссылка на картинку или аудио
}

export class MatchingPairItem {
    left: PairElement;
    right: PairElement;

    constructor(left: PairElement, right: PairElement) {
        this.left = left;
        this.right = right;
    }
}

export class ExerciseMatchingPairs {
    pairs: MatchingPairItem[];
    rightItemsOrder?: string[];

    constructor(pairs: MatchingPairItem[], rightItemsOrder?: string[]) {
        this.pairs = pairs;
        this.rightItemsOrder = rightItemsOrder;
    }
}

export type ContentType =
    | { kind: 'text'; text: string }
    | { kind: 'audio'; url: URL }
    | { kind: 'video'; url: URL }
    | { kind: 'image'; url: URL }
    | { kind: 'link'; url: URL };

export interface ContentData {
    text?: string;
    content?: ContentType;
}