// LessonPracticeViewModel.ts

import { makeAutoObservable, runInAction } from "mobx";
import { Lesson } from "../../../../domain/models";
import { Exercise, ExerciseSelectOption, ExerciseMissingWord, ExerciseAnagram, ExerciseTable } from "../../../../domain/models";
import { WordItem } from "../../../../domain/models";
import { LessonPracticeSession } from "../../../../domain/services/LessonPracticeSession";
import CompositionRoot from "../../../../compositionRoot";
import { PracticeSessionDelegate } from "../../../../domain/Exercises/PracticeSessionDelegate";
import { PracticeSessionDelegateStub } from "../../../../domain/Exercises/PracticeSessionDelegate";

// Anagram
import { AnagramLearnInteractor } from "../../../../domain/Exercises/Anagram/AnagramLearnInteractor";
import { AnagramLearnViewModel } from "../../exercises_types/Anagram/Practice/AnagramLearnViewModel";
// Table
import { PracticeTableViewModel } from "../../exercises_types/Table/Practice/PracticeTableViewModel";
import {
    MatchingPairsExerciseViewModel
} from "../../exercises_types/MatchingPairs/Practice/MatchingPairsExerciseViewModel";
import {SessionStateInteractor} from "../../../../domain/Multiplayer/SessionStateInteractor";
import {SessionRepository} from "../../../../domain/Multiplayer/SessionRepository";
import {PracticeHangmanViewModel} from "../../exercises_types/Hangman/Practice/PracticeHangmanViewModel";
import {MatchingPairsUseCase} from "../../../../domain/Exercises/MatchingPairs/Practice/MatchingPairsUseCase";
// (При необходимости импортируем другие viewModel)

// Тип, аналогичный вашему type PracticeStateUI:
export type PracticeStateUI =
    | { type: "selectOption"; exercise: ExerciseSelectOption; id: string }
    | { type: "missingWord"; exercise: ExerciseMissingWord; id: string }
    | { type: "anagram"; id: string; vm: AnagramLearnViewModel }
    | { type: "matchingPairs"; id: string; vm: MatchingPairsExerciseViewModel }
    | { type: "wordSearch"; id: string }
    | { type: "crossword"; id: string }
    | { type: "fillBlanks"; id: string }
    | { type: "justContent"; id: string }
    | { type: "table"; id: string; vm: PracticeTableViewModel }
    | { type: "hangman"; id: string; vm: PracticeHangmanViewModel }
    | { type: "done" };

export class LessonPracticeViewModel implements PracticeSessionDelegate {
    /**
     * Аналоги Swift:
     *  @Published var set: Lesson?
     *  var session: LessonPracticeSession?
     *  let sessionStateInteractor: SessionStateInteractor
     */
    lesson: Lesson | null = null;
    session: LessonPracticeSession | null = null;

    // Аналог Swift @Published var practiceState: PracticeStateUI
    practiceState: PracticeStateUI = { type: "done" };

    // Эквивалент Swift let sessionStateInteractor: SessionStateInteractor
    public sessionStateInteractor: SessionStateInteractor;

    // Зависимости (как в Swift serviceLocator)
    private storage = CompositionRoot.getExercisesStorage();
    private practiceStorage = CompositionRoot.practiceSessionStorage;
    private userManager = CompositionRoot.getAppUserManager();

    constructor(private lessonId: string) {
        makeAutoObservable(this);

        // Создаем SessionStateInteractor, как в Swift init(id:)
        const sessionRepo: SessionRepository = CompositionRoot.sessionRepository;
        this.sessionStateInteractor = new SessionStateInteractor(lessonId, sessionRepo);

        // Аналог Swift sessionStateInteractor.onIndexChange = { [weak self] newIndex in ... }
        this.sessionStateInteractor.onIndexChange = (newIndex: number) => {
            if (this.session && this.session.currentIndex !== newIndex) {
                runInAction(() => {
                    this.session!.currentIndex = newIndex;
                });
                this.getExercise();
            }
        };

        // Загружаем Lesson (как Swift loadSet())
        this.loadLesson();
    }

    // Аналог Swift var totalExercises: Int
    get totalExercises(): number {
        return this.lesson?.exercises.length ?? 0;
    }

    // Аналог Swift var currentExerciseIndex: Int
    get currentExerciseIndex(): number {
        return this.session?.currentIndex ?? 0;
    }

    /**
     * Аналог Swift loadSet():
     *  1) Подгружаем lesson
     *  2) Создаем LessonPracticeSession
     *  3) Синхронизируем session.currentIndex с sessionStateInteractor.currentExerciseIndex
     *  4) ensureInitialState(...)
     *  5) getExercise()
     */
    private async loadLesson() {
        try {
            console.log("Loading lesson with id:", this.lessonId);
            const lesson = await this.storage.getExerciseSet(this.lessonId);
            runInAction(() => {
                this.lesson = lesson;
            });

            const practiceSession = new LessonPracticeSession(
                lesson,
                this.userManager,
                this.practiceStorage
            );
            runInAction(() => {
                this.session = practiceSession;
            });

            // Ставим локальный currentIndex из sessionStateInteractor
            const existingIndex = this.sessionStateInteractor.currentExerciseIndex;
            if (existingIndex !== undefined) {
                runInAction(() => {
                    this.session!.currentIndex = existingIndex;
                });
            }

            // Убеждаемся, что в Firestore создано начальное состояние
            await this.sessionStateInteractor.ensureInitialState(lesson.exercises);

            // Вызываем getExercise (как в Swift)
            runInAction(() => {
                this.getExercise();
            });
        } catch (err) {
            console.error("Failed to load lesson:", err);
            runInAction(() => {
                this.practiceState = { type: "done" };
            });
        }
    }

    // -----------------------------
    // PracticeSessionDelegate
    // -----------------------------
    saveAttempt(exercise: Exercise, answer: any) {
        this.session?.saveAttempt(exercise, answer);
    }

    /**
     * currentStepDone():
     *  Swift:
     *   session?.goNext()
     *   if let curIndex = session?.currentIndex { sessionStateInteractor.updateCurrentExerciseIndex(curIndex) }
     *   getExercise()
     */
    currentStepDone() {
        if (!this.session) return;

        this.session.goNext();
        const newIndex = this.session.currentIndex;
        this.sessionStateInteractor.updateCurrentExerciseIndex(newIndex);
        this.getExercise();
    }

    // Если вам нужно кнопка "Next"
    goNext() {
        if (!this.session) return;

        this.session.goNext();
        const newIndex = this.session.currentIndex;
        this.sessionStateInteractor.updateCurrentExerciseIndex(newIndex);
        this.getExercise();
    }

    /**
     * Основная функция: достаем текущее упражнение (session.getExercise()),
     * переключаем practiceState.
     *
     * По аналогии с Swift getExercise():
     *  switch exercise.type { ... }
     *    case .table: -> setupTable(exercise: Exercise)
     *    case .anagram: -> setupAnagram(exercise: Exercise)
     *    ...
     */
    getExercise() {
        if (!this.session) {
            this.practiceState = { type: "done" };
            return;
        }
        const exercise = this.session.getExercise();
        if (!exercise) {
            this.practiceState = { type: "done" };
            return;
        }

        // switch exercise.type.kind { ... }
        switch (exercise.type.kind) {
            case "table": {
                // Аналог Swift setupTable
                // 1) Получаем handler из sessionStateInteractor
                const handler = this.sessionStateInteractor.tableHandler(exercise.id);
                // 2) Создаем PracticeTableViewModel
                const vm = new PracticeTableViewModel(exercise, this, handler);
                this.practiceState = { type: "table", id: exercise.id, vm };
                break;
            }

            case "anagram": {
                // Аналог Swift setupAnagram
                // Пример:
                const exAnagram = exercise.type.data as { word: WordItem };
                const dummyUseCase = {}; // или real
                const dummyUIHandler = null; // или real
                const dummyDelegate = null; // или real

                const interactor = new AnagramLearnInteractor({
                    wordItem: exAnagram.word,
                    delegate: dummyDelegate,
                    uiHandler: dummyUIHandler,
                    useCase: dummyUseCase,
                });
                const vm = new AnagramLearnViewModel({ interactor });
                this.practiceState = { type: "anagram", id: exercise.id, vm };
                break;
            }

            case "hangman": {
                const handler = this.sessionStateInteractor.hangmanHandler(exercise.id);
                const vm = new PracticeHangmanViewModel(exercise, this, handler)
                this.practiceState = { type: "hangman", id: exercise.id, vm };
                break
            }

            case "matchingPairs": {
                const handler = this.sessionStateInteractor.matchingPairsHandler(exercise.id)
                const useCase = new MatchingPairsUseCase()
                const vm = new MatchingPairsExerciseViewModel(exercise, this, handler, useCase)
                this.practiceState = { type: "matchingPairs", id: exercise.id, vm };
                break
            }

            // другие режимы ...
            default:
                this.practiceState = { type: "done" };
                break;
        }
    }
}