// ExerciseCreationFlowView.tsx
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
// или без mobx, тогда классViewModel -> useState

import { ExerciseCreationFlowViewModel } from "./ExerciseCreationFlowViewModel";
import { CreateExerciseFlowStep, getFlowStepTitle } from "./CreateExerciseFlowStep";
import { Exercise } from "../../../domain/models";
import {ExerciseTypeSelectionView} from "./ExerciseTypeSelection/ExerciseTypeSelectionView";
import {ExerciseCreatingSettingsView} from "./ExerciseCreatingSettings/ExerciseCreatingSettingsView";
import {ExerciseEditingView} from "./ExerciseEditingConfirmingPreview/ExerciseEditingView";

interface Props {
    onSelect: (exercise: Exercise) => void;
    onClose: () => void;
}

export const ExerciseCreationFlowView = observer(({ onSelect, onClose }: Props) => {
    const [viewModel] = useState(() => new ExerciseCreationFlowViewModel());

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", paddingTop: 50 }}>
            <div style={{ flex: 1, borderTop: "1px solid #ccc", padding: 16, minWidth: 800, width: "80%" }}>
                {renderStep()}
            </div>
        </div>
    );

    function renderStep() {
        switch (viewModel.createExerciseFlowStep) {
            case CreateExerciseFlowStep.selectType:
                return (
                    <ExerciseTypeSelectionView
                        onSelect={(type) => {
                            viewModel.selectedExerciseType = type;
                            viewModel.createExerciseFlowStep = CreateExerciseFlowStep.settings;
                        }}
                        onClose={onClose}
                    />
                );
            case CreateExerciseFlowStep.settings:
                if (!viewModel.selectedExerciseType) {
                    // fallback
                    return <div>No type selected</div>;
                }
                return (
                    <ExerciseCreatingSettingsView
                        selectedExerciseType={viewModel.selectedExerciseType}
                        grammarCreationMethod={viewModel.grammarCreationMethod}
                        fillBlanksCreationMethod={viewModel.fillBlanksCreationMethod}
                        createExerciseFlowStep={viewModel.createExerciseFlowStep}
                        onGrammarMethodChange={(val) => {
                            viewModel.grammarCreationMethod = val;
                        }}
                        onFillBlanksMethodChange={(val) => {
                            viewModel.fillBlanksCreationMethod = val;
                        }}
                        onStepChange={(step) => {
                            viewModel.createExerciseFlowStep = step;
                        }}
                        onExercisesGenerated={(exs) => {
                            exs.forEach(ex => {
                                onSelect(ex)
                            });
                        }}
                        onBack={() => {
                            // Если user cancels method
                            viewModel.grammarCreationMethod = null;
                            viewModel.fillBlanksCreationMethod = null;
                            viewModel.createExerciseFlowStep = CreateExerciseFlowStep.selectType;
                        }}
                    />
                );
            case CreateExerciseFlowStep.editing:
                return (
                    <>
                        <ExerciseEditingView
                            exercises={viewModel.tempExercises}
                            onSelect={(ex) => {
                                onSelect(ex);
                                // Удаляем добавленный ex из temp (как Swift code)
                                viewModel.tempExercises = viewModel.tempExercises.filter((e) => e.id !== ex.id);
                            }}
                        />
                        <div style={{ marginTop: 20, display: "flex", justifyContent: "space-between" }}>
                            <button
                                onClick={() => {
                                    viewModel.createExerciseFlowStep = CreateExerciseFlowStep.settings;
                                }}
                            >
                                Back
                            </button>
                            <button onClick={() => onClose()}>Готово</button>
                        </div>
                    </>
                );
            default:
                return <div>Unknown step</div>;
        }
    }
});