// ===============================
// PracticeHangmanUIHandler.ts
// ===============================
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { ExerciseUIHandler } from "../../../Multiplayer/ExerciseUIHandler";
import { SessionStateInteractor } from "../../../Multiplayer/SessionStateInteractor";
import { ExerciseUIState } from "../../../Multiplayer/ExerciseUIState";
import { PracticeHangmanUIState, PracticeHangmanUIStateUtils } from "./PracticeHangmanUIState";

/**
 * Класс, отвечающий за онлайн-синхронизацию Hangman.
 * Аналог PracticeTableUIHandler, но для Hangman.
 */
export class PracticeHangmanUIHandler implements ExerciseUIHandler {
    // Локально храним текущее состояние (MobX)
    public uiState: PracticeHangmanUIState;

    private exerciseId: string;
    private interactor?: SessionStateInteractor;
    private isUpdatingFromServer = false;

    private currentUIState: PracticeHangmanUIState;

    constructor(
        exerciseId: string,
        interactor?: SessionStateInteractor,
        initialUIState?: PracticeHangmanUIState
    ) {
        this.exerciseId = exerciseId;
        this.interactor = interactor;

        this.currentUIState = initialUIState ?? {
            guessedLetters: [],
            mistakes: 0,
        };

        // Создаём mobx-объект на базе текущего UIState
        this.uiState = { ...this.currentUIState };

        makeAutoObservable(this);

        // Следим за изменениями (guessedLetters, mistakes).
        reaction(
            () => ({
                guessedLetters: this.uiState.guessedLetters.slice(),
                mistakes: this.uiState.mistakes,
            }),
            () => {
                if (!this.isUpdatingFromServer) {
                    this.sendUpdateIfNeeded();
                }
            }
        );
    }

    /**
     * Метод, который вызывается SessionStateInteractor,
     * когда приходит обновлённое состояние из Firestore.
     */
    public updateUIState(uiState: ExerciseUIState | undefined): void {
        if (!uiState || uiState.type !== "hangman") {
            return;
        }

        // Преобразуем "сырые" данные из Firestore в PracticeHangmanUIState
        const newState = PracticeHangmanUIStateUtils.fromFirestore(uiState.data);

        // Сравниваем с текущим
        if (!PracticeHangmanUIStateUtils.equals(newState, this.currentUIState)) {
            this.isUpdatingFromServer = true;
            runInAction(() => {
                this.currentUIState = newState;
                // Обновляем mobx-объект
                this.uiState = { ...newState };
            });
            this.isUpdatingFromServer = false;
        }
    }

    private sendUpdateIfNeeded() {
        if (this.isUpdatingFromServer) return;

        console.log("PracticeHangmanUIHandler sendUpdateIfNeeded start")
        // Создаём копию из mobx-объекта
        const newState: PracticeHangmanUIState = {
            guessedLetters: [...this.uiState.guessedLetters],
            mistakes: this.uiState.mistakes,
        };

        console.log("PracticeHangmanUIHandler sendUpdateIfNeeded newState: ", newState)
        // Проверим, отличается ли от currentUIState
        if (PracticeHangmanUIStateUtils.equals(newState, this.currentUIState)) {
            console.log("PracticeHangmanUIHandler sendUpdateIfNeeded states are equal")
            return; // нет изменений
        }

        this.currentUIState = newState;

        // Отправим обновление в SessionStateInteractor
        this.interactor?.updateHangmanState(this.exerciseId, newState);
    }
}