import React, {useEffect, useRef} from "react";

/** Хук для отслеживания изменения размеров через ResizeObserver */
export function useResizeObserver(
    ref: React.RefObject<HTMLElement | null>,
    callback: () => void
) {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        const element = ref.current;
        if (!element) return;

        const observer = new ResizeObserver(() => {
            callbackRef.current();
        });
        observer.observe(element);

        return () => {
            observer.disconnect();
        };
    }, [ref]);
}