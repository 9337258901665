export interface HangmanGameModel {
    targetWord: string;      // загаданное слово (верхний регистр)
    hint: string;            // подсказка к слову
    guessedLetters: Set<string>;
    mistakes: number;        // текущее количество ошибок
    maxMistakes: number;     // максимально допустимое количество ошибок
}

export function createGameModel(
    word: string,
    hint: string,
    maxMistakes: number = 6
): HangmanGameModel {
    return {
        targetWord: word.toUpperCase(),
        hint,
        guessedLetters: new Set(),
        mistakes: 0,
        maxMistakes,
    };
}

export function didWin(model: HangmanGameModel): boolean {
    // Если все буквы в слове уже угаданы
    for (const ch of model.targetWord) {
        if (!model.guessedLetters.has(ch)) {
            return false;
        }
    }
    return true;
}

export function didLose(model: HangmanGameModel): boolean {
    return model.mistakes >= model.maxMistakes;
}

export function isGameOver(model: HangmanGameModel): boolean {
    return didWin(model) || didLose(model);
}

export function stage(model: HangmanGameModel): number {
    // Текущая стадия для виселицы (0..maxMistakes)
    return Math.min(model.mistakes, model.maxMistakes);
}

export function displayedWord(model: HangmanGameModel): string {
    let result = "";
    for (const ch of model.targetWord) {
        if (model.guessedLetters.has(ch)) {
            result += ch;
        } else {
            result += "_";
        }
        result += " ";
    }
    return result.trim();
}

export function guessLetter(model: HangmanGameModel, letter: string): HangmanGameModel {
    const upper = letter.toUpperCase();

    // Если уже угадали/пробовали эту букву — не делаем ничего
    if (model.guessedLetters.has(upper)) {
        return model;
    }

    // Новая копия guessedLetters
    const newGuessedLetters = new Set(model.guessedLetters);
    newGuessedLetters.add(upper);

    // Проверяем, есть ли буква в слове
    const isInWord = model.targetWord.includes(upper);
    const newMistakes = isInWord ? model.mistakes : model.mistakes + 1;

    return {
        ...model,
        guessedLetters: newGuessedLetters,
        mistakes: newMistakes,
    };
}