// Base/TableView.tsx
import React from 'react';
import { TableRowView } from './TableRowView';
import {TableModel} from "../../../../../domain/Exercises/Table/Models/TableModel";

interface TableViewProps {
    columns: TableModel['columns'];
    stableCells: TableModel['stableCells'];

    toggleStable: (rowIndex: number, columnIndex: number) => void;
    updatePlaceholder: (columnIndex: number, rowIndex: number | null, userInput: string) => void;
    confirmPressed: (columnIndex: number, rowIndex: number | null) => void;
    editPressed: (columnIndex: number, rowIndex: number | null) => void;
    fillRow: (rowIndex: number) => void;
    removeRow: (rowIndex: number) => void;
    loadingRows: Set<number>;
}

export function TableView(props: TableViewProps) {
    const {
        columns,
        stableCells,
        toggleStable,
        updatePlaceholder,
        confirmPressed,
        editPressed,
        fillRow,
        removeRow,
        loadingRows
    } = props;

    // вычислим количество строк
    const maxRows = columns.reduce((acc, col) => Math.max(acc, col.items.length), 0);

    // размеры
    const columnWidth = 120;
    const rowHeight = 60;
    const actionsColumnWidth = 110;
    const lineThickness = 2;

    return (
        <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
            {/* HEADER */}
            <TableRowView
                columns={columns}
                rowIndex={null}
                stableCells={stableCells}
                toggleStable={toggleStable}
                updatePlaceholder={updatePlaceholder}
                confirmPressed={confirmPressed}
                editPressed={editPressed}
                fillRow={fillRow}
                removeRow={removeRow}
                isLoading={false} // header row not loading
                columnWidth={columnWidth}
                rowHeight={rowHeight}
                lineColor={'rgba(128,128,128,0.5)'}
                lineThickness={lineThickness}
                actionsColumnWidth={actionsColumnWidth}
            />

            {/* BODY ROWS */}
            {Array.from({ length: maxRows }).map((_, rowIndex) => (
                <TableRowView
                    key={rowIndex}
                    columns={columns}
                    rowIndex={rowIndex}
                    stableCells={stableCells}
                    toggleStable={toggleStable}
                    updatePlaceholder={updatePlaceholder}
                    confirmPressed={confirmPressed}
                    editPressed={editPressed}
                    fillRow={fillRow}
                    removeRow={removeRow}
                    isLoading={loadingRows.has(rowIndex)}
                    columnWidth={columnWidth}
                    rowHeight={rowHeight}
                    lineColor={'rgba(128,128,128,0.5)'}
                    lineThickness={lineThickness}
                    actionsColumnWidth={actionsColumnWidth}
                />
            ))}
        </div>
    );
}