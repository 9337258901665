// ui/components/exercise/AllExercisesView.tsx
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite"; // если mobx
// или без mobx: const AllExercisesView = () => { ... }

import { Exercise } from "../../../domain/models";
import { ExerciseTextPreview } from "./ExerciseTextPreview";
import { CreateExerciseModal } from "./CreateExerciseModal";
import { ExercisePreviewModal } from "./ExercisePreviewModal";
import { EditExerciseModal } from "./EditExerciseModal";
import {AllExercisesViewModel, ExerciseTypeFilter} from "./AllExercisesViewModel";
import {
    ExercisesListInteractorImpl,
    ExercisesListInteractorStub
} from "../../../domain/AllExercises/ExercisesListInteractor";
import {
    SaveSingleExerciseInteractorImpl,
    SaveSingleExerciseInteractorStub
} from "../../../domain/AllExercises/SaveSingleExerciseInteractor";
import {
    CreateSimilarInteractorMock,
    CreateSimilarInteractorStub
} from "../../../domain/AllExercises/CreateSimilarInteractor";
import CompositionRoot from "../../../compositionRoot";
import {SimilarTableUseCase} from "../../../domain/Exercises/Table/SimilarTableUseCase";
import {FilterSegmented} from "./FilterSegmented";

export const AllExercisesView = observer(() => {

    const storage = CompositionRoot.exercisesStorage;
    const tableRequester = CompositionRoot.tableRequester;
    // Создаём VM + заглушечные интеракторы
    const [viewModel] = useState(() => new AllExercisesViewModel(
        new ExercisesListInteractorImpl(storage),
        new SaveSingleExerciseInteractorImpl(storage),
        new CreateSimilarInteractorMock(new SimilarTableUseCase(tableRequester))
    ));

    const [creatingExercise, setCreatingExercise] = useState(false);

    // Аналог Swift: Picker("Filter", selection: $viewModel.selectedFilter)
    // В React: <select> or <Segmented>
    // Зададим enum values: [all, missingWord, ...]
    const filters = Object.values(ExerciseTypeFilter);

    return (
        <div style={{ position: "relative", minHeight: "100vh" }}>
            {/* Filter */}
            <div style={{ padding: 16 }}>
                <FilterSegmented
                    value={viewModel.selectedFilter}
                    onChange={(newVal) => {
                        viewModel.selectedFilter = newVal;
                    }}
                />
            </div>

            {/* Exercises Grid */}
            <div style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                gap: "16px",
                padding: "16px"
            }}>
                {viewModel.filteredExercises.map((exercise) => (
                    <div
                        key={exercise.id}
                        style={{ border: "1px solid #ccc", cursor: "pointer" }}
                        onClick={() => (viewModel.previewingExercise = exercise)}
                        onContextMenu={(e) => {
                            e.preventDefault();
                            // contextMenu handle => createSimilar
                            viewModel.generateSimilarExercise(exercise);
                        }}
                    >
                        <ExerciseTextPreview exercise={exercise} />
                    </div>
                ))}
            </div>

            {/* "Plus" button */}
            <button
                style={{
                    position: "absolute",
                    right: 16,
                    bottom: 40,
                    width: 60,
                    height: 60,
                    borderRadius: 30,
                    backgroundColor: "blue",
                    color: "white",
                    fontSize: 24,
                    border: "none",
                    boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
                    cursor: "pointer"
                }}
                onClick={() => setCreatingExercise(true)}
            >
                +
            </button>

            {/* Sheet (modal) for creating exercise */}
            {creatingExercise && (
                <CreateExerciseModal
                    onClose={() => setCreatingExercise(false)}
                    onSelect={(newEx: Exercise) => {
                        viewModel.addExercise(newEx);
                        setCreatingExercise(false);
                    }}
                />
            )}

            {/* Sheet for previewing */}
            {viewModel.previewingExercise && (
                <ExercisePreviewModal
                    exercise={viewModel.previewingExercise}
                    onEdit={(ex) => {
                        viewModel.editExercise(ex);
                        // modal close inside
                    }}
                    onRemove={(ex) => {
                        viewModel.removeExercise(ex);
                        viewModel.previewingExercise = null;
                    }}
                    onCreateSimilar={(ex) => {
                        viewModel.generateSimilarExercise(ex);
                    }}
                    onClose={() => {
                        viewModel.previewingExercise = null;
                    }}
                />
            )}

            {/* Sheet for editing */}
            {viewModel.editingExercise && (
                <EditExerciseModal
                    exercise={viewModel.editingExercise}
                    onClose={() => {
                        viewModel.editingExercise = null;
                    }}
                    onSave={(updatedExercise) => {
                        viewModel.saveEditedExercise(updatedExercise);
                        viewModel.editingExercise = null;
                    }}
                />
            )}
        </div>
    );
});