// ExercisePreviewModal.tsx
import React from 'react';
import { Exercise } from '../../../domain/models';

interface Props {
    exercise: Exercise;
    onEdit: (ex: Exercise) => void;
    onRemove: (ex: Exercise) => void;
    onCreateSimilar: (ex: Exercise) => void;
    onClose: () => void;
}

export function ExercisePreviewModal({ exercise, onEdit, onRemove, onCreateSimilar, onClose }: Props) {
    return (
        <div style={modalStyle}>
            <div style={modalContentStyle}>
                <h2>Exercise Preview</h2>
                <p>ID: {exercise.id}</p>
                <div> {/* Here you might do exercise.viewData.renderView(...) in real code */}</div>

                <div style={{ marginTop: 20 }}>
                    <button onClick={() => { onEdit(exercise); }}>Edit</button>
                    <button onClick={() => { onRemove(exercise); }}>Remove</button>
                    <button onClick={() => { onCreateSimilar(exercise); }}>Create Similar</button>
                    <button onClick={() => onClose()}>Close</button>
                </div>
            </div>
        </div>
    );
}
const modalStyle: React.CSSProperties = {
    position: "fixed",
    top: 0, left: 0, right: 0, bottom: 0,
    backgroundColor: "rgba(0,0,0,0.4)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
};
const modalContentStyle: React.CSSProperties = {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 8,
    minWidth: 300
};