// ========== Файл импорта (fileImporter) ==========
// В SwiftUI используется .fileImporter, мы сделаем BaseFileImporter как компонент,
// который показывает скрытый input type="file" при isPresented = true.
// При выборе файла вызовем onFileSelected или onError.
// allowedContentTypes: [.item] можно игнорировать или просто разрешить любой файл.

import React, { useRef, useEffect } from 'react';

interface BaseFileImporterProps {
    isPresented: boolean;
    onFileSelected: (fileUrl: URL) => void;
    onError: (error: Error) => void;
    onDismissed: () => void;
}

export function BaseFileImporter({ isPresented, onFileSelected, onError, onDismissed }: BaseFileImporterProps) {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isPresented && inputRef.current) {
            inputRef.current.value = '';
            inputRef.current.click();
        }
    }, [isPresented]);

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            // В реальном мире нельзя напрямую сделать URL файл-системы, сделаем заглушку
            const fileUrl = new URL(URL.createObjectURL(file));
            onFileSelected(fileUrl);
        } else {
            onError(new Error("No file selected"));
        }
        onDismissed();
    };

    return <input type="file" ref={inputRef} style={{ display: 'none' }} onChange={handleChange} />;
}
