// ./src/data/implementations/FirestoreExercisesService.ts

import { ExercisesStorage } from '../../domain/interfaces/ExercisesStorage';
import { db } from '../config/firebase';
import {
    doc,
    setDoc,
    getDoc,
    collection,
    addDoc,
    Timestamp,
    getDocs,
    QuerySnapshot
} from 'firebase/firestore';

import {
    Exercise,
    Lesson,
    ExerciseMissingWord,
    ExerciseSelectOption,
    ExerciseWordSearch,
    ExerciseAnagram,
    ExerciseCrossword,
    ExerciseFillBlanks,
    ExerciseMatchingPairs,
    ExerciseJustContent,
    ExerciseTable,
    WordItem,
    PairElement,
    MatchingPairItem, PlacedWord, ExerciseHangman,
} from "../../domain/models"; // Импортируем все наши типы

export class FirestoreExercisesService implements ExercisesStorage {
    constructor() {}

    public async saveExercise(exercise: Exercise): Promise<void> {
        // Например:
        const docRef = doc(db, 'exercises', exercise.id);
        const data = this.serializeExercise(exercise);
        await setDoc(docRef, data);
    }

    public async getAllExercises(): Promise<Exercise[]> {
        const collectionRef = collection(db, 'exercises');
        const snapshot = await getDocs(collectionRef);
        const exercises: Exercise[] = [];
        snapshot.forEach(docSnap => {
            const data = docSnap.data();
            const ex = this.deserializeExercise(data, docSnap.id);
            if (ex) exercises.push(ex);
        });
        return exercises;
    }

    /**
     * Сохраняет набор упражнений в Firestore.
     */
    public async saveExerciseSet(exerciseSet: Lesson): Promise<void> {
        if (!exerciseSet.authorId) {
            throw new Error('Author ID is required to save ExerciseSet');
        }

        const exerciseSetRef = doc(db, 'exerciseSets', exerciseSet.id);

        // Подготовка данных для основного документа (метаданные сета)
        const exerciseSetData = {
            id: exerciseSet.id,
            creationDate: Timestamp.fromDate(new Date()),
            authorId: exerciseSet.authorId,
            name: exerciseSet.name || '',
        };

        // Сохраняем основной документ
        await setDoc(exerciseSetRef, exerciseSetData);

        // Сохраняем упражнения как поддокументы в коллекции "exercises"
        const exercisesCollectionRef = collection(exerciseSetRef, 'exercises');
        for (let i = 0; i < exerciseSet.exercises.length; i++) {
            const exercise = exerciseSet.exercises[i];
            const exerciseData = this.serializeExercise(exercise);
            // Дополнительно можем сохранить orderIndex, если хотите аналог Swift
            exerciseData["orderIndex"] = i;
            await setDoc(doc(exercisesCollectionRef, exercise.id), exerciseData);
        }
    }

    /**
     * Получает набор упражнений из Firestore по ID.
     */
    public async getExerciseSet(id: string): Promise<Lesson> {
        const exerciseSetRef = doc(db, 'exerciseSets', id);
        const exerciseSetDoc = await getDoc(exerciseSetRef);

        if (!exerciseSetDoc.exists()) {
            throw new Error(`ExerciseSet not found for ID: ${id}`);
        }

        const exerciseSetData = exerciseSetDoc.data();
        const name = exerciseSetData.name || id;

        // Получаем подколлекцию "exercises"
        const exercisesCollectionRef = collection(exerciseSetRef, 'exercises');
        const exercisesSnapshot: QuerySnapshot = await getDocs(exercisesCollectionRef);

        const exercises: Exercise[] = exercisesSnapshot.docs
            .map((docSnapshot) => {
                const data = docSnapshot.data();
                return this.deserializeExercise(data, docSnapshot.id);
            })
            .filter((exercise): exercise is Exercise => exercise !== null);

        return {
            id: exerciseSetData.id,
            exercises: exercises,
            authorId: exerciseSetData.authorId,
            name: name,
        };
    }

    // ========================
    //   СЕРИАЛИЗАЦИЯ
    // ========================
    private serializeExercise(exercise: Exercise): any {
        switch (exercise.type.kind) {
            case 'missingWord': {
                const mw: ExerciseMissingWord = exercise.type.data;
                return {
                    type: 'missingWord',
                    sentence: mw.sentence,
                    correctForm: mw.correctForm
                };
            }
            case 'selectOption': {
                const so: ExerciseSelectOption = exercise.type.data;
                return {
                    type: 'selectOption',
                    sentence: so.sentence,
                    correctOption: so.correctOption,
                    option1: so.option1,
                    option2: so.option2,
                    option3: so.option3,
                    option4: so.option4
                };
            }
            case 'wordSearch': {
                const ws: ExerciseWordSearch = exercise.type.data;
                // Пример flatten для grid
                const rowsCount = ws.grid.length;
                const columnsCount = rowsCount > 0 ? ws.grid[0].length : 0;
                const flattenedGrid = ws.grid.flat();

                return {
                    type: 'wordSearch',
                    rowsCount,
                    columnsCount,
                    gridData: flattenedGrid,
                    words: ws.words.map(w => this.serializeWordItem(w))
                };
            }
            case 'anagram': {
                const an: ExerciseAnagram = exercise.type.data;
                return {
                    type: 'anagram',
                    word: this.serializeWordItem(an.word)
                };
            }
            case 'crossword': {
                const cw: ExerciseCrossword = exercise.type.data;
                // Можно сохранить как JSON
                // 1) Сериализуем в JS-объект
                const dict = this.crosswordToDict(cw);
                dict["type"] = 'crossword';
                return dict;
            }
            case 'fillBlanks': {
                const fb: ExerciseFillBlanks = exercise.type.data;
                const wordsArray = fb.words.map(w => this.serializeWordItem(w));
                return {
                    type: 'fillBlanks',
                    text: fb.text,
                    words: wordsArray,
                    audioURL: fb.audioURL || null
                };
            }
            case 'matchingPairs': {
                const mp: ExerciseMatchingPairs = exercise.type.data;
                const pairsArray = mp.pairs.map((pair) => ({
                    left: this.pairElementToDict(pair.left),
                    right: this.pairElementToDict(pair.right),
                }));
                return {
                    type: 'matchingPairs',
                    pairs: pairsArray,
                    rightItemsOrder: mp.rightItemsOrder?.map(uuid => uuid) || null
                };
            }
            case 'justContent': {
                const jc: ExerciseJustContent = exercise.type.data;
                // При желании можно сериализовать контент массивом объектов {type, value},
                // тут сделаем упрощённо, "contents": JSON.stringify(...)
                // Либо реализовать похожую логику, как в Swift
                return {
                    type: 'justContent',
                    contents: jc.contents.map(c => this.serializeContentType(c))
                };
            }
            case 'table': {
                const tbl: ExerciseTable = exercise.type.data;
                // Аналогично Swift: JSON encode
                // В JS просто:
                return {
                    type: 'table',
                    columns: tbl.columns,
                    stableCells: tbl.stableCells
                };
            }
            case 'hangman': {
                const h: ExerciseHangman = exercise.type.data;
                return {
                    type: 'hangman',
                    word: h.word,
                    hint: h.hint
                };
            }
            default:
                throw new Error('Unknown ExerciseType kind');
        }
    }

    // ========================
    //   ДЕСЕРИАЛИЗАЦИЯ
    // ========================
    private deserializeExercise(data: any, id: string): Exercise | null {
        const type = data.type;
        if (!type) return null;

        switch (type) {
            case 'missingWord': {
                const { sentence, correctForm } = data;
                if (
                    typeof sentence !== 'string' ||
                    typeof correctForm !== 'string'
                ) {
                    return null;
                }
                return {
                    id,
                    type: {
                        kind: 'missingWord',
                        data: {
                            sentence,
                            correctForm
                        }
                    }
                };
            }

            case 'selectOption': {
                const { sentence, correctOption, option1, option2, option3, option4 } = data;
                if (
                    typeof sentence !== 'string' ||
                    typeof correctOption !== 'string' ||
                    typeof option1 !== 'string' ||
                    typeof option2 !== 'string' ||
                    typeof option3 !== 'string' ||
                    typeof option4 !== 'string'
                ) {
                    return null;
                }
                return {
                    id,
                    type: {
                        kind: 'selectOption',
                        data: {
                            sentence,
                            correctOption,
                            option1,
                            option2,
                            option3,
                            option4
                        }
                    }
                };
            }

            case 'wordSearch': {
                const { rowsCount, columnsCount, gridData, words } = data;
                if (
                    typeof rowsCount !== 'number' ||
                    typeof columnsCount !== 'number' ||
                    !Array.isArray(gridData) ||
                    !Array.isArray(words)
                ) {
                    return null;
                }
                // Восстанавливаем двумерный массив
                const grid: string[][] = [];
                let index = 0;
                for (let r = 0; r < rowsCount; r++) {
                    const row = gridData.slice(index, index + columnsCount);
                    index += columnsCount;
                    if (!Array.isArray(row) || row.length !== columnsCount) {
                        return null;
                    }
                    grid.push(row);
                }
                // Десериализуем words
                const wordsDeser = words
                    .map((w: any) => this.deserializeWordItem(w))
                    .filter((w: WordItem | null): w is WordItem => w !== null);

                return {
                    id,
                    type: {
                        kind: 'wordSearch',
                        data: {
                            words: wordsDeser as WordItem[],
                            grid
                        }
                    }
                };
            }

            case 'anagram': {
                const { word } = data;
                if (!word) return null;
                const wordItem = this.deserializeWordItem(word);
                if (!wordItem) return null;
                return {
                    id,
                    type: {
                        kind: 'anagram',
                        data: {
                            word: wordItem
                        }
                    }
                };
            }

            case 'crossword': {
                // Аналогично Swift: тут dict -> объект
                // У нас есть rowsCount, columnsCount, flattenedGrid ...
                // Или вы сохраняете все поля. Я покажу пример через "crosswordFromDict"
                const cw = this.crosswordFromDict(data);
                if (!cw) return null;
                return {
                    id,
                    type: {
                        kind: 'crossword',
                        data: cw
                    }
                };
            }

            case 'fillBlanks': {
                const { text, words, audioURL } = data;
                if (typeof text !== 'string' || !Array.isArray(words)) {
                    return null;
                }
                const wordItems = words
                    .map((w: any) => this.deserializeWordItem(w))
                    .filter((wi: WordItem | null): wi is WordItem => wi !== null);

                return {
                    id,
                    type: {
                        kind: 'fillBlanks',
                        data: {
                            text,
                            words: wordItems as WordItem[],
                            audioURL: audioURL || undefined
                        }
                    }
                };
            }

            case 'matchingPairs': {
                const { pairs, rightItemsOrder } = data;
                if (!Array.isArray(pairs)) return null;
                const mpPairs: MatchingPairItem[] = pairs.map((p: any) => {
                    const left = p.left ? this.pairElementFromDict(p.left) : null;
                    const right = p.right ? this.pairElementFromDict(p.right) : null;
                    if (!left || !right) return null;
                    return { left, right };
                }).filter((x: MatchingPairItem | null) => x !== null) as MatchingPairItem[];

                // rightItemsOrder - массив строк (UUID), просто сохраним как string[]
                return {
                    id,
                    type: {
                        kind: 'matchingPairs',
                        data: {
                            pairs: mpPairs,
                            rightItemsOrder: Array.isArray(rightItemsOrder) ? rightItemsOrder : undefined
                        }
                    }
                };
            }

            case 'justContent': {
                const { contents } = data;
                if (!Array.isArray(contents)) {
                    return null;
                }
                const parsedContents = contents.map((c: any) => this.deserializeContentType(c))
                    .filter((c: any) => c !== null);

                return {
                    id,
                    type: {
                        kind: 'justContent',
                        data: {
                            contents: parsedContents
                        }
                    }
                };
            }

            case 'table': {
                // Здесь можно сделать JSON parse/encode (как в Swift).
                // Допустим, мы пишем напрямую:
                const { columns, stableCells } = data;
                if (!Array.isArray(columns) || !Array.isArray(stableCells)) {
                    return null;
                }
                return {
                    id,
                    type: {
                        kind: 'table',
                        data: {
                            columns,
                            stableCells
                        }
                    }
                };
            }

            case 'hangman': {
                const { word, hint } = data;
                if (typeof word !== 'string' || typeof hint !== 'string') {
                    return null;
                }
                return {
                    id,
                    type: {
                        kind: 'hangman',
                        data: { word, hint }
                    }
                };
            }
            default:
                return null;
        }
    }

    // ===============================
    //     HELPERS для слов, etc.
    // ===============================

    /**
     * Сериализация WordItem → plain object
     */
    private serializeWordItem(wordItem: WordItem): any {
        return {
            id: wordItem.id,
            word: wordItem.word,
            translation: wordItem.translation,
            example: wordItem.example ?? null,
            imageURL: wordItem.imageURL ?? null,
            repetitions: wordItem.repetitions.map(rep => ({
                date: rep.date.toISOString(),
                type: rep.type,
                success: rep.success
            }))
        };
    }

    /**
     * Десериализация WordItem ← plain object
     */
    private deserializeWordItem(data: any): WordItem | null {
        if (typeof data !== 'object' || !data.id || !data.word || !data.translation) {
            return null;
        }
        return {
            id: data.id,
            word: data.word,
            translation: data.translation,
            example: data.example || undefined,
            imageURL: data.imageURL || undefined,
            repetitions: Array.isArray(data.repetitions)
                ? data.repetitions.map((rep: any) => ({
                    date: rep.date ? new Date(rep.date) : new Date(),
                    type: rep.type || 'learn',
                    success: !!rep.success
                }))
                : []
        };
    }

    /**
     * Пример сериализации PairElement.
     */
    private pairElementToDict(pe: PairElement): any {
        return {
            id: pe.id,
            text: pe.text || null,
            contentURL: pe.contentURL || null
        };
    }

    /**
     * Пример десериализации PairElement
     */
    private pairElementFromDict(dict: any): PairElement | null {
        if (!dict || !dict.id) return null;
        return {
            id: dict.id,
            text: dict.text || undefined,
            contentURL: dict.contentURL || undefined
        };
    }

    // =============================
    //   CROSSWORD helpers
    // =============================
    private crosswordToDict(cw: ExerciseCrossword): any {
        // Можно напрямую возвращать поля:
        // grid: cw.grid, placedWords, occupiedPositions, startPositions...
        return {
            rowsCount: cw.grid.length,
            columnsCount: cw.grid.length > 0 ? cw.grid[0].length : 0,
            flattenedGrid: cw.grid.flat(),
            placedWords: cw.placedWords.map(p => ({
                word: this.serializeWordItem(p.word),
                horizontal: p.horizontal,
                startRow: p.startRow,
                startCol: p.startCol,
                wordIndex: p.wordIndex
            })),
            occupiedPositions: cw.occupiedPositions.map(pos => ({
                row: pos.row,
                col: pos.col
            })),
            startPositions: Object.fromEntries(
                Object.entries(cw.startPositions).map(([key, val]) => [key, val])
            ),
            words: cw.words.map(w => this.serializeWordItem(w))
        };
    }

    private crosswordFromDict(data: any): ExerciseCrossword | null {
        const {
            rowsCount, columnsCount, flattenedGrid,
            placedWords, occupiedPositions, startPositions, words
        } = data;

        if (typeof rowsCount !== 'number' || typeof columnsCount !== 'number') {
            return null;
        }
        if (!Array.isArray(flattenedGrid) || !Array.isArray(placedWords) || !Array.isArray(occupiedPositions) || !Array.isArray(words)) {
            return null;
        }

        // Восстанавливаем grid
        const grid: string[][] = [];
        let idx = 0;
        for (let r = 0; r < rowsCount; r++) {
            const row = flattenedGrid.slice(idx, idx + columnsCount);
            idx += columnsCount;
            grid.push(row);
        }

        // placedWords
        const pw: PlacedWord[] = placedWords
            .map((pwObj: any) => {
                const w = this.deserializeWordItem(pwObj.word);
                if (!w) return null;
                return {
                    word: w,
                    horizontal: !!pwObj.horizontal,
                    startRow: pwObj.startRow,
                    startCol: pwObj.startCol,
                    wordIndex: pwObj.wordIndex
                } as PlacedWord;
            })
            .filter((p: PlacedWord | null): p is PlacedWord => p !== null);

        // occupiedPositions
        const occPos = occupiedPositions.map((pos: any) => ({
            row: pos.row,
            col: pos.col
        }));

        // startPositions
        // startPositions - объект key -> number
        const sp = (typeof startPositions === 'object') ? startPositions : {};

        // words
        const wordItems: WordItem[] = words
            .map((w: any) => this.deserializeWordItem(w))
            .filter((wi: WordItem | null) => wi !== null) as WordItem[];

        return {
            words: wordItems,
            grid,
            placedWords: pw,
            occupiedPositions: occPos,
            startPositions: sp,
        };
    }

    // =============================
    //   JustContent helpers
    // =============================
    private serializeContentType(content: any): any {
        // Swift в примере делал switch .text, .audio, ...
        // Предположим, content = { kind: 'text', value: '...' }
        // Или у вас есть enum. Ниже – иллюстрация
        if (!content || !content.kind) {
            return { type: 'unknown', value: null };
        }
        switch (content.kind) {
            case 'text':
                return { type: 'text', value: content.value };
            case 'audio':
                return { type: 'audio', value: content.value };
            case 'video':
                return { type: 'video', value: content.value };
            case 'image':
                return { type: 'image', value: content.value };
            case 'link':
                return { type: 'link', value: content.value };
            default:
                return { type: 'unknown', value: null };
        }
    }

    private deserializeContentType(dict: any): any {
        if (!dict || typeof dict.type !== 'string') return null;
        switch (dict.type) {
            case 'text':
                return { kind: 'text', value: dict.value };
            case 'audio':
                return { kind: 'audio', value: dict.value };
            case 'video':
                return { kind: 'video', value: dict.value };
            case 'image':
                return { kind: 'image', value: dict.value };
            case 'link':
                return { kind: 'link', value: dict.value };
            default:
                return null;
        }
    }
}