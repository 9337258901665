// PracticeTableUseCase.ts


import {ExerciseTable} from "../../models";
import {TableModel, TableModelColumn} from "./Models/TableModel";
import {exerciseTableToModel} from "./Models/Mapping";
import {TableTextElement} from "./Models/TableTextElement";
import {CellCoordinate} from "./Models/CellCoordinate";
import {PracticeTableState} from "./Practice/PracticeTableState";
import {PracticeSessionDelegate} from "../PracticeSessionDelegate";

/**
 * Аналог Swift public protocol LoadTableUseCaseProtocol
 */
export interface PracticeTableUseCaseProtocol {
    // Создаём «полную» модель (где все ячейки – .text(...))
    createTableModel(exTable: ExerciseTable): TableModel;

    // Создаём «учебную» модель (stable => .text(...), остальные => .editor(""))
    createPracticeModel(fullModel: TableModel): TableModel;

    updateUserInput(
        state: PracticeTableState,
        correctModel: TableModel,
        columnIndex: number,
        rowIndex: number,
        newValue: string,
        delegate?: PracticeSessionDelegate
    ): PracticeTableState;

    updatePracticeModel(
        currentModel: TableModel,
        correctModel: TableModel,
        cellTexts: Map<CellCoordinate, string>
    ): TableModel;

    updateCellTexts(
        practiceModel: TableModel,
        existingCellTexts: Map<CellCoordinate, string>
    ): Map<CellCoordinate, string>;
}

/**
 * Аналог Swift public struct LoadTableUseCase
 */
export class PracticeTableUseCase implements PracticeTableUseCaseProtocol {
    createTableModel(exTable: ExerciseTable): TableModel {
        // 1) Преобразуем каждый столбец (ExerciseTable.DBColumn) в TableModelColumn
        const columns: TableModelColumn[] = exTable.columns.map((dbCol) => {
            const headerElem: TableTextElement = {
                kind: "text",
                value: dbCol.header,
            };
            const items: TableTextElement[] = dbCol.items.map((val) => ({
                kind: "text",
                value: val,
            }));

            return { header: headerElem, items };
        });

        // 2) stableCells: Array<{ column, row }> -> Set<CellCoordinate>
        const stableSet = new Set<CellCoordinate>();
        exTable.stableCells.forEach((dict) => {
            const col = dict["column"];
            const row = dict["row"];
            stableSet.add(CellCoordinate.create(col, row));
        });

        // 3) Возвращаем TableModel
        return {
            columns,
            stableCells: stableSet,
        };
    }

    createPracticeModel(fullModel: TableModel): TableModel {
        // «Учебная» модель: для каждой нестабильной ячейки — .editor(""),
        // для стабильной ячейки — остаётся .text(...)
        const practiceCols: TableModelColumn[] = fullModel.columns.map(
            (col, colIndex) => {
                const items = col.items.map((item, rowIndex) => {
                    const coord = CellCoordinate.create(colIndex, rowIndex);
                    if (fullModel.stableCells.has(coord)) {
                        // Стабильная => оставляем item (уже .text)
                        return item;
                    } else {
                        // Нестабильная => .editor("")
                        return { kind: "editor", value: "" } as TableTextElement;
                    }
                });
                return { header: col.header, items };
            }
        );

        return {
            columns: practiceCols,
            stableCells: fullModel.stableCells,
        };
    }

    updateUserInput(
        state: PracticeTableState,
        correctModel: TableModel,
        columnIndex: number,
        rowIndex: number,
        newValue: string,
        delegate?: PracticeSessionDelegate
    ): PracticeTableState {
        if (
            columnIndex >= state.practiceModel.columns.length ||
            rowIndex >= state.practiceModel.columns[columnIndex].items.length
        ) {
            return state;
        }

        const oldModel = state.practiceModel;
        const newColumns = oldModel.columns.map((col) => {
            return {
                header: { ...col.header }, // Копируем header (если нужно)
                items: col.items.map((item) => ({ ...item })) // Создаём новый объект для каждого item
            };
        });

        const oldItem = newColumns[columnIndex].items[rowIndex];
        if (oldItem.kind === "text") {
            return {
                practiceModel: {
                    columns: newColumns,
                    stableCells: oldModel.stableCells // оставляем Set как есть
                }
            };
        }

        const correctItem = correctModel.columns[columnIndex].items[rowIndex];
        if (
            correctItem.kind === "text" &&
            correctItem.value.toLowerCase() === newValue.toLowerCase()
        ) {
            newColumns[columnIndex].items[rowIndex] = {
                kind: "text",
                value: correctItem.value,
            };
        } else {
            newColumns[columnIndex].items[rowIndex] = {
                kind: "editor",
                value: newValue,
            };
        }

        const newPracticeModel: TableModel = {
            columns: newColumns,
            stableCells: oldModel.stableCells // не трогаем, чтобы сохранить Set<CellCoordinate>
        };

        if (this.allCellsFilled(newPracticeModel)) {
            delegate?.currentStepDone();
        }

        return { practiceModel: newPracticeModel };
    }

    allCellsFilled(practiceModel: TableModel): boolean {
        for (const col of practiceModel.columns) {
            for (const item of col.items) {
                if (item.kind === "editor") {
                    return false;
                }
            }
        }
        return true;
    }

    public updatePracticeModel(
        currentModel: TableModel,
        correctModel: TableModel,
        cellTexts: Map<CellCoordinate, string>
    ): TableModel {
        // Копируем currentModel
        const newModel: TableModel = {
            columns: currentModel.columns.map((col) => ({
                header: { ...col.header },
                items: col.items.map((item) => ({ ...item })),
            })),
            stableCells: currentModel.stableCells,
        };

        // Пробегаемся по каждой ячейке
        newModel.columns.forEach((col, colIndex) => {
            col.items.forEach((item, rowIndex) => {
                if (item.kind === "text") {
                    // Уже заполнена => не меняем
                    return;
                }
                // item.kind === 'editor'
                const coord = CellCoordinate.create(colIndex, rowIndex);
                const userValue = cellTexts.get(coord);
                const correctValue = correctModel.columns[colIndex].items[rowIndex].value
                if (userValue !== undefined) {
                    if (correctValue === userValue) {
                        col.items[rowIndex] = { kind: "text", value: userValue };
                    } else {
                        col.items[rowIndex] = { kind: "editor", value: userValue };
                    }
                }
            });
        });

        return newModel;
    }

    public updateCellTexts(
        practiceModel: TableModel,
        existingCellTexts: Map<CellCoordinate, string>
    ): Map<CellCoordinate, string> {
        // Начинаем с копии существующих
        const newCellTexts = new Map(existingCellTexts);

        practiceModel.columns.forEach((col, colIndex) => {
            col.items.forEach((item, rowIndex) => {
                const coord = CellCoordinate.create(colIndex, rowIndex);
                // item.kind === 'text' | 'editor'
                newCellTexts.set(coord, item.value);
            });
        });

        return newCellTexts;
    }
}