// MatchingPairsExerciseViewModel.ts
import { makeAutoObservable } from "mobx";
import {
    MatchingPairsLearnInteractor
} from "../../../../../domain/Exercises/MatchingPairs/Practice/MatchingPairsLearnInteractor";
import {MatchingPairsState} from "../../../../../domain/Exercises/MatchingPairs/Practice/MatchingPairsState";
import {Exercise} from "../../../../../domain/models";
import {PairElement} from "../../../../../domain/Exercises/MatchingPairs/PairElement";
import {PracticeSessionDelegate} from "../../../../../domain/Exercises/PracticeSessionDelegate";
import {MatchingPairsUIHandler} from "../../../../../domain/Exercises/MatchingPairs/Practice/MatchingPairsUIHandler";
import {MatchingPairsUseCase} from "../../../../../domain/Exercises/MatchingPairs/Practice/MatchingPairsUseCase";

export class MatchingPairsExerciseViewModel {
    private interactor: MatchingPairsLearnInteractor;
    private _state: MatchingPairsState;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: MatchingPairsUIHandler,
        useCase?: MatchingPairsUseCase
    ) {
        this.interactor = new MatchingPairsLearnInteractor({exercise, delegate, uiHandler, useCase});
        this._state = this.interactor.state;

        makeAutoObservable(this);

        this.interactor.onStateChange = (newState) => {
            this._state = newState;
        };
    }

    // Аналог @Published var
    get state(): MatchingPairsState {
        return this._state;
    }

    // Удобные геттеры
    get pairsCount() {
        return this._state.pairsCount;
    }
    get leftItems() {
        return this._state.leftItems();
    }
    get rightItems() {
        return this._state.orderedRightItems();
    }

    get matchedPairs() {
        // Собираем реальные объекты left, right
        return this._state.matchedPairs.map((mp) => {
            const all = [...this.leftItems, ...this.rightItems];
            const left = all.find((i) => i.id === mp.leftId);
            const right = all.find((i) => i.id === mp.rightId);
            if (!left || !right) return null;
            return { left, right };
        }).filter(Boolean) as Array<{ left: PairElement; right: PairElement }>;
    }

    get selectedLeft() {
        if (!this._state.selectedLeft) return null;
        return [...this.leftItems, ...this.rightItems].find(
            (e) => e.id === this._state.selectedLeft
        ) || null;
    }

    get selectedRight() {
        if (!this._state.selectedRight) return null;
        return [...this.leftItems, ...this.rightItems].find(
            (e) => e.id === this._state.selectedRight
        ) || null;
    }

    selectLeftItem(item: PairElement) {
        this.interactor.selectLeftItem(item);
    }

    selectRightItem(item: PairElement) {
        this.interactor.selectRightItem(item);
    }

    isMatched(item: PairElement): boolean {
        return this._state.isMatched(item);
    }

    isWrong(item: PairElement): boolean {
        return this._state.isWrong(item);
    }
}