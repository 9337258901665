// PracticeAnagramView.swift -> PracticeAnagramView.tsx
import React from 'react';
import { AnagramLearnViewModel } from './AnagramLearnViewModel';
import { AnagramLearnView } from './AnagramLearnView';

interface PracticeAnagramViewProps {
    vm: AnagramLearnViewModel;
}

export function PracticeAnagramView({ vm }: PracticeAnagramViewProps) {
    return <AnagramLearnView viewModel={vm} />;
}
