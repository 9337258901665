// TableRequester.ts
import {TableModel} from "./Models/TableModel";
import {TableTextElement} from "./Models/TableTextElement";
import {OpenAIRequester} from "../../../data/implementations/OpenAIRequester";
import {CellCoordinate} from "./Models/CellCoordinate";

export interface TableResponseModel {
    headers: string[];
    items: string[][];
}


/**
 * Аналог Swift TableRequesting
 */
export interface TableRequesting {
    createSimilar(tableModel: TableModel): Promise<TableModel>;
    fill(tableModel: TableModel, withRequest: string): Promise<TableModel>;
    checkIsCorrect(tableModel: TableModel): Promise<void>;
}

/**
 * Аналог Swift TableRequester: TableRequesting
 */
export class TableRequester implements TableRequesting {
    private requester: OpenAIRequester;

    constructor(requester: OpenAIRequester) {
        this.requester = requester;
    }

    public async fill(tableModel: TableModel, withRequest: string): Promise<TableModel> {
        const prompt = `
This is a table with headers and one row.
First element in each column is a header.
Some cells are missing. 
Table: ${tableModelToPromptFormat(tableModel)}
Conditions: ${withRequest}
Return the same table, with headers, and with filled cells.
`;

        // Swift:  let responseFormat = TableResponseModel(headers:..., items:...)
        const responseFormat: TableResponseModel = {
            headers: ["header1","header2"],
            items: [
                ["item1","item2"],
                ["item1","item2"]
            ]
        };

        // 1) запрашиваем
        const result = await this.requester.requestWithCustomFormat<TableResponseModel>(
            prompt,
            responseFormat
        );
        if (!result || result.length === 0) {
            throw new Error("No data from OpenAI");
        }

        // 2) берем result[0]
        const [first] = result;
        // 3) строим TableModel
        const newModel = tableResponseModelToTableModel(first);
        return newModel;
    }

    public async createSimilar(tableModel: TableModel): Promise<TableModel> {
//         const tableJson = tableModelToPromptFormat(tableModel);
//         const prompt = `
// This is a table with headers and rows.
// First element in each column is a header.
// Create new table with same headers, but different rows.
// Table: ${tableJson}
//     `;
//
//         const responseFormat: TableResponseModel = {
//             items: [
//                 { headers: "headerExample", items: "itemExample" }
//             ],
//         };
//
//         const result = await this.requester.requestWithCustomFormat<TableResponseModel>(prompt, responseFormat);
//         if (!result || result.length === 0) {
//             throw new Error("No data from OpenAI for createSimilar(...)");
//         }
//         const [first] = result;
//         return tableResponseModelToTableModel(first);
        return tableModel
    }

    public async checkIsCorrect(_tableModel: TableModel): Promise<void> {
        // заглушка
    }
}
/**
 * Пример вспомогательной функции,
 * аналог .toPromptFormat в Swift.
 * Возвращает JSON-строку вида {headers:[], items:[[]]}
 */
export function tableModelToPromptFormat(tableModel: TableModel): string {
    const headers = tableModel.columns.map((col) => col.header.value || "MISSING");
    const maxRows = Math.max(...tableModel.columns.map((c) => c.items.length), 0);

    const items: string[][] = [];
    for (let r = 0; r < maxRows; r++) {
        const rowVals: string[] = [];
        for (let c = 0; c < tableModel.columns.length; c++) {
            const itemVal = tableModel.columns[c].items[r]?.value || "MISSING";
            rowVals.push(itemVal);
        }
        items.push(rowVals);
    }

    const obj = { headers, items };
    return JSON.stringify(obj, null, 2);
}

export function tableResponseModelToTableModel(resp: TableResponseModel): TableModel {
    // 1) Для colIndex in 0 ..< headers.count
    //    создаём column header = resp.headers[colIndex]
    // 2) Пробегаемся по resp.items (каждая – массив строк)
    //    если colIndex < rowArray.length => .editor(rowArray[colIndex]) else .editor("")
    const columns: TableModel["columns"] = [];

    for (let colIndex = 0; colIndex < resp.headers.length; colIndex++) {
        const headerElem: TableTextElement = {
            kind: "editor",
            value: resp.headers[colIndex] || "MISSING",
        };

        const columnItems: TableTextElement[] = [];
        // resp.items = двумерный массив
        for (let r = 0; r < resp.items.length; r++) {
            const rowArray = resp.items[r];
            let cellValue = "";
            if (colIndex < rowArray.length) {
                cellValue = rowArray[colIndex];
            } else {
                cellValue = "";
            }
            columnItems.push({
                kind: "editor",
                value: cellValue === "" ? "MISSING" : cellValue,
            });
        }

        columns.push({ header: headerElem, items: columnItems });
    }

    // stableCells = пустое
    return {
        columns,
        stableCells: new Set(),
    };
}