// JsonSchemaGenerator.ts

export class JsonSchemaGenerator {
    /**
     * Генерирует схему, в которой top-level — объект с полем "items",
     * представляющим массив с элементами типа `example`.
     */
    public generateArrayOf<T>(example: T): any {
        const singleItemSchema = this.generateObject(example);
        return {
            type: "object",
            properties: {
                items: {
                    type: "array",
                    items: singleItemSchema,
                },
            },
            required: ["items"],
            additionalProperties: false,
        };
    }

    /**
     * Генерирует схему, описывающую объект вида `example`.
     * - Для каждого поля вычисляется схема (строка, число, булево, массив, объект).
     * - Поля считаются "required", даже если в runtime оказались `null`/`undefined`.
     * - Если поле в runtime = `null` или `undefined`, оно считается "optional":
     *   для простых типов используется "type": [<type>, "null"],
     *   для сложных — anyOf с "null".
     */
    public generateObject<T>(example: T): any {
        const properties: Record<string, any> = {};
        const required: string[] = [];

        // Обходим все ключи (enumerable) в объекте:
        for (const key in example) {
            if (Object.prototype.hasOwnProperty.call(example, key)) {
                const value = (example as any)[key];
                const isOptional = value === null || value === undefined;

                // Сгенерировать схему для текущего значения (рекурсивно)
                const childSchema = this.buildSchema(value);

                if (isOptional) {
                    // Если значение null | undefined, "опциональное" поле
                    // Для простых типов: { type: [<type>, "null"] }
                    if (typeof childSchema.type === "string") {
                        properties[key] = {
                            type: [childSchema.type, "null"],
                        };
                    } else {
                        // Если это сложный тип (object/array), применяем anyOf
                        properties[key] = {
                            anyOf: [childSchema, { type: "null" }],
                        };
                    }
                } else {
                    // Обычное (не-опциональное) поле
                    properties[key] = childSchema;
                }

                // Включаем в список required (по аналогии с Swift-генератором)
                required.push(key);
            }
        }

        return {
            type: "object",
            properties,
            required,
            additionalProperties: false,
        };
    }

    /**
     * Определяет схему поля по его runtime-значению.
     * - string → { type: "string" }
     * - number → { type: "number" }
     * - boolean → { type: "boolean" }
     * - массив  → { type: "array", items: ... } (рекурсивно для первого элемента)
     * - объект  → { type: "object", ... } (рекурсивно по ключам)
     * - fallback → { type: "string" }
     */
    private buildSchema(value: any): any {
        // Примитивные типы
        if (typeof value === "string") {
            return { type: "string" };
        } else if (typeof value === "number") {
            return { type: "number" };
        } else if (typeof value === "boolean") {
            return { type: "boolean" };
        }

        // Массив
        if (Array.isArray(value)) {
            if (value.length > 0) {
                // Смотрим на первый элемент, чтобы вывести схему
                const itemSchema = this.buildSchema(value[0]);
                return {
                    type: "array",
                    items: itemSchema,
                };
            } else {
                // Пустой массив - fallback
                return {
                    type: "array",
                    items: { type: "string" },
                };
            }
        }

        // Объект (не null) - рекурсивно строим схему как "object"
        if (value && typeof value === "object") {
            // Здесь можно либо вызвать generateObject(value),
            // либо собрать аналогичный объект вручную.
            return this.generateObject(value);
        }

        // Если значение вообще ничто (null/undefined), мы сюда обычно не дойдём,
        // т.к. это обрабатывается в generateObject как "isOptional".
        // Но на всякий случай вернём fallback:
        return { type: "string" };
    }
}