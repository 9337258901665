import React from "react";

/**
 * Пример кнопки с тёмным стилем (если нужно).
 */
export function GrayButton(props: { text: string; onClick: () => void }) {
    return (
        <button
            style={{
                backgroundColor: "#444",
                color: "#fff",
                border: "1px solid #888",
                borderRadius: 4,
                padding: "8px 16px",
                marginTop: 4,
                cursor: "pointer",
            }}
            onClick={props.onClick}
        >
            {props.text}
        </button>
    );
}