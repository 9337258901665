// MatchingPairsTypes.ts

import {MatchedPair} from "./MatchedPair";

/** Аналог Swift MatchingPairsUIState */
export interface MatchingPairsUIState {
    matchedPairs: MatchedPair[];
    selectedLeft?: string;
    selectedRight?: string;
    lastWrongAttempt?: MatchedPair;
}

export function createDefaultMatchingPairsUIState(): MatchingPairsUIState {
    return {
        matchedPairs: [],
        selectedLeft: undefined,
        selectedRight: undefined,
        lastWrongAttempt: undefined,
    };
}