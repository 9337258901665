// ========== BaseComponents/BaseIcon.tsx ==========
// Для иконок systemName: "trash" можно просто использовать emoji или текст.
// В SwiftUI Image(systemName:) - иконка из SF Symbols.
// В вебе просто покажем символ или обычный текст. Можно ввести BaseIcon с name пропсом.
import React from 'react';

interface BaseIconProps {
    name: string;
    color?: string;
}

export function BaseIcon({ name, color }: BaseIconProps) {
    // Примитивная замена: если name === "trash" -> 🗑️
    let symbol = name;
    if (name === "trash") symbol = "🗑️";
    if (name === "plus.circle") symbol = "➕";

    return <span style={{ color }}>{symbol}</span>;
}
