// PracticeTableUIState.ts

import {CellCoordinate} from "../Models/CellCoordinate";
import {encodeCellTexts, decodeCellTexts, FirestoreCellTextsArray} from "../Models/FirestoreCellTextsArray";

export class PracticeTableUIState {
    public cellTexts: Map<CellCoordinate, string>;

    constructor(cellTexts?: Map<CellCoordinate, string>) {
        this.cellTexts = cellTexts ?? new Map<CellCoordinate, string>();
    }

    /** Сериализуем в формат FirestoreCellTextsArray */
    public toFirestore(): FirestoreCellTextsArray {
        return encodeCellTexts(this.cellTexts);
    }

    /** Десериализуем из массива FirestoreCellTextsArray */
    public static fromFirestore(arr: FirestoreCellTextsArray): PracticeTableUIState {
        const map = decodeCellTexts(arr);
        return new PracticeTableUIState(map);
    }

    public equals(other: PracticeTableUIState): boolean {
        // Грубый вариант:
        // @ts-ignore
        return JSON.stringify([...this.cellTexts]) === JSON.stringify([...other.cellTexts]);
    }
}

