// LessonPractice.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { LessonPracticeViewModel, PracticeStateUI } from './LessonPracticeViewModel';

// Старые (реальные) компоненты
import { PracticeSelectOptionView } from './PracticeSelectOptionView';
import { PracticeMissingWordView } from './PracticeMissingWordView';

// Новые (заглушки или реальные) компоненты
import { PracticeAnagramView } from "../../exercises_types/Anagram/Practice/PracticeAnagramView";
import { MatchingPairsExerciseView } from "../../exercises_types/MatchingPairs/Practice/MatchingPairsExerciseView";
import { FillBlanksExerciseView, JustContentPracticeView, PracticeCrosswordView, PracticeWordSearchView } from "./TempViews";
import { PracticeTableView } from "../../exercises_types/Table/Practice/PracticeTableView";

import {
    MatchingPairsExerciseViewModel
} from "../../exercises_types/MatchingPairs/Practice/MatchingPairsExerciseViewModel";
import {BaseText} from "../../exercises_types/BaseComponents/BaseText";
import {PracticeHangmanView} from "../../exercises_types/Hangman/Practice/PracticeHangmanView";
import {NotesWindowView} from "../../Notes/NotesWindow";
import CompositionRoot from "../../../../compositionRoot";
import {NotesWindowViewModel} from "../../Notes/NotesWindowViewModel";
import {NotesInteractor} from "../../Notes/NotesInteractor"; // ваш вспом. метод, если нужен

const LessonPractice: React.FC = observer(() => {
    const { id } = useParams<{ id: string }>();

    // Создаём один экземпляр LessonPracticeViewModel
    const [vm] = React.useState(() => new LessonPracticeViewModel(id!));

    const [interactor] = React.useState(() => {
        const noteId = `lesson-${id}`; // Пример генерации noteId на основе lesson id
        const initialState = { version: 0, items: [] }; // Или загрузите начальное состояние из репозитория

        return new NotesInteractor(
            noteId,
            initialState,
            CompositionRoot.noteRepository, // Предполагается, что serviceLocator предоставляет необходимые зависимости
            CompositionRoot.mediaUploading,
            CompositionRoot.imageUploading
        );
    });

    // Инициализируем NotesWindowViewModel с использованием interactor
    const [notesVM] = React.useState(() => new NotesWindowViewModel(interactor));


    // Извлекаем текущее состояние
    const practiceState = vm.practiceState;

    // Можно отобразить прогресс
    const total = vm.totalExercises;
    const currentIndex = vm.currentExerciseIndex;

    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            {/* Левая часть: упражнения */}
            <div style={{ width: '70%', padding: '20px', overflowY: 'auto' }}>
                <BaseText font="largeTitle" paddingBottom={30}>
                    Lesson {vm.lesson?.name} — Exercise {currentIndex + 1} / {total}
                </BaseText>

                {practiceState.type === 'selectOption' && (
                    <PracticeSelectOptionView
                        id={practiceState.id}
                        exercise={practiceState.exercise}
                        saveAttempt={(ex, ans) => vm.saveAttempt(ex, ans)}
                        goNext={() => vm.currentStepDone()}
                    />
                )}

                {practiceState.type === 'missingWord' && (
                    <PracticeMissingWordView
                        id={practiceState.id}
                        exercise={practiceState.exercise}
                        saveAttempt={(ex, ans) => vm.saveAttempt(ex, ans)}
                        goNext={() => vm.currentStepDone()}
                    />
                )}

                {practiceState.type === 'anagram' && (
                    <PracticeAnagramView
                        vm={practiceState.vm}
                    />
                )}

                {practiceState.type === 'matchingPairs' && (
                    <MatchingPairsExerciseView viewModel={practiceState.vm} />
                )}

                {practiceState.type === 'wordSearch' && (
                    <PracticeWordSearchView vm={{}} />
                )}

                {practiceState.type === 'crossword' && (
                    <PracticeCrosswordView vm={{}} />
                )}

                {practiceState.type === 'fillBlanks' && (
                    <FillBlanksExerciseView viewModel={{}} />
                )}

                {practiceState.type === 'justContent' && (
                    <JustContentPracticeView viewModel={{}} />
                )}

                {practiceState.type === 'table' && (
                    <PracticeTableView viewModel={practiceState.vm} />
                )}

                {practiceState.type === 'hangman' && (
                    <PracticeHangmanView viewModel={practiceState.vm} />
                )}

                {practiceState.type === 'done' && (
                    <p>Practice Done</p>
                )}
            </div>

            {/* Правая часть: NotesWindow */}
            <div style={{ width: '30%', borderLeft: '1px solid #ccc', padding: '20px', overflowY: 'auto' }}>
                <NotesWindowView viewModel={notesVM} />
            </div>
        </div>
    );
});

export default LessonPractice;