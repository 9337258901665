// PracticeTableUIHandler.ts
import {makeAutoObservable, reaction, runInAction} from "mobx";
import { PracticeTableUIState } from "./PracticeTableUIState";
import {ExerciseUIHandler} from "../../../Multiplayer/ExerciseUIHandler";
import {CellCoordinate} from "../Models/CellCoordinate";
import {SessionStateInteractor} from "../../../Multiplayer/SessionStateInteractor";
import {ExerciseUIState} from "../../../Multiplayer/ExerciseUIState";
import {encodeCellTexts} from "../Models/FirestoreCellTextsArray";

export class PracticeTableUIHandler implements ExerciseUIHandler {
    public cellTexts: Map<CellCoordinate, string>;

    private exerciseId: string;
    private interactor?: SessionStateInteractor;
    private isUpdatingFromServer = false;

    private currentUIState: PracticeTableUIState;

    constructor(
        exerciseId: string,
        interactor?: SessionStateInteractor,
        initialState?: PracticeTableUIState
    ) {
        this.exerciseId = exerciseId;
        this.interactor = interactor;

        this.currentUIState = initialState ?? new PracticeTableUIState();
        this.cellTexts = new Map(this.currentUIState.cellTexts);

        makeAutoObservable(this);

        reaction(
            () => Array.from(this.cellTexts.entries()),
            () => {
                if (!this.isUpdatingFromServer) {
                    this.sendUpdateIfNeeded();
                }
            }
        );
    }

    public updateUIState(uiState: ExerciseUIState | undefined): void {
        if (!uiState || uiState.type !== "table") {
            return;
        }
        const newState = PracticeTableUIState.fromFirestore(encodeCellTexts(uiState.data.cellTexts));

        if (!newState.equals(this.currentUIState)) {
            this.isUpdatingFromServer = true;
            runInAction(() => {
                this.currentUIState = newState;
                this.cellTexts = new Map(newState.cellTexts);
            });
            this.isUpdatingFromServer = false;
        }
    }

    private sendUpdateIfNeeded() {
        if (this.isUpdatingFromServer) return;
        const newState = new PracticeTableUIState(new Map(this.cellTexts));
        if (newState.equals(this.currentUIState)) {
            return;
        }
        this.currentUIState = newState;

        this.interactor?.updateTableState(this.exerciseId, newState);
    }
}