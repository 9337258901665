// ========== MatchingPairsCreateViewModel.tsx ==========

import {Exercise} from "../../../../../domain/models";
import {
    MatchingPairsCreateUseCaseImpl
} from "../../../../../domain/Exercises/MatchingPairs/Creation/MatchingPairsCreateUseCaseImpl";
import {PairElementInput} from "../../../../../domain/Exercises/MatchingPairs/Creation/PairElementInput";
import {
    EnhancedMatchingPairInput
} from "../../../../../domain/Exercises/MatchingPairs/Creation/EnhancedMatchingPairInput";
import {EnhancedMatchingPairItem} from "./PairElementUI";
import {makeObservable, observable, action, makeAutoObservable} from 'mobx';

export class MatchingPairsCreateViewModel {
    pairs: EnhancedMatchingPairItem[] = [];
    private useCase: MatchingPairsCreateUseCaseImpl;
    onExercisesCreated: (exercises: Exercise[]) => void;

    constructor(onExercisesCreated: (exercises: Exercise[]) => void) {
        makeAutoObservable(this);
        this.onExercisesCreated = onExercisesCreated;
        this.useCase = new MatchingPairsCreateUseCaseImpl();
    }

    async createExercise() {
        try {
            await this.useCase.loadData();
            const inputs: EnhancedMatchingPairInput[] = this.pairs.map(pair => ({
                left: { text: pair.left.text, fileURL: pair.left.fileURL },
                right: { text: pair.right.text, fileURL: pair.right.fileURL }
            }));
            const exercises = await this.useCase.createExercises(inputs);
            this.onExercisesCreated(exercises);
        } catch (error) {
            console.error("Error creating exercise:", error);
        }
    }

    isAllPairsValid(): boolean {
        const inputs: EnhancedMatchingPairInput[] = this.pairs.map(pair => ({
            left: { text: pair.left.text, fileURL: pair.left.fileURL },
            right: { text: pair.right.text, fileURL: pair.right.fileURL }
        }));
        return this.useCase.isAllPairsValid(inputs);
    }

    handleImportedFile(result: URL | Error, isLeft: boolean, pairId: string) {
        const idx = this.pairs.findIndex(p => p.id === pairId);
        if (idx < 0) return;

        if (result instanceof Error) {
            console.error("Error importing file:", result);
            return;
        }

        const tempURL = this.useCase.copyFileToTemp(result);
        const newPairs = [...this.pairs];
        const pair = newPairs[idx];
        if (isLeft) {
            pair.left = { ...pair.left, fileURL: tempURL };
        } else {
            pair.right = { ...pair.right, fileURL: tempURL };
        }
        this.pairs = newPairs;
    }

    addPair() {
        const newPair: EnhancedMatchingPairItem = {
            id: crypto.randomUUID().toUpperCase(),
            left: { id: crypto.randomUUID().toUpperCase(), text: "" },
            right: { id: crypto.randomUUID().toUpperCase(), text: "" }
        };
        this.pairs = [...this.pairs, newPair];
    }

    removePair(pairId: string) {
        this.pairs = this.pairs.filter(p => p.id !== pairId);
    }

    updatePairLeftText(pairId: string, newText: string) {
        const idx = this.pairs.findIndex(p => p.id === pairId);
        if (idx < 0) return;
        const newPairs = [...this.pairs];
        newPairs[idx].left = { ...newPairs[idx].left, text: newText };
        this.pairs = newPairs;
    }

    updatePairRightText(pairId: string, newText: string) {
        const idx = this.pairs.findIndex(p => p.id === pairId);
        if (idx < 0) return;
        const newPairs = [...this.pairs];
        newPairs[idx].right = { ...newPairs[idx].right, text: newText };
        this.pairs = newPairs;
    }
}
