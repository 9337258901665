import React from "react";
import { HangmanLayoutConfig } from "./HangmanLayoutConfig";
import { HangmanStage } from "./HangmanStage";
import GallowsView from "./GallowsView";
import RopeView from "./RopeView";
import HeadWithEyesView from "./HeadWithEyesView";
import BodyPartView from "./BodyPartView";

interface HangmanViewProps {
    stage: HangmanStage;
    config: HangmanLayoutConfig;
    width?: number;
    height?: number;
}

const HangmanView: React.FC<HangmanViewProps> = ({
                                                     stage,
                                                     config,
                                                     width = 300,
                                                     height = 300,
                                                 }) => {
    // Вычисляем все координаты:
    const crossbarY = height * config.crossbarYPercent;
    const crossbarXEnd = width * config.crossbarXEndPercent;

    const ropeLength = height * config.ropeLengthPercent;
    const headDiameter = width * config.headSizePercent;
    const headRadius = headDiameter / 2;

    // Верх головы = конец верёвки
    const headTopY = crossbarY + ropeLength;
    // Центр головы
    const headCenterY = headTopY + headRadius;

    // Туловище
    const bodyHeight = height * config.bodyHeightPercent;
    const bodyTopY = headCenterY + headRadius;
    const bodyCenterY = bodyTopY + bodyHeight / 2;

    // Руки
    const armHeight = height * config.armHeightPercent;
    const armCenterY = bodyTopY + armHeight / 2;

    // Ноги
    const legsTopY = bodyTopY + bodyHeight;
    const legHeight = height * config.legHeightPercent;
    const legCenterY = legsTopY + legHeight / 2;

    return (
        <svg width={width} height={height} style={{ border: "1px solid transparent" }}>
            {/* Виселица */}
            <GallowsView width={width} height={height} config={config} />

            {/* Верёвка */}
            <RopeView x={crossbarXEnd} topY={crossbarY} length={ropeLength} lineWidth={config.bodyLineWidth} />

            {/* Голова */}
            {stage >= HangmanStage.stage1 && (
                <HeadWithEyesView
                    cx={crossbarXEnd}
                    cy={headCenterY}
                    diameter={headDiameter}
                    config={config}
                />
            )}

            {/* Туловище */}
            {stage >= HangmanStage.stage2 && (
                <BodyPartView
                    x={crossbarXEnd}
                    y={bodyTopY}
                    height={bodyHeight}
                    lineWidth={config.bodyLineWidth}
                />
            )}

            {/* Левая рука */}
            {stage >= HangmanStage.stage3 && (
                <BodyPartView
                    x={crossbarXEnd}
                    y={bodyTopY}
                    height={armHeight}
                    lineWidth={config.bodyLineWidth}
                    rotation={-45}
                />
            )}

            {/* Правая рука */}
            {stage >= HangmanStage.stage4 && (
                <BodyPartView
                    x={crossbarXEnd}
                    y={bodyTopY}
                    height={armHeight}
                    lineWidth={config.bodyLineWidth}
                    rotation={45}
                />
            )}

            {/* Левая нога */}
            {stage >= HangmanStage.stage5 && (
                <BodyPartView
                    x={crossbarXEnd}
                    y={legsTopY}
                    height={legHeight}
                    lineWidth={config.bodyLineWidth}
                    rotation={-25}
                />
            )}

            {/* Правая нога */}
            {stage >= HangmanStage.stage6 && (
                <BodyPartView
                    x={crossbarXEnd}
                    y={legsTopY}
                    height={legHeight}
                    lineWidth={config.bodyLineWidth}
                    rotation={25}
                />
            )}
        </svg>
    );
};

export default HangmanView;