// Изображение из Data:
////////////////////////////////////
// ImageFromData.tsx (вспомогательный)
////////////////////////////////////
import React from 'react';
interface ImageFromDataProps {
    data: Uint8Array;                  // Данные изображения
    style?: React.CSSProperties;       // Дополнительные стили
}

export function ImageFromData({ data, style }: ImageFromDataProps) {
    const blob = new Blob([data], { type: 'image/png' });
    const url = URL.createObjectURL(blob);
    return <img src={url} alt="Loaded" style={{ maxWidth: '100%', maxHeight: '100%', ...style }} />;
}