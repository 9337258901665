import React from "react";

interface SpanishKeyboardViewProps {
    onLetterTap: (letter: string) => void;
    disabled?: boolean;
}

const SpanishKeyboardView: React.FC<SpanishKeyboardViewProps> = ({
                                                                     onLetterTap,
                                                                     disabled = false,
                                                                 }) => {
    const row1 = Array.from("QWERTYUIOP");
    const row2 = Array.from("ASDFGHJKLÑ");
    const row3 = Array.from("ZXCVBNM");

    const renderRow = (letters: string[]) => {
        return (
            <div style={{ display: "flex", gap: 4 }}>
                {letters.map((letter) => (
                    <button
                        key={letter}
                        onClick={() => onLetterTap(letter)}
                        disabled={disabled}
                        style={{
                            width: 32,
                            height: 32,
                            background: "blue",
                            color: "white",
                            border: "none",
                            borderRadius: 4,
                            cursor: disabled ? "not-allowed" : "pointer",
                        }}
                    >
                        {letter}
                    </button>
                ))}
            </div>
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            {renderRow(row1)}
            {renderRow(row2)}
            {renderRow(row3)}
        </div>
    );
};

export default SpanishKeyboardView;