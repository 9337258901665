import React, { useState } from 'react';

type ShareButtonProps = {
    link: string;
};

const ShareButton: React.FC<ShareButtonProps> = ({ link }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleShare = async () => {
        try {
            await navigator.clipboard.writeText(link);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 3000);
        } catch (error) {
            console.error('Failed to copy link', error);
        }
    };

    return (
        <button style={styles.shareButton} onClick={handleShare}>
            {isCopied ? 'Link Copied' : 'Share'}
        </button>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    shareButton: {
        padding: '8px 16px',
        fontSize: '16px',
        cursor: 'pointer',
        backgroundColor: '#444',
        color: '#fff',
        border: '1px solid #666',
        borderRadius: '4px',
    },
};

export default ShareButton;