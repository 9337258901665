// src/domain/services/LessonPracticeSession.ts

import { Lesson } from '../models/Lesson';
import { PracticeSessionResults } from '../models/PracticeSessionResults';
import { PracticeSessionStorage } from '../interfaces/PracticeSessionStorage';
import { AppUserManagerProtocol } from '../interfaces/AppUserManagerProtocol';
import { Exercise } from '../models/Exercise';

export class LessonPracticeSession {
    private set: Lesson;
    currentIndex: number = 0;
    private results: PracticeSessionResults;
    private storage: PracticeSessionStorage;
    private userManager: AppUserManagerProtocol;

    constructor(set: Lesson, userManager: AppUserManagerProtocol, storage: PracticeSessionStorage) {
        this.set = set;
        this.userManager = userManager;
        this.storage = storage;
        this.results = {
            id: this.generateUUID(),
            startTime: new Date(),
            setId: set.id,
            answers: {},
        };
    }

    public goNext() {
        this.currentIndex += 1;
    }

    public saveAttempt(exercise: Exercise, answer: string) {
        const userId = this.userManager.currentUser?.uid;
        if (!userId) {
            return;
        }
        const exerciseId = exercise.id;
        this.results.answers[exerciseId] = answer;
        this.storage.saveResults(this.results, userId).catch(console.error);
    }

    public getExercise(): Exercise | null {
        if (this.currentIndex >= this.set.exercises.length) {
            return null;
        }
        return this.set.exercises[this.currentIndex];
    }

    private generateUUID(): string {
        // Генерация UUID без сторонних библиотек
        return crypto.randomUUID().toUpperCase();
    }
}
