// utils/elementContent.ts

import {PairElement} from "../../../../../domain/Exercises/MatchingPairs/PairElement";
import {ContentData, ContentType} from "../../../../../domain/models";

export function elementContent(item: PairElement): ContentData {
    const text = item.text?.trim();

    let content: ContentType | undefined;
    if (item.contentURL) {
        // Определяем расширение, чтобы понять, к какому типу относится (audio, video, image, link...)
        try {
            const url = new URL(item.contentURL);
            const ext = url.pathname.split('.').pop()?.toLowerCase() || '';

            if (['mp3', 'wav', 'm4a', 'mpeg'].includes(ext)) {
                content = { kind: 'audio', url };
            } else if (['mp4', 'mov', 'm4v'].includes(ext)) {
                content = { kind: 'video', url };
            } else if (['jpg', 'jpeg', 'png', 'gif'].includes(ext)) {
                content = { kind: 'image', url };
            } else if (text && !ext) {
                // теоретически, если нет расширения, можем считать это .link
                content = { kind: 'link', url };
            } else {
                // Либо просто link, если не знаем, что это
                content = { kind: 'link', url };
            }
        } catch {
            // Если URL недействительный, пропускаем
        }
    }

    return { text, content };
}