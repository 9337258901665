// SessionStateInteractor.ts

import { ExerciseUIHandler } from "./ExerciseUIHandler";
import {SessionState} from "./SessionState";
import {SessionRepository} from "./SessionRepository";
import {PracticeTableUIState} from "../Exercises/Table/Practice/PracticeTableUIState";
import {PracticeTableUIHandler} from "../Exercises/Table/Practice/PracticeTableUIHandler";
import {
    PracticeHangmanUIState,
    PracticeHangmanUIStateUtils
} from "../Exercises/Hangman/Practice/PracticeHangmanUIState";
import {PracticeHangmanUIHandler} from "../Exercises/Hangman/Practice/PracticeHangmanUIHandler";
import {MatchingPairsUIHandler} from "../Exercises/MatchingPairs/Practice/MatchingPairsUIHandler";
import {ExerciseUIState} from "./ExerciseUIState";

export class SessionStateInteractor {
    public onIndexChange?: (newIndex: number) => void;

    private sessionId: string;
    private repository: SessionRepository;
    private currentState?: SessionState;
    private uiHandlers: Map<string, ExerciseUIHandler>;

    constructor(sessionId: string, repository: SessionRepository) {
        this.sessionId = sessionId;
        this.repository = repository;
        this.uiHandlers = new Map();

        // Аналог subscribeToSession(sessionId: onUpdate:)
        this.repository.subscribeToSession(sessionId, (newState) => {
            this.handleSessionUpdate(newState);
        });
    }

    // ---- 1) Обработка входящих данных из репозитория (onSnapshot) ----
    private handleSessionUpdate(state: SessionState | undefined): void {
        if (!state) {
            // Документ не существует -> currentState = undefined
            this.currentState = undefined;
            return;
        }

        if (this.currentState) {
            // Сравним версии (аналог if newState.version <= current.version)
            if (state.version <= this.currentState.version) {
                // Получили «не более новую» версию, игнорируем
                return;
            } else {
                // Новая версия state
                this.currentState = state;
            }
        } else {
            // У нас не было текущего state
            this.currentState = state;
        }

        // Обновляем UIHandlers
        for (const exId of state.exercises) {
            const uiState = state.uiStateByExerciseId[exId];
            const handler = this.uiHandlers.get(exId);
            handler?.updateUIState(uiState);
        }

        // Вызываем onIndexChange, если есть
        this.onIndexChange?.(state.currentExerciseIndex);
    }

    // ---- 2) currentExerciseIndex ----
    public get currentExerciseIndex(): number | undefined {
        return this.currentState?.currentExerciseIndex;
    }

    // ---- 3) ensureInitialState / setInitialStateIfNeeded ----
    public async ensureInitialState(exercises: any[]): Promise<void> {
        try {
            const existingState = await this.repository.getSessionState(this.sessionId);
            // Если состояние уже есть, ничего не делаем
            if (existingState) {
                return;
            }
        } catch (err) {
            console.error("Failed to check existing session state:", err);
            return;
        }
        // Если нет, создаём
        await this.setInitialStateIfNeeded(exercises);
    }

    public async setInitialStateIfNeeded(exercises: any[]): Promise<void> {
        if (this.currentState) return; // уже есть локальное состояние

        const initialState: SessionState = {
            exercises: exercises.map((ex) => ex.id), // Предположим ex.id — string
            currentExerciseIndex: 0,
            uiStateByExerciseId: {},
            version: 0
        };

        try {
            await this.repository.updateSessionState(this.sessionId, (draft) => {
                // Полностью перезаписываем
                Object.assign(draft, initialState);
            });
        } catch (err) {
            console.error("Failed to initialize session state:", err);
        }
    }

    // ---- 4) updateCurrentExerciseIndex ----
    public updateCurrentExerciseIndex(newIndex: number): void {
        // Обновляем локально
        if (this.currentState) {
            this.currentState.currentExerciseIndex = newIndex;
            this.currentState.version++;
        }
        // Асинхронно обновляем в репо
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    draft.currentExerciseIndex = newIndex;
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update currentExerciseIndex:", err);
            }
        })();
    }

    // ---- 5) tableHandler + updateTableState ----
    // Аналог Swift extension для table.

    public tableHandler(exerciseId: string): PracticeTableUIHandler {
        // Пробуем найти уже созданный handler
        let handler = this.uiHandlers.get(exerciseId) as PracticeTableUIHandler;
        if (handler) {
            return handler;
        } else {
            // Создаём новый
            handler = new PracticeTableUIHandler(exerciseId, this);
            // Если в currentState есть uiStateByExerciseId для этого exerciseId
            const uiState = this.currentState?.uiStateByExerciseId[exerciseId];
            if (uiState && uiState.type === "table") {
                // Передаём handler.updateUIState(...)
                handler.updateUIState(uiState);
            }
            this.uiHandlers.set(exerciseId, handler);
            return handler;
        }
    }

    public updateTableState(exerciseId: string, newState: PracticeTableUIState): void {
        // 1) Обновляем локально
        if (this.currentState) {
            this.currentState.uiStateByExerciseId[exerciseId] = {
                type: "table",
                data: newState
            };
            this.currentState.version++;
        }
        // 2) Асинхронно - репозиторий
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    if (!draft.uiStateByExerciseId) {
                        draft.uiStateByExerciseId = {};
                    }
                    draft.uiStateByExerciseId[exerciseId] = {
                        type: "table",
                        data: newState
                    };
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update table state:", err);
            }
        })();
    }

    public hangmanHandler(exerciseId: string): PracticeHangmanUIHandler {
        // Пробуем найти уже созданный handler
        let handler = this.uiHandlers.get(exerciseId) as PracticeHangmanUIHandler;
        if (handler) {
            return handler;
        } else {
            // Создаём новый
            handler = new PracticeHangmanUIHandler(exerciseId, this);
            // Если в currentState есть uiStateByExerciseId для этого exerciseId
            const uiState = this.currentState?.uiStateByExerciseId[exerciseId];
            if (uiState && uiState.type === "hangman") {
                handler.updateUIState(uiState);
            }
            this.uiHandlers.set(exerciseId, handler);
            return handler;
        }
    }

    /**
     * Вызывается из PracticeHangmanUIHandler, когда нужно сохранить
     * обновлённое состояние (guessedLetters, mistakes и т.д.) в SessionState.
     */
    public updateHangmanState(exerciseId: string, newState: PracticeHangmanUIState): void {
        console.log("SessionStateInteractor updateHangmanState")

        if (this.currentState) {
            this.currentState.uiStateByExerciseId[exerciseId] = {
                type: "hangman",
                data: PracticeHangmanUIStateUtils.toFirestore(newState)
            };
            this.currentState.version++;
        }
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    if (!draft.uiStateByExerciseId) {
                        draft.uiStateByExerciseId = {};
                    }
                    draft.uiStateByExerciseId[exerciseId] = {
                        type: "hangman",
                        data: PracticeHangmanUIStateUtils.toFirestore(newState)
                    };
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update hangman state:", err);
            }
        })();
    }




    public matchingPairsHandler(exerciseId: string): MatchingPairsUIHandler {
        let handler = this.uiHandlers.get(exerciseId) as MatchingPairsUIHandler;
        if (!handler) {
            // Создаём новый
            handler = new MatchingPairsUIHandler(exerciseId, this);
            // Если есть currentState, пытаемся подгрузить UIState
            const uiState = this.currentState?.uiStateByExerciseId[exerciseId];
            if (uiState && uiState.type === "matchingPairs") {
                handler.updateUIState(uiState);
            }
            this.uiHandlers.set(exerciseId, handler);
        }
        return handler;
    }

    /** Вызывается из UIHandler, чтобы сохранить новое состояние (matchingPairs(...)) */
    public updateExerciseState(exerciseId: string, newState: ExerciseUIState): void {
        if (!this.currentState) return;
        this.currentState.uiStateByExerciseId[exerciseId] = newState;
        this.currentState.version++;

        // Асинхронное сохранение в репозиторий
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    if (!draft.uiStateByExerciseId) draft.uiStateByExerciseId = {};
                    draft.uiStateByExerciseId[exerciseId] = newState;
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update matchingPairs state:", err);
            }
        })();
    }
}