import React from "react";
import { HangmanLayoutConfig } from "./HangmanLayoutConfig";

interface GallowsViewProps {
    width: number;
    height: number;
    config: HangmanLayoutConfig;
}

const GallowsView: React.FC<GallowsViewProps> = ({ width, height, config }) => {
    const crossbarY = height * config.crossbarYPercent;
    const poleX = width * config.verticalPoleXPercent;
    const groundEndX = width * config.groundMaxWidthPercent;
    const crossbarXEnd = width * config.crossbarXEndPercent;

    // Преобразуем линии в path
    // Земля: (0, height) -> (groundEndX, height)
    // Стойка: (poleX, height) -> (poleX, crossbarY)
    // Перекладина: (poleX, crossbarY) -> (crossbarXEnd, crossbarY)
    const pathData = `
    M 0 ${height}
    L ${groundEndX} ${height}

    M ${poleX} ${height}
    L ${poleX} ${crossbarY}

    M ${poleX} ${crossbarY}
    L ${crossbarXEnd} ${crossbarY}
  `;

    return (
        <path
            d={pathData}
            stroke="brown"
            strokeWidth={4}
            fill="none"
        />
    );
};

export default GallowsView;