// Preview/TableViewable.tsx
import React from 'react';
import {Exercise, ExerciseTable} from "../../../../../domain/models";
import {ExerciseViewable} from "../../ExerciseViewable";
import {PracticeTableView} from "../Practice/PracticeTableView";
import {PracticeTableViewModel} from "../Practice/PracticeTableViewModel";
import {Resizer} from "../../../Resizer";

export class ExerciseTableViewable implements ExerciseViewable {
    constructor(private exerciseTable: ExerciseTable) {}

    renderView(exercise: Exercise): JSX.Element {
        if (exercise.type.kind !== 'table') {
            throw new Error('Unsupported exercise type for ExerciseTable: ExerciseViewable');
        }

        return (
            <Resizer targetSize={{ width: 300, height: 150 }}>
                <PracticeTableView viewModel={ new PracticeTableViewModel(exercise, undefined, undefined)} />
            </Resizer>
        );
    }
}