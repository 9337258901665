// ==================== MediaLoader.ts ====================
import { getStorage, ref, uploadBytes, getDownloadURL, getMetadata, StorageReference } from 'firebase/storage';
import {AudioLoading, AudioUploading, MediaFile, MediaLoading, MediaUploading} from "../../../domain/interfaces/MediaTypes";

/**
 * Класс для загрузки и скачивания медиафайлов (аудио, видео) в Firebase Storage
 * Реализует сразу несколько интерфейсов (AudioUploading, AudioLoading, MediaUploading, MediaLoading).
 */
export class MediaLoader implements MediaUploading, MediaLoading, AudioUploading, AudioLoading {
    private storage;

    constructor() {
        // Предполагается, что Firebase уже инициализирован где-то в вашем приложении
        this.storage = getStorage();
    }

    /**
     * Загрузка файла (аудио или видео) в Firebase Storage
     */
    public async uploadFile(data: Blob, fileName: string, fileExtension: string): Promise<string> {
        // Путь, по которому будет храниться файл
        const path = `media/${fileName}.${fileExtension}`;
        const storageRef = ref(this.storage, path);

        // Определяем contentType (MIME-type) на основе расширения файла
        const contentType = this.getMimeType(fileExtension);

        // Мета-данные, включая contentType
        // (Во многих случаях можно не задавать, но так корректнее)
        const metadata = {
            contentType,
        };

        // Загружаем данные
        await uploadBytes(storageRef, data, metadata);
        // Получаем ссылку для скачивания
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
    }

    /**
     * Загрузка медиафайла из Firebase Storage
     */
    public async loadFile(url: string): Promise<MediaFile> {
        const storageRef = ref(this.storage, url);

        // Сначала достанем метаданные, чтобы узнать contentType
        const fileMetadata = await getMetadata(storageRef);
        const contentType = fileMetadata.contentType || 'application/octet-stream';

        // Теперь получаем сам файл (как Blob)
        const dataBlob = await this.getBlobFromStorageRef(storageRef);

        return { data: dataBlob, contentType };
    }

    /**
     * Загрузка аудио (упрощённый вариант, используется по умолчанию расширение .mp3)
     */
    public async uploadAudio(data: Blob, fileName: string): Promise<string> {
        return this.uploadFile(data, fileName, 'mp3');
    }

    /**
     * Скачивание аудиофайла (проверяем, что contentType начинается с "audio/")
     */
    public async loadAudio(url: string): Promise<Blob> {
        const mediaFile = await this.loadFile(url);
        if (!mediaFile.contentType.startsWith('audio/')) {
            throw new Error('File is not an audio file.');
        }
        return mediaFile.data;
    }

    /**
     * Получаем Blob-файл из StorageReference
     */
    private async getBlobFromStorageRef(storageRef: StorageReference): Promise<Blob> {
        // В веб-версии Firebase нет метода getData(maxSize) напрямую,
        // поэтому получаем ссылку для скачивания и делаем fetch.
        const downloadURL = await getDownloadURL(storageRef);
        const response = await fetch(downloadURL);

        if (!response.ok) {
            throw new Error(`Failed to fetch file. HTTP status: ${response.status}`);
        }

        return await response.blob();
    }

    /**
     * Определяем MIME-тип по расширению
     */
    private getMimeType(fileExtension: string): string {
        const ext = fileExtension.toLowerCase();
        switch (ext) {
            case 'mp3':
                return 'audio/mpeg';
            case 'wav':
                return 'audio/wav';
            case 'm4a':
                return 'audio/mp4';
            case 'mp4':
                return 'video/mp4';
            case 'mov':
                return 'video/quicktime';
            case 'm4v':
                return 'video/x-m4v';
            default:
                return 'application/octet-stream';
        }
    }
}