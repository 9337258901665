// ========== PairElementEditor.tsx ==========

import React from 'react';
import { BaseButton } from '../../BaseComponents/BaseButton';
import { BaseText } from '../../BaseComponents/BaseText';
import { BaseVStack } from '../../BaseComponents/BaseVStack';
import { BaseTextField } from '../../BaseComponents/BaseTextField';
import {PairElementUI} from "./PairElementUI";

interface PairElementEditorProps {
    element: PairElementUI;
    onElementChange: (newElement: PairElementUI) => void;
    onSelectFile: () => void;
}

export function PairElementEditor({ element, onElementChange, onSelectFile }: PairElementEditorProps) {
    return (
        <BaseVStack>
            <BaseTextField
                placeholder="Enter text"
                value={element.text}
                onChange={(newVal) => onElementChange({ ...element, text: newVal })}
            />
            {element.fileURL ? (
                <>
                    <BaseText>{element.fileURL.toString()}</BaseText>
                    <BaseButton text="Choose another file" onPress={onSelectFile} />
                </>
            ) : (
                <BaseButton text="Choose a file" onPress={onSelectFile} />
            )}
        </BaseVStack>
    );
}
