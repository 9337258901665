////////////////////////////////////
// VideoPlayerView.tsx
////////////////////////////////////
interface VideoPlayerViewProps {
    url: URL;                          // URL видео
    style?: React.CSSProperties;       // Дополнительные стили
}

export function VideoPlayerView({ url, style }: VideoPlayerViewProps) {
    return <video src={url.toString()} controls style={{ maxWidth: '100%', maxHeight: '100%', ...style }} />;
}