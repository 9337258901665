// CellCoordinate.ts

const COORD_CACHE = new Map<string, CellCoordinate>();

export class CellCoordinate {
    private readonly __uniqueBrand!: void; // Поле проинициализировано через утверждение

    private constructor(public readonly columnIndex: number, public readonly rowIndex: number) {}

    static create(columnIndex: number, rowIndex: number): CellCoordinate {
        const key = `${columnIndex},${rowIndex}`;
        if (!COORD_CACHE.has(key)) {
            COORD_CACHE.set(key, new CellCoordinate(columnIndex, rowIndex));
        }
        return COORD_CACHE.get(key)!;
    }
}