// CreateExerciseFlowStep.ts

export enum CreateExerciseFlowStep {
    selectType = 1,
    settings = 2,
    editing = 3
}

// Вспомогательные методы (title)
export function getFlowStepTitle(step: CreateExerciseFlowStep): string {
    switch (step) {
        case CreateExerciseFlowStep.selectType:
            return "Выбор типа упражнения";
        case CreateExerciseFlowStep.settings:
            return "Настройки";
        case CreateExerciseFlowStep.editing:
            return "Редактирование";
        default:
            return "";
    }
}