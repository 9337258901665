// ========== BaseComponents/BaseButton.tsx ==========
import React from 'react';

interface BaseButtonProps {
    text: string;
    onPress: () => void;
}

export function BaseButton({ text, onPress }: BaseButtonProps) {
    return (
        <button onClick={onPress}>{text}</button>
    );
}