// extension ExerciseAnagram: ExerciseViewable -> Аналог
// ExerciseAnagram.tsx (расширяем ExerciseAnagram)
import { AnagramPreview } from './AnagramPreview';
import React from 'react';
import {Exercise, ExerciseAnagram} from "../../../../../domain/models";
import {ExerciseViewable} from "../../ExerciseViewable";

export class ExerciseAnagramViewable implements ExerciseViewable {
    private exercise: ExerciseAnagram;

    constructor(exercise: ExerciseAnagram) {
        this.exercise = exercise;
    }

    renderView(_exercise: Exercise): JSX.Element {
        return <AnagramPreview exercise={this.exercise} />;
    }
}
