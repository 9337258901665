// Для ZoomableImageView, VideoPlayerView, AudioPlayerView – заглушки:
////////////////////////////////////
// ZoomableImageView.tsx
////////////////////////////////////
import React from 'react';
interface ZoomableImageViewProps {
    url: URL;
}
export function ZoomableImageView({ url }: ZoomableImageViewProps) {
    return <img src={url.toString()} alt="Zoomable" style={{ maxWidth: '100%', maxHeight: '100%' }} />;
}