// 8. Example React component with mobx-react-lite for observation
import React from 'react';
import { observer } from 'mobx-react-lite';
import {NotesWindowViewModel} from "./NotesWindowViewModel";

interface NotesWindowViewProps {
    viewModel: NotesWindowViewModel;
}

export const NotesWindowView: React.FC<NotesWindowViewProps> = observer(
    function NotesWindowView({ viewModel }) {
        const fileInputRef = React.useRef<HTMLInputElement>(null);

        const onAddText = () => {
            viewModel.addTextItem();
        };

        const onFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!e.target.files || e.target.files.length === 0) return;
            const file = e.target.files[0];
            await viewModel.addFileItem(file);
            e.target.value = '';
        };

        const onDelete = (index: number) => {
            viewModel.deleteItems([index]);
        };

        const moveUp = (index: number) => {
            if (index === 0) return;
            viewModel.moveItems([index], index - 1);
        };

        const moveDown = (index: number) => {
            if (index === viewModel.currentState.items.length - 1) return;
            viewModel.moveItems([index], index + 2);
        };

        return (
            <div style={{ width: 400, height: 700, overflow: 'auto', border: '1px solid #ccc' }}>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                    {viewModel.currentState.items.map((item, index) => {
                        const { kind } = item.content;

                        // Display / edit logic based on kind
                        switch (kind) {
                            case 'text':
                                return (
                                    <li key={item.id} style={{ marginBottom: '1rem' }}>
                    <textarea
                        rows={5}
                        value={item.content.text}
                        onChange={(e) => viewModel.updateText(item.id, e.target.value)}
                        style={{ width: '100%' }}
                    />
                                        <button onClick={() => moveUp(index)}>↑ Move Up</button>
                                        <button onClick={() => moveDown(index)}>↓ Move Down</button>
                                        <button onClick={() => onDelete(index)}>Delete</button>
                                    </li>
                                );
                            case 'image':
                                return (
                                    <li key={item.id} style={{ marginBottom: '1rem' }}>
                                        {/* item.content.url is a real URL object */}
                                        <img src={item.content.url.toString()} alt="" style={{ maxWidth: '100%' }} />
                                        <div>
                                            <button onClick={() => moveUp(index)}>↑ Move Up</button>
                                            <button onClick={() => moveDown(index)}>↓ Move Down</button>
                                            <button onClick={() => onDelete(index)}>Delete</button>
                                        </div>
                                    </li>
                                );
                            case 'audio':
                                return (
                                    <li key={item.id} style={{ marginBottom: '1rem' }}>
                                        <audio controls src={item.content.url.toString()} />
                                        <div>
                                            <button onClick={() => moveUp(index)}>↑ Move Up</button>
                                            <button onClick={() => moveDown(index)}>↓ Move Down</button>
                                            <button onClick={() => onDelete(index)}>Delete</button>
                                        </div>
                                    </li>
                                );
                            case 'video':
                                return (
                                    <li key={item.id} style={{ marginBottom: '1rem' }}>
                                        <video controls src={item.content.url.toString()} style={{ width: '100%' }} />
                                        <div>
                                            <button onClick={() => moveUp(index)}>↑ Move Up</button>
                                            <button onClick={() => moveDown(index)}>↓ Move Down</button>
                                            <button onClick={() => onDelete(index)}>Delete</button>
                                        </div>
                                    </li>
                                );
                            case 'link':
                                return (
                                    <li key={item.id} style={{ marginBottom: '1rem' }}>
                                        <a href={item.content.url.toString()} target="_blank" rel="noreferrer">
                                            {item.content.url.toString()}
                                        </a>
                                        <div>
                                            <button onClick={() => moveUp(index)}>↑ Move Up</button>
                                            <button onClick={() => moveDown(index)}>↓ Move Down</button>
                                            <button onClick={() => onDelete(index)}>Delete</button>
                                        </div>
                                    </li>
                                );
                            default:
                                return null;
                        }
                    })}
                </ul>

                <div style={{ padding: '1rem' }}>
                    <button onClick={onAddText}>Add text</button>
                    <button onClick={() => fileInputRef.current?.click()}>Add file</button>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={onFileSelect}
                        accept="image/*,video/*,audio/*"
                    />
                </div>
            </div>
        );
    }
);
