import {Exercise, ExerciseHangman} from "../../../../../domain/models";
import {useState} from "react";

interface HangmanCreateViewProps {
    onExercisesCreated: (exs: Exercise[]) => void;
}

export function HangmanCreateView({ onExercisesCreated }: HangmanCreateViewProps) {
    const [hangmanExercises, setHangmanExercises] = useState<ExerciseHangman[]>([
        { word: '', hint: '' },
    ]);

    const addExercise = () => {
        setHangmanExercises((prev) => [...prev, { word: '', hint: '' }]);
    };

    const removeExercise = (index: number) => {
        setHangmanExercises((prev) => prev.filter((_, i) => i !== index));
    };

    const handleChange = (
        index: number,
        field: keyof ExerciseHangman,
        value: string
    ) => {
        setHangmanExercises((prev) =>
            prev.map((item, i) => {
                if (i === index) {
                    return { ...item, [field]: value };
                }
                return item;
            })
        );
    };

    const handleSave = () => {
        const exercises: Exercise[] = hangmanExercises.map((item) => {
            return {
                id: crypto.randomUUID().toUpperCase(),
                type: { kind: 'hangman', data: { word: item.word, hint: item.hint } },
            };
        });

        onExercisesCreated(exercises);
    };

    return (
        <div>
            <h2>Hangman</h2>
            {hangmanExercises.map((exercise, index) => (
                <div key={index} style={{ marginBottom: '1rem' }}>
                    <input
                        type="text"
                        placeholder="Word"
                        value={exercise.word}
                        onChange={(e) => handleChange(index, 'word', e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Hint"
                        value={exercise.hint}
                        onChange={(e) => handleChange(index, 'hint', e.target.value)}
                    />
                    {hangmanExercises.length > 1 && (
                        <button onClick={() => removeExercise(index)}>Delete</button>
                    )}
                </div>
            ))}
            <button onClick={addExercise}>Add more</button>
            <button onClick={handleSave}>Save</button>
        </div>
    );
}