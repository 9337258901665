// ExerciseTypeSelectionView.tsx
import React from "react";
import {ALL_EXERCISE_CREATION_TYPES, ExerciseCreationType} from "../../../../domain/models/ExerciseCreate";
import {GenericTypePreview} from "./CreationTypePreviewFactory";
import {TableTypePreview} from "../../exercises_types/Table/SmallPreview/TableTypePreview";
import {HangmanTypePreview} from "../../exercises_types/Hangman/SmallPreview/HangmanTypePreview";
import {MatchingPairsTypePreview} from "../../exercises_types/MatchingPairs/SmallPreview/MatchingPairsTypePreview";

interface Props {
    onSelect: (type: ExerciseCreationType) => void;
    onClose: () => void;
}

export function ExerciseTypeSelectionView({ onSelect, onClose }: Props) {
    const columnsStyle: React.CSSProperties = {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "20px",
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", padding: 20 }}>
            <h2 style={{ marginBottom: 20, color: "#fff" }}>Select Exercise Type</h2>

            <div style={{ ...columnsStyle, flex: 1 }}>
                {ALL_EXERCISE_CREATION_TYPES.map((type) => (
                    <div
                        key={type}
                        style={{
                            border: "1px solid #666",
                            padding: 16,
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            backgroundColor: "#2a2a2a",   // чуть более тёмный фон
                        }}
                        onClick={() => onSelect(type)}
                    >
                        <h3 style={{ marginTop: 0, marginBottom: 8, color: "#fff" }}>{typeLabel(type)}</h3>
                        <div style={{ height: 150, width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {renderPreview(type)}
                        </div>
                    </div>
                ))}
            </div>

            <div style={{ marginTop: 20 }}>
                <button style={buttonStyle} onClick={onClose}>Cancel</button>
            </div>
        </div>
    );
}

function typeLabel(type: ExerciseCreationType): string {
    switch (type) {
        case ExerciseCreationType.MissingWord:    return "Missing Word";
        case ExerciseCreationType.SelectOption:   return "Select Option";
        case ExerciseCreationType.WordSearch:     return "Word Search";
        case ExerciseCreationType.Anagram:        return "Anagram";
        case ExerciseCreationType.Crossword:      return "Crossword";
        case ExerciseCreationType.FillBlanks:     return "Fill Blanks";
        case ExerciseCreationType.MatchingPairs:  return "Matching Pairs";
        case ExerciseCreationType.JustContent:    return "Just Content";
        case ExerciseCreationType.Table:          return "Table";
        default: return type;
    }
}

function renderPreview(type: ExerciseCreationType): JSX.Element {
    switch (type) {
        case ExerciseCreationType.MissingWord:
            return <GenericTypePreview label={"Missing Word\nPreview"} />;
        case ExerciseCreationType.SelectOption:
            return <GenericTypePreview label={"Select Option\nPreview"} />;
        case ExerciseCreationType.WordSearch:
            return <GenericTypePreview label={"Word Search\nPreview"} />;
        case ExerciseCreationType.Anagram:
            return <GenericTypePreview label={"Anagram\nPreview"} />;
        case ExerciseCreationType.Crossword:
            return <GenericTypePreview label={"Crossword\nPreview"} />;
        case ExerciseCreationType.FillBlanks:
            return <GenericTypePreview label={"Fill Blanks\nPreview"} />;
        case ExerciseCreationType.MatchingPairs:
            return <MatchingPairsTypePreview key={Date.now()} />;
        case ExerciseCreationType.JustContent:
            return <GenericTypePreview label={"Just Content\nPreview"} />;
        case ExerciseCreationType.Table:
            return <TableTypePreview key={Date.now()} />;
        case ExerciseCreationType.Hangman:
            return <HangmanTypePreview key={Date.now()} />

        default:
            // fallback — если вдруг появится новый тип
            return <GenericTypePreview label={`${type}\nPreview`} />;
    }
}

const buttonStyle: React.CSSProperties = {
    backgroundColor: "#555",
    color: "#fff",
    border: "1px solid #888",
    borderRadius: 4,
    padding: "8px 16px",
    cursor: "pointer",
    marginRight: 8,
};