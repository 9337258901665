import React, {useEffect, useState} from 'react';
import {TableCreateViewModel} from './TableCreateViewModel';
import {TableView} from '../Base/TableView';
import {TableModel} from "../../../../../domain/Exercises/Table/Models/TableModel";
import {Exercise} from "../../../../../domain/models";
import {GrayButton} from "./GrayButton";
import {makeEmptyModel, makeEmptyTableModel} from "./MakeEmptyModel";

interface TableCreateViewProps {
    model?: TableModel;  // optional начальная модель
    onExercisesCreated: (exercises: Exercise[]) => void;
}

export function TableCreateView({ model, onExercisesCreated }: TableCreateViewProps) {
    // Храним ViewModel в state
    const [viewModel] = useState(() =>
        new TableCreateViewModel(model ?? makeEmptyTableModel(), onExercisesCreated)
    );

    // Локальный счётчик, чтобы «форсить» перерисовку (аналог @Published).
    const [_, setTick] = useState(0);
    function forceUpdate() {
        setTick((x) => x + 1);
    }

    useEffect(() => {
        viewModel.setForceUpdateCallback(forceUpdate);
    }, [viewModel]);

    // Обёртки над методами VM, чтобы после них делать forceUpdate().
    const handleAddColumn = () => {
        viewModel.addColumn();
        forceUpdate();
    };
    const handleRemoveColumn = () => {
        viewModel.removeColumn();
        forceUpdate();
    };
    const handleAddRow = () => {
        viewModel.addRow();
        forceUpdate();
    };
    const handleFillTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        viewModel.fillText = e.target.value;
        forceUpdate();
    };
    const handleSave = () => {
        viewModel.savePressed(); // это вызовет onExercisesCreated(...)
        // можно forceUpdate() не делать, т.к. после save может быть закрытие экрана
    };

    // Методы, которые меняют model (fillRow, etc.) уже «обёрнуты» в VM,
    // но всё равно нужно forceUpdate() после них. Для примера fillRow:
    const handleFillRow = async (rowIndex: number) => {
        await viewModel.fillRow(rowIndex);
        forceUpdate();
    };
    const handleFill = async () => {
        await viewModel.fill();
        forceUpdate();
    };
    const handleRemoveRow = (rowIndex: number) => {
        viewModel.removeRow(rowIndex);
        forceUpdate();
    };
    const handleToggleStable = (r: number, c: number) => {
        viewModel.toggleStable(r, c);
        forceUpdate();
    };
    const handleUpdatePlaceholder = (c: number, r: number | null, text: string) => {
        viewModel.updatePlaceholder(c, r, text);
        forceUpdate();
    };
    const handleConfirmPressed = (c: number, r: number | null) => {
        viewModel.confirmPressed(c, r);
        forceUpdate();
    };
    const handleConfirmTablePressed = () => {
        viewModel.confirmTablePressed();
        forceUpdate();
    };
    const handleEditPressed = (c: number, r: number | null) => {
        viewModel.editPressed(c, r);
        forceUpdate();
    };

    const hasPlaceholder = viewModel.model.columns.some(
        (col) =>
            col.header.kind === 'editor' ||
            col.items.some((item) => item.kind === 'editor')
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            {/* Левая часть: ZStack аналог через position: relative */}
            <div style={{ display: 'flex', position: 'relative', flex: 1, margin: 8, overflowX: 'auto', justifyContent: 'center' }}>
                <TableView
                    columns={viewModel.model.columns}
                    stableCells={viewModel.model.stableCells}
                    toggleStable={handleToggleStable}
                    updatePlaceholder={handleUpdatePlaceholder}
                    confirmPressed={handleConfirmPressed}
                    editPressed={handleEditPressed}
                    fillRow={handleFillRow}
                    removeRow={handleRemoveRow}
                    loadingRows={viewModel.loadingRows}
                />
            </div>

            {/* Правая часть: settingsView */}
            <div
                style={{
                    width: 300,
                    margin: 8,
                    padding: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',
                }}
            >
                <div style={{ marginBottom: 'auto' }}>
                    <GrayButton text="Add column" onClick={handleAddColumn} />
                    <GrayButton text="Remove column" onClick={handleRemoveColumn} />
                    <GrayButton text="Add row" onClick={handleAddRow} />
                </div>

                <div style={{ marginTop: 40 }}>
                    <span style={{ color: '#fff' }}>Filling rules</span>
                    <textarea
                        style={{ width: '100%', height: 100, marginTop: 4, backgroundColor: '#333', color: '#fff' }}
                        value={viewModel.fillText}
                        onChange={handleFillTextChange}
                    />
                    <GrayButton text="Fill table" onClick={handleFill} />
                </div>

                <div style={{ marginTop: 80 }}>
                    {hasPlaceholder ? (
                        <GrayButton text="Confirm table" onClick={handleConfirmTablePressed} />
                    ) : (
                        <GrayButton text="Save" onClick={handleSave} />
                    )}
                </div>
            </div>
        </div>
    );
}

