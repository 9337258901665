// ItemsGridView.tsx
import React from 'react';
import { PairElementView } from './PairElementView';
import { ContentType, PairElement } from "../../../../../../domain/models";
import { elementContent } from "../elementContent";

interface ItemsGridViewProps {
    items: PairElement[];
    columnsCount: number;
    spacing: number;
    isSelected: (item: PairElement) => boolean;
    isMatched: (item: PairElement) => boolean;
    isWrong: (item: PairElement) => boolean;
    onSelect: (item: PairElement) => void;
    onLongPressImageOrVideo: (content: ContentType) => void;
}

/**
 * Сетка, которая НЕ растягивает элементы по высоте, а даёт им занимать высоту по контенту.
 * Чтобы размеры были «какие есть».
 */
export function ItemsGridView({
                                  items,
                                  columnsCount,
                                  spacing,
                                  isSelected,
                                  isMatched,
                                  isWrong,
                                  onSelect,
                                  onLongPressImageOrVideo
                              }: ItemsGridViewProps) {
    return (
        <div
            style={{
                display: 'grid',
                // Вместо minmax(120px, 1fr) — ставим auto / min-content
                gridTemplateColumns: `repeat(${columnsCount}, auto)`,
                gridAutoRows: 'min-content',
                gap: `${spacing}px`,
                width: '100%',
                alignItems: 'start',
            }}
        >
            {items.map(item => {
                const data = elementContent(item);
                return (
                    <PairElementView
                        key={item.id}
                        itemId={item.id}
                        data={data}
                        isSelected={isSelected(item)}
                        isMatched={isMatched(item)}
                        isWrongAttempt={isWrong(item)}
                        onLongPressImageOrVideo={onLongPressImageOrVideo}
                        action={() => onSelect(item)}
                    />
                );
            })}
        </div>
    );
}