// src/components/MainContent.tsx
import React from 'react';
import StartScreen from './start/StartScreen';
import AddingTypes from './adding/AddingTypes';
import WordsToAddPreview from './adding/WordsToAddPreview';
import SubscriptionScreen from './start/SubscriptionScreen';
import ScreenForType from './navigation/ScreenForType';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SettingsScreen from "./settings/SettingsScreen";
import AuthScreen from "./settings/auth/AuthScreen";
import CreateExerciseView from "./exercise/create_exercise/CreateExerciseView";
import LessonsList from "./exercise/LessonsList";
import LessonPractice from "./exercise/practice/LessonPractice";
import CreateFillBlanksScreen from "./exercise/fill_blanks/CreateFillBlanksScreen";
import FillBlanksExercisesList from "./exercise/fill_blanks/FillBlanksExercisesList";
import FillBlanksExerciseScreen from "./exercise/fill_blanks/FillBlanksExerciseScreen";
import {AllExercisesView} from "./AllExercises/AllExercisesView";
import {LessonCreationView} from "./LessonCreation/LessonCreationView";

const MainContent: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<StartScreen />} />
                <Route path="/addFromTextEnteringText" element={<ScreenForType screen="addFromTextEnteringText" />} />
                <Route path="/addByTheme" element={<ScreenForType screen="addByTheme" />} />
                <Route path="/addMultiple" element={<ScreenForType screen="addMultiple" />} />
                <Route path="/mainAdd" element={<AddingTypes />} />
                <Route path="/subscription" element={<SubscriptionScreen />} />
                <Route path="/addManually" element={<ScreenForType screen="addManually" />} />
                <Route path="/mainStat" element={<ScreenForType screen="mainStat" />} />
                <Route path="/settings" element={<SettingsScreen />} />
                <Route path="/auth" element={<AuthScreen />} />
                <Route path="/mainStudy" element={<ScreenForType screen="mainStudy" />} />
                <Route path="/createExercise" element={<CreateExerciseView />} />
                <Route path="/lessonsList" element={<LessonsList />} />
                <Route path="/lessonPractice/:id" element={<LessonPractice />} />
                <Route path="/createFillBlanks" element={<CreateFillBlanksScreen />} />
                <Route path="/fillBlanksList" element={<FillBlanksExercisesList />} />
                <Route path="/fillBlanksExercise/:id" element={<FillBlanksExerciseScreen />} />
                <Route path="/allExercises" element={<AllExercisesView />} />
                <Route path="/createLesson" element={<LessonCreationView />} />

            </Routes>
        </Router>
    );
};

export default MainContent;
