// ElementContentView.tsx
import React, { useEffect, useState } from 'react';
import {AudioPlayerView} from "../../../BaseComponents/AudioPlayerView";
import {VideoPlayerView} from "../../../BaseComponents/VideoPlayerView";
import {ImageFromData} from "../../../BaseComponents/ImageFromData";
import {ContentData} from "../../../../../../domain/models";

export function ElementContentView({ data }: { data: ContentData }) {
    const [audioData, setAudioData] = useState<Uint8Array | null>(null);
    const [imageData, setImageData] = useState<Uint8Array | null>(null);

    useEffect(() => {
        // Если контент = audio или image, подгружаем
        if (data.content?.kind === 'audio' || data.content?.kind === 'image') {
            const url = data.content.url;
            fetch(url.toString())
                .then(r => r.arrayBuffer())
                .then(buf => {
                    const bytes = new Uint8Array(buf);
                    if (data.content?.kind === 'audio') {
                        setAudioData(bytes);
                    } else if (data.content?.kind === 'image') {
                        setImageData(bytes);
                    }
                })
                .catch(() => {
                    // Ошибка
                });
        }
    }, [data.content]);

    return (
        <div style={containerStyle}>
            {/* Отображаем текст, если есть */}
            {data.text && <div style={{ marginBottom: 8 }}>{data.text}</div>}

            {/* Отображаем контент, если есть */}
            {data.content && (() => {
                switch (data.content.kind) {
                    case 'text':
                        return <div>{data.content.text}</div>;

                    case 'audio':
                        return audioData ? (
                            <AudioPlayerView audioData={audioData.buffer} />
                        ) : (
                            <div>Загрузка аудио...</div>
                        );

                    case 'video':
                        return (
                            <div style={mediaContainerStyle}>
                                <VideoPlayerView url={data.content.url} style={mediaStyle} />
                            </div>
                        );

                    case 'image':
                        return imageData ? (
                            <div style={mediaContainerStyle}>
                                <ImageFromData data={imageData} style={mediaStyle} />
                            </div>
                        ) : (
                            <div>Загрузка изображения...</div>
                        );

                    case 'link':
                        return <a href={data.content.url.toString()}>{data.content.url.toString()}</a>;
                }
            })()}
        </div>
    );
}

// Общий стиль для контейнера
const containerStyle: React.CSSProperties = {
    textAlign: 'center',
    padding: 8,
    maxHeight: '300px', // Ограничиваем высоту элемента
    overflow: 'hidden', // Прячем всё, что выходит за пределы
    boxSizing: 'border-box',
};

// Контейнер для медиа (фото/видео)
const mediaContainerStyle: React.CSSProperties = {
    width: '100%',         // Ширина контейнера 100%
    height: '100%',        // Высота контейнера 100%
    maxHeight: '300px',    // Ограничиваем высоту для медиа
    display: 'flex',       // Центрируем контент
    alignItems: 'center',
    justifyContent: 'center',
};

// Стили для медиа-контента
const mediaStyle: React.CSSProperties = {
    width: 'auto',         // Автоматическая ширина
    height: '100%',        // Высота равна контейнеру
    maxHeight: '300px',    // Ограничиваем максимум
    objectFit: 'contain',  // Масштабируем так, чтобы контент полностью вмещался
};