import {Exercise} from "../models";
import {ExercisesStorage} from "../interfaces/ExercisesStorage";

export interface SaveSingleExerciseInteractor {
    saveExercise(exercise: Exercise): void;
}

export class SaveSingleExerciseInteractorStub implements SaveSingleExerciseInteractor {
    saveExercise(exercise: Exercise) {
        console.log("Stub: Saving exercise", exercise);
    }
}

export class SaveSingleExerciseInteractorImpl implements SaveSingleExerciseInteractor {
    private storage: ExercisesStorage;

    constructor(storage: ExercisesStorage) {
        this.storage = storage;
    }

    saveExercise(exercise: Exercise) {
        // аналог Swift Task { await storage.saveExercise(exercise) }
        (async () => {
            try {
                await this.storage.saveExercise(exercise);
            } catch (error) {
                console.error("Failed to save single exercise:", error);
            }
        })();
    }
}