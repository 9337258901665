// ExerciseTextPreview.tsx
import React from "react";
import { Exercise } from "../../../domain/models";

// Минимальная версия
interface Props {
    exercise: Exercise;
    width?: number;
    height?: number;
}

export function ExerciseTextPreview({ exercise, width = 250, height = 80 }: Props) {
    const [typeName, sentence, words] = previewContent(exercise);

    return (
        <div
            style={{
                width,
                height,
                padding: "4px 8px",
                backgroundColor: "rgba(128,128,128,0.1)",
                borderRadius: 6,
                overflow: "hidden"
            }}
        >
            <div style={{ fontWeight: "bold" }}>{typeName}</div>
            <div style={{ fontSize: 14, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                {sentence || "-"}
            </div>
            <div style={{ fontSize: 12, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                {words || "-"}
            </div>
        </div>
    );
}

// Аналог extension Exercise { func previewContent() -> (String, String, String) }
function previewContent(ex: Exercise): [string, string, string] {
    switch (ex.type.kind) {
        case "missingWord":
            return ["Missing Word", ex.type.data.sentence, ex.type.data.correctForm];
        case "selectOption":
            return ["Select Option", ex.type.data.sentence, ex.type.data.correctOption];
        case "wordSearch":
            return ["Word Search", "", ex.type.data.words.map((w: any) => w.word).join(", ")];
        case "anagram":
            return ["Anagram", "", ex.type.data.word.word];
        case "crossword":
            return ["Crossword", "", ex.type.data.words.map((w: any) => w.word).join(", ")];
        case "fillBlanks":
            return ["Fill Blanks", ex.type.data.text, ex.type.data.words.map((w: any) => w.word).join(", ")];
        case "matchingPairs":
            return [
                "Matching Pairs",
                "",
                ex.type.data.pairs.map((p: any) => p.left.text || "").join(", ")
            ];
        case "justContent":
            // ...
            return ["Just Content", "", "some text"];
        case "table":
            const headers = ex.type.data.columns.map((col: any) => col.header).join(", ");
            const items = ex.type.data.columns[0]?.items?.join(", ") ?? "";
            return ["Table", headers, items];
        default:
            return ["Unknown", "", ""];
    }
}