// ./domain/interfaces/SaveExerciseInteractor.ts
import { Lesson } from '../models/Lesson';
import {ExercisesStorage} from "./ExercisesStorage";
import {AppUserManagerProtocol} from "./AppUserManagerProtocol";

export interface SaveExerciseInteractor {
    save(exerciseSet: Lesson): Promise<void>;
}

export class SaveExerciseInteractorImpl implements SaveExerciseInteractor {
    private storage: ExercisesStorage;
    private userManager: AppUserManagerProtocol;

    public constructor(storage: ExercisesStorage, userManager: AppUserManagerProtocol) {
        this.storage = storage;
        this.userManager = userManager;
    }

    public async save(exerciseSet: Lesson): Promise<void> {
        const authorId = this.userManager.currentUser?.uid;
        if (!authorId) {
            throw new Error('User not authenticated');
        }
        exerciseSet.authorId = authorId;
        await this.storage.saveExerciseSet(exerciseSet);
        await this.userManager.addExerciseSet(exerciseSet.id, authorId);
    }
}
