import React, { useRef, useState, useLayoutEffect, ReactNode, CSSProperties } from "react";

interface ResizerProps {
    /** Если задано scale — используем его напрямую. */
    scale?: number;
    /** Если задан targetSize — вычисляем «aspect fit»
     (умещаем по ширине/высоте, берём min(scaleW, scaleH)). */
    targetSize?: { width: number; height: number };
    /** Ваш контент, который нужно трансформировать. */
    children: ReactNode;
}

/**
 * Аналог SwiftUI Resizer:
 * - Если указать `scale`, контент будет фиксированно масштабироваться на эту величину.
 * - Если указать `targetSize`, то будет вычисляться aspect-fit-масштаб,
 *   чтобы ребёнок уместился внутрь targetSize, сохраняя пропорции.
 * - Если ни то, ни другое не задано, контент отображается без скейла (scale=1).
 */
export function Resizer({ scale, targetSize, children }: ResizerProps) {
    const [originalSize, setOriginalSize] = useState({ width: 0, height: 0 });
    const containerRef = useRef<HTMLDivElement>(null);

    // Измеряем «естественный» размер контента, когда он отрендерился.
    useLayoutEffect(() => {
        if (!containerRef.current) return;
        // measure the child
        const rect = containerRef.current.getBoundingClientRect();
        // Сохраняем в state
        setOriginalSize({ width: rect.width, height: rect.height });
    }, [children]);
    // При изменении children заново измеряем

    // Вычисляем финальный scale
    const computedScale = getComputedScale();

    // Также вычисляем итоговый width/height, чтобы outer-container занимал нужный размер
    const finalWidth = originalSize.width * computedScale;
    const finalHeight = originalSize.height * computedScale;

    // Inline-стили
    const wrapperStyle: CSSProperties = {
        position: "relative",
        width: finalWidth,
        height: finalHeight,
        overflow: "hidden",
    };

    // Для внутреннего (child) контейнера — делаем position: absolute, top:0, left:0,
    // применяем transform: scale(...). anchor=top-left => transformOrigin: 'top left'.
    const childStyle: CSSProperties = {
        position: "absolute",
        top: 0,
        left: 0,
        transformOrigin: "top left",
        transform: `scale(${computedScale})`,
    };

    return (
        <div style={wrapperStyle}>
            <div ref={containerRef} style={childStyle}>
                {children}
            </div>
        </div>
    );

    function getComputedScale(): number {
        // Если вручную задан scale — вернём его
        if (scale !== undefined) {
            return scale;
        }
        // Если есть targetSize и мы уже знаем originalSize
        if (
            targetSize &&
            originalSize.width > 0 &&
            originalSize.height > 0
        ) {
            const scaleW = targetSize.width / originalSize.width;
            const scaleH = targetSize.height / originalSize.height;
            // Минимальный => effect «уместить» (aspect-fit).
            return Math.min(scaleW, scaleH);
        }
        // Иначе 1
        return 1;
    }
}