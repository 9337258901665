// FirestoreNoteRepository.ts

import {
    getFirestore,
    doc,
    onSnapshot,
    setDoc,
    updateDoc,
    getDoc
} from "firebase/firestore";
import {NoteRepository} from "../../ui/components/Notes/NoteRepository";
import {NotesItem, NotesState} from "../../ui/components/Notes/NotesState";
import {ContentType} from "../../domain/models";

export class FirestoreNoteRepository implements NoteRepository {
    private db = getFirestore();
    private unsubMap: Map<string, () => void> = new Map();

    subscribeToUpdates(
        noteId: string,
        onUpdate: (state: NotesState | undefined) => void
    ): () => void {
        const ref = doc(this.db, "notes", noteId);

        const unsubscribe = onSnapshot(ref, (snapshot) => {
            if (!snapshot.exists()) {
                onUpdate(undefined);
                return;
            }
            const data = snapshot.data();
            try {
                const state = decodeNotesState(data);
                onUpdate(state);
            } catch (err) {
                console.error("Error decoding note state:", err);
                onUpdate(undefined);
            }
        });

        this.unsubMap.set(noteId, unsubscribe);

        return () => {
            unsubscribe();
            this.unsubMap.delete(noteId);
        };
    }

    async saveState(noteId: string, state: NotesState): Promise<void> {
        const ref = doc(this.db, "notes", noteId);
        const snapshot = await getDoc(ref);

        // Если документ отсутствует, используем setDoc, иначе обновляем
        if (!snapshot.exists()) {
            await setDoc(ref, encodeNotesState(state));
        } else {
            await updateDoc(ref, encodeNotesState(state));
        }
    }
}

function decodeContentType(data: any): ContentType {
    const kind = data?.kind;
    if (typeof kind !== "string") {
        // Если поле kind некорректно — fallback
        return { kind: "text", text: "" };
    }

    switch (kind) {
        case "text":
            return {
                kind: "text",
                text: typeof data.text === "string" ? data.text : "",
            };
        case "audio":
            try {
                return { kind: "audio", url: new URL(data.url) };
            } catch {
                // На случай, если data.url некорректно
                return { kind: "audio", url: new URL("https://invalid.url") };
            }
        case "video":
            try {
                return { kind: "video", url: new URL(data.url) };
            } catch {
                return { kind: "video", url: new URL("https://invalid.url") };
            }
        case "image":
            try {
                return { kind: "image", url: new URL(data.url) };
            } catch {
                return { kind: "image", url: new URL("https://invalid.url") };
            }
        case "link":
            try {
                return { kind: "link", url: new URL(data.url) };
            } catch {
                return { kind: "link", url: new URL("https://invalid.url") };
            }
        default:
            // Если kind неизвестен, можно решить, что делать
            // Здесь будет простейший fallback
            return { kind: "text", text: "" };
    }
}

// Вспомогательная функция для кодирования ContentType в объект
// (для хранения в Firestore)
function encodeContentType(content: ContentType): any {
    switch (content.kind) {
        case "text":
            return {
                kind: "text",
                text: content.text,
            };
        case "audio":
        case "video":
        case "image":
        case "link":
            return {
                kind: content.kind,
                url: content.url.toString(), // Храним в Firestore как строку
            };
    }
}

// Основные функции для NotesState

export function decodeNotesState(data: any): NotesState {
    return {
        version: typeof data.version === "number" ? data.version : 0,
        items: Array.isArray(data.items)
            ? data.items.map((rawItem: any): NotesItem => {
                return {
                    id: typeof rawItem.id === "string" ? rawItem.id : "",
                    content: decodeContentType(rawItem.content),
                };
            })
            : [],
    };
}

export function encodeNotesState(state: NotesState): any {
    return {
        version: state.version,
        items: state.items.map((item) => {
            return {
                id: item.id,
                content: encodeContentType(item.content),
            };
        }),
    };
}