export interface HangmanLayoutConfig {
    // Где по вертикали висит перекладина (0...1 от высоты контейнера)
    crossbarYPercent: number;
    // Левая стойка: процент по ширине (где рисуем вертикальный столб)
    verticalPoleXPercent: number;
    // Правая граница земли (в процентах от ширины)
    groundMaxWidthPercent: number;

    // Где заканчивается перекладина по горизонтали (0...1)
    crossbarXEndPercent: number;

    // Длина верёвки (в долях от высоты контейнера)
    ropeLengthPercent: number;

    // Размер головы (процент от ширины контейнера)
    headSizePercent: number;
    // Толщина обводки головы
    headLineWidth: number;

    // Толщина частей тела (линий)
    bodyLineWidth: number;
    // Высота туловища (доля от высоты контейнера)
    bodyHeightPercent: number;
    // Высота рук
    armHeightPercent: number;
    // Высота ног
    legHeightPercent: number;
}

export const defaultHangmanLayoutConfig: HangmanLayoutConfig = {
    crossbarYPercent: 0.15,
    verticalPoleXPercent: 0.3,
    groundMaxWidthPercent: 0.4,

    crossbarXEndPercent: 0.6,
    ropeLengthPercent: 0.15,

    headSizePercent: 0.15,
    headLineWidth: 3,

    bodyLineWidth: 3,
    bodyHeightPercent: 0.2,
    armHeightPercent: 0.15,
    legHeightPercent: 0.2,
};