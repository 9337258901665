import React from "react";

interface RopeViewProps {
    x: number;
    topY: number;
    length: number;
    lineWidth: number;
}

const RopeView: React.FC<RopeViewProps> = ({ x, topY, length, lineWidth }) => {
    // Верёвка идёт от (x, topY) до (x, topY + length)
    const pathData = `
    M ${x} ${topY}
    L ${x} ${topY + length}
  `;

    return <path d={pathData} stroke="black" strokeWidth={lineWidth} fill="none" />;
};

export default RopeView;