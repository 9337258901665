// LessonListViewModel.ts
import { makeAutoObservable, runInAction } from 'mobx';
import CompositionRoot from '../../../compositionRoot';
import { Lesson } from '../../../domain/models/Lesson';
import { NavigateFunction } from 'react-router-dom';
import {Exercise} from "../../../domain/models";

interface ExerciseSummary {
    id: string;
    info: string;
}

export interface LessonCardViewData {
    id: string;
    name: string;
    exercises: ExerciseSummary[];
}

export class LessonsListViewModel {
    public lessons: LessonCardViewData[] = [];

    private userManager = CompositionRoot.getAppUserManager();
    private storage = CompositionRoot.exercisesStorage;
    private navigate: NavigateFunction;

    constructor(navigate: NavigateFunction) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.navigate = navigate;
    }

    async loadLessons() {
        const currentUser = this.userManager.currentUser;
        if (!currentUser?.exerciseSets) return;

        try {
            const lessonsData = await Promise.all(
                currentUser.exerciseSets.map(async (id: string) => {
                    try {
                        const lesson = (await this.storage.getExerciseSet(id)) as Lesson;
                        return lesson;
                    } catch (error) {
                        console.error('Error loading lesson with id:', id, error);
                        return null;
                    }
                })
            );

            const filteredData = lessonsData.filter((lesson): lesson is Lesson => lesson !== null);
            const lessonViewData = filteredData.map((lesson) => ({
                id: lesson.id,
                name: lesson.name || lesson.id,
                exercises: lesson.exercises.map((ex) => ({
                    id: ex.id,
                    info: this.getExerciseShortInfo(ex),
                })),
            }));

            runInAction(() => {
                this.lessons = lessonViewData;
            });
        } catch (error) {
            console.error('Error loading lessons:', error);
        }
    }

    onLessonCardClick(lessonId: string) {
        this.navigate(`/lessonPractice/${lessonId}`);
    }

    private getExerciseShortInfo(exercise: Exercise): string {
        return `Exercise type: ${exercise.type.kind}`;
    }
}