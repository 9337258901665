
export interface ExerciseOptions {
    learnlang: string;
    theme: string;
    level: string;
    comment: string;
}

export interface ExerciseSentence {
    id: string;
    sentence: string;
    targetWord: string;
}

export enum ExerciseCreationType {
    MissingWord = "missingWord",
    SelectOption = "selectOption",
    WordSearch = "wordSearch",
    Anagram = "anagram",
    Crossword = "crossword",
    FillBlanks = "fillBlanks",
    MatchingPairs = "matchingPairs",
    JustContent = "justContent",
    Table = "table",
    Hangman = "hangman"
}

export const ALL_EXERCISE_CREATION_TYPES = [
    ExerciseCreationType.MissingWord,
    ExerciseCreationType.SelectOption,
    ExerciseCreationType.WordSearch,
    ExerciseCreationType.Anagram,
    ExerciseCreationType.Crossword,
    ExerciseCreationType.Table,
    ExerciseCreationType.FillBlanks,
    ExerciseCreationType.MatchingPairs,
    ExerciseCreationType.JustContent,
    ExerciseCreationType.Hangman,
];