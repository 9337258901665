// ui/viewmodels/ExercisesListPickerViewModel.ts

import { makeAutoObservable, runInAction } from "mobx";
import { Exercise } from "../../../domain/models";
import { ExercisesListInteractor, ExercisesListInteractorImpl } from "../../../domain/AllExercises/ExercisesListInteractor";
import CompositionRoot from "../../../compositionRoot";
import {ExerciseTypeFilter} from "../AllExercises/AllExercisesViewModel";

/**
 * Аналог ExercisesListPickerViewModel из Swift:
 *   @StateObject private var viewModel = ExercisesListPickerViewModel()
 */
export class ExercisesListPickerViewModel {
    exercises: Exercise[] = [];
    selectedFilter: ExerciseTypeFilter = ExerciseTypeFilter.all;

    private interactor: ExercisesListInteractor;

    constructor() {
        makeAutoObservable(this);

        // Аналог Swift: ExercisesListInteractorImpl(storage: serviceLocator.exercisesStorage)
        this.interactor = new ExercisesListInteractorImpl(CompositionRoot.exercisesStorage);
    }

    async load() {
        try {
            const allExercises = await this.interactor.getAllExercises();
            runInAction(() => {
                this.exercises = allExercises;
            });
        } catch (error) {
            console.error("Failed to load exercises in picker:", error);
        }
    }

    // Аналог filteredExercises
    get filteredExercises(): Exercise[] {
        if (this.selectedFilter === ExerciseTypeFilter.all) {
            return this.exercises;
        }
        return this.exercises.filter((exercise) => {
            switch (exercise.type.kind) {
                case "missingWord":
                    return this.selectedFilter === ExerciseTypeFilter.missingWord;
                case "selectOption":
                    return this.selectedFilter === ExerciseTypeFilter.selectOption;
                case "wordSearch":
                    return this.selectedFilter === ExerciseTypeFilter.wordSearch;
                case "anagram":
                    return this.selectedFilter === ExerciseTypeFilter.anagram;
                case "crossword":
                    return this.selectedFilter === ExerciseTypeFilter.crossword;
                case "fillBlanks":
                    return this.selectedFilter === ExerciseTypeFilter.fillBlanks;
                case "matchingPairs":
                    return this.selectedFilter === ExerciseTypeFilter.matchingPairs;
                case "justContent":
                    return this.selectedFilter === ExerciseTypeFilter.justContent;
                case "table":
                    return this.selectedFilter === ExerciseTypeFilter.table;
                default:
                    return false;
            }
        });
    }
}