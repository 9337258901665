import {TableModel} from "../../../../../domain/Exercises/Table/Models/TableModel";

export interface TablePlaceholderUseCaseProtocol {
    updatePlaceholder(
        model: TableModel,
        columnIndex: number,
        rowIndex: number | null,
        userInput: string
    ): TableModel;
    confirmPressed(model: TableModel, columnIndex: number, rowIndex: number | null): TableModel;
    confirmTablePressed(model: TableModel): TableModel;
    editPressed(model: TableModel, columnIndex: number, rowIndex: number | null): TableModel;
}

export class TablePlaceholderUseCase implements TablePlaceholderUseCaseProtocol {
    updatePlaceholder(
        model: TableModel,
        columnIndex: number,
        rowIndex: number | null,
        userInput: string
    ): TableModel {
        const newModel: TableModel = {...model, columns: model.columns.map(c => ({...c}))};

        if (rowIndex === null) {
            // update header
            if (columnIndex < 0 || columnIndex >= newModel.columns.length) return newModel;
            const header = newModel.columns[columnIndex].header;
            if (header.kind === 'editor') {
                newModel.columns[columnIndex].header = {kind: 'editor', value: userInput};
            }
        } else {
            if (columnIndex < 0 || columnIndex >= newModel.columns.length) return newModel;
            const col = newModel.columns[columnIndex];
            if (rowIndex < 0 || rowIndex >= col.items.length) return newModel;
            const item = col.items[rowIndex];
            if (item.kind === 'editor') {
                col.items[rowIndex] = {kind: 'editor', value: userInput};
            }
        }
        return newModel;
    }

    confirmTablePressed(model: TableModel): TableModel {
        const newModel: TableModel = { ...model, columns: model.columns.map(c => ({ ...c })) };

        newModel.columns.forEach((column) => {
            if (column.header.kind === 'editor') {
                column.header = { kind: 'text', value: column.header.value };
            }
            column.items = column.items.map((item) => {
                if (item.kind === 'editor') {
                    return { kind: 'text', value: item.value };
                }
                return item;
            });
        });

        return newModel;
    }

    confirmPressed(model: TableModel, columnIndex: number, rowIndex: number | null): TableModel {
        const newModel: TableModel = {...model, columns: model.columns.map(c => ({...c}))};

        if (rowIndex === null) {
            if (columnIndex < 0 || columnIndex >= newModel.columns.length) return newModel;
            const header = newModel.columns[columnIndex].header;
            if (header.kind === 'editor') {
                newModel.columns[columnIndex].header = {kind: 'text', value: header.value};
            }
        } else {
            if (columnIndex < 0 || columnIndex >= newModel.columns.length) return newModel;
            const col = newModel.columns[columnIndex];
            if (rowIndex < 0 || rowIndex >= col.items.length) return newModel;
            const item = col.items[rowIndex];
            if (item.kind === 'editor') {
                col.items[rowIndex] = {kind: 'text', value: item.value};
            }
        }
        return newModel;
    }

    editPressed(model: TableModel, columnIndex: number, rowIndex: number | null): TableModel {
        const newModel: TableModel = {...model, columns: model.columns.map(c => ({...c}))};

        if (rowIndex === null) {
            if (columnIndex < 0 || columnIndex >= newModel.columns.length) return newModel;
            const header = newModel.columns[columnIndex].header;
            if (header.kind === 'text') {
                newModel.columns[columnIndex].header = {kind: 'editor', value: header.value};
            }
        } else {
            if (columnIndex < 0 || columnIndex >= newModel.columns.length) return newModel;
            const col = newModel.columns[columnIndex];
            if (rowIndex < 0 || rowIndex >= col.items.length) return newModel;
            const item = col.items[rowIndex];
            if (item.kind === 'text') {
                col.items[rowIndex] = {kind: 'editor', value: item.value};
            }
        }
        return newModel;
    }
}