import {ExerciseTable} from "../../../models";
import {TableModel, TableModelColumn} from "./TableModel";
import {TableTextElement} from "./TableTextElement";
import {CellCoordinate} from "./CellCoordinate";

export function exerciseTableToModel(exTable: ExerciseTable): TableModel {
    // 1) Build each TableModel.Column
    const columns: TableModelColumn[] = exTable.columns.map((dbCol) => {
        // convert header string -> TableTextElement.text(...) или .editor(...)
        const headerElem: TableTextElement = { kind: 'text', value: dbCol.header };
        const itemElems: TableTextElement[] = dbCol.items.map((s) => ({ kind: 'text', value: s }));
        return { header: headerElem, items: itemElems };
    });

    // 2) Convert stableCells array of dict → Set<CellCoordinate>
    const stableSet = new Set<CellCoordinate>();
    for (const dict of exTable.stableCells) {
        const col = dict['column'];
        const row = dict['row'];
        if (typeof col === 'number' && typeof row === 'number') {
            stableSet.add(CellCoordinate.create(col, row));
        }
    }

    return {
        columns,
        stableCells: stableSet
    };
}

/**
 * Обратная функция: преобразует TableModel -> ExerciseTable.
 * Аналог Swift extension: `model.exerciseData`
 */
export function modelToExerciseTable(model: TableModel): ExerciseTable {
    // 1) Convert columns -> DBColumn[]
    const dbColumns = model.columns.map((col) => {
        const headerString =
            col.header.kind === 'text' ? col.header.value : col.header.value;
        const items: string[] = col.items.map((item) => item.value);
        return { header: headerString, items };
    });

    // 2) Convert stableCells: Set<CellCoordinate> -> Array<{column, row}>
    const stableArr: Array<{ [key: string]: number }> = [];
    model.stableCells.forEach((coord) => {
        stableArr.push({ column: coord.columnIndex, row: coord.rowIndex });
    });

    return {
        columns: dbColumns,
        stableCells: stableArr
    };
}