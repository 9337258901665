// ===============================
// PracticeHangmanUIState.ts
// ===============================
export interface PracticeHangmanUIState {
    /**
     * Перечень угаданных (или выбранных) букв.
     * Можно хранить как массив строк.
     */
    guessedLetters: string[];

    /**
     * Текущее количество ошибок.
     */
    mistakes: number;

    /**
     * Любые другие поля, которые понадобятся для онлайн-синхронизации
     * (например, можно хранить stage, или состояние победы/поражения и т.п.)
     */

    // ...

    // Если нужно расширять, добавляйте поля
    // Например:
    // isGameOver: boolean;
    // isWin: boolean;
}

/**
 * Пример утилиты для превращения PracticeHangmanUIState
 * в простой JS-объект, который будет сохраняться в Firestore,
 * и наоборот (если нужно).
 */
export class PracticeHangmanUIStateUtils {
    static toFirestore(uiState: PracticeHangmanUIState): any {
        return {
            guessedLetters: uiState.guessedLetters ?? [],
            mistakes: uiState.mistakes ?? 0,
        };
    }

    static fromFirestore(data: any): PracticeHangmanUIState {
        return {
            guessedLetters: Array.isArray(data.guessedLetters) ? data.guessedLetters : [],
            mistakes: typeof data.mistakes === 'number' ? data.mistakes : 0,
        };
    }

    /**
     * Сравнение состояний, чтобы понять, нужно ли обновлять локально.
     */
    static equals(a: PracticeHangmanUIState, b: PracticeHangmanUIState): boolean {
        if (a.mistakes !== b.mistakes) return false;
        if (a.guessedLetters.length !== b.guessedLetters.length) return false;
        for (let i = 0; i < a.guessedLetters.length; i++) {
            if (a.guessedLetters[i] !== b.guessedLetters[i]) return false;
        }
        return true;
    }
}