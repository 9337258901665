import React from "react";
import { HangmanLayoutConfig } from "./HangmanLayoutConfig";

interface HeadWithEyesViewProps {
    cx: number;
    cy: number;
    diameter: number;
    config: HangmanLayoutConfig;
}

// Рисуем круг (голову) и крестики для глаз
const HeadWithEyesView: React.FC<HeadWithEyesViewProps> = ({
                                                               cx,
                                                               cy,
                                                               diameter,
                                                               config,
                                                           }) => {
    const radius = diameter / 2;
    const lineWidth = config.headLineWidth;

    // Глаза
    const leftEyeCenterX = cx - diameter * 0.2;
    const rightEyeCenterX = cx + diameter * 0.2;
    const eyeCenterY = cy - diameter * 0.1;
    const eyeSize = diameter * 0.08;

    // Функция для рисования "крестика" глаза
    const eyePath = (centerX: number, centerY: number) => {
        return `
      M ${centerX - eyeSize} ${centerY - eyeSize}
      L ${centerX + eyeSize} ${centerY + eyeSize}
      M ${centerX + eyeSize} ${centerY - eyeSize}
      L ${centerX - eyeSize} ${centerY + eyeSize}
    `;
    };

    return (
        <g>
            {/* Голова */}
            <circle
                cx={cx}
                cy={cy}
                r={radius}
                stroke="black"
                fill="none"
                strokeWidth={lineWidth}
            />

            {/* Глаза (крестики) */}
            <path
                d={`${eyePath(leftEyeCenterX, eyeCenterY)} ${eyePath(rightEyeCenterX, eyeCenterY)}`}
                stroke="black"
                strokeWidth={2}
                fill="none"
            />
        </g>
    );
};

export default HeadWithEyesView;