// BaseComponents/BaseVStack.tsx
import React from 'react';

interface BaseVStackProps {
    children?: React.ReactNode;
    padding?: number;
    style?: React.CSSProperties; // Добавлено
}

export function BaseVStack({ children, padding, style }: BaseVStackProps) {
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: padding ? `${padding}px` : undefined,
        ...style // Раскладываем style
    }}>
        {children}
    </div>;
}
