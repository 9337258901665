// ===============================
// PracticeHangmanInteractor.ts
// (Добавим в конструктор uiHandler и реакцию на его изменения)
// ===============================
import { makeAutoObservable, reaction } from "mobx";
import { HangmanGameModel, createGameModel, guessLetter, didWin, isGameOver, didLose, displayedWord, stage } from "./HangmanGameModel";
import { PracticeHangmanUIHandler } from "./PracticeHangmanUIHandler";
import { PracticeHangmanUIState } from "./PracticeHangmanUIState";
import {PracticeSessionDelegate} from "../../PracticeSessionDelegate";
import {PracticeHangmanState} from "../../../../ui/components/exercises_types/Hangman/Practice/PracticeHangmanState";
import {Exercise} from "../../../models";

/**
 * Interactor управляет логикой «Виселицы» (Hangman).
 */
export class PracticeHangmanInteractor {
    private gameModel: HangmanGameModel;
    private delegate?: PracticeSessionDelegate;

    // Текущее "UI-состояние" для MVVM
    state: PracticeHangmanState;

    // Коллбек, вызывается при изменении state
    onStateChange?: (newState: PracticeHangmanState) => void;

    // UIHandler — для синхронизации через Firestore
    private uiHandler: PracticeHangmanUIHandler;

    private isSyncingFromServer = false;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: PracticeHangmanUIHandler
    ) {
        if (exercise.type.kind !== "hangman") {
            throw new Error("Not a hangman exercise");
        }
        this.delegate = delegate;

        // Если нам не передали UIHandler «снаружи» — создаём фейковый (без interactor)
        this.uiHandler = uiHandler ?? new PracticeHangmanUIHandler(exercise.id);

        // Создаём локальную модель
        const { word, hint } = exercise.type.data;
        this.gameModel = createGameModel(word, hint);

        // Начальное состояние
        this.state = this.computeState();

        makeAutoObservable(this);

        // Настраиваем реакцию на изменения в UIHandler (guessedLetters, mistakes)
        this.setupBindings();

        // Сразу же пробуем «подтянуть» состояние из UIHandler
        setTimeout(() => {
            this.syncFromUIHandler();
        }, 0);
    }

    /**
     * Когда пользователь пытается угадать букву.
     */
    guessLetter(letter: string) {
        if (isGameOver(this.gameModel)) return;
        this.gameModel = guessLetter(this.gameModel, letter);
        this.updateState();
        // После изменения локальной модели — синхронизируем в UIHandler
        this.syncToUIHandler();
    }

    /**
     * Когда HangmanGameView сообщает «игра завершилась» (выиграл / проиграл).
     */
    handleGameEnd(didWinGame: boolean) {
        this.delegate?.currentStepDone()
    }

    private computeState(): PracticeHangmanState {
        return {
            targetWord: this.gameModel.targetWord,
            hint: this.gameModel.hint,
            guessedLetters: Array.from(this.gameModel.guessedLetters),
            mistakes: this.gameModel.mistakes,
            maxMistakes: this.gameModel.maxMistakes,
            isGameOver: isGameOver(this.gameModel),
            isWin: didWin(this.gameModel),
            displayed: displayedWord(this.gameModel),
            currentStage: stage(this.gameModel),
        };
    }

    private updateState() {
        this.state = this.computeState();
        this.onStateChange?.(this.state);
    }

    private setupBindings() {
        // Если что-то поменялось в UIHandler.uiState — пробуем «притащить» это в локальную модель
        reaction(
            () => ({
                guessedLetters: this.uiHandler.uiState.guessedLetters.slice(),
                mistakes: this.uiHandler.uiState.mistakes,
            }),
            () => {
                if (!this.isSyncingFromServer) {
                    this.syncFromUIHandler();
                }
            }
        );
    }

    /**
     * Синхронизация локальной модели из UIHandler (например, если
     * другой пользователь на другом устройстве угадал буквы).
     */
    private syncFromUIHandler() {
        this.isSyncingFromServer = true;
        try {
            const { guessedLetters, mistakes } = this.uiHandler.uiState;
            // Перезапишем локальную gameModel:
            let model = this.gameModel;
            // 1. guessedLetters
            const newSet = new Set<string>(guessedLetters.map((l) => l.toUpperCase()));
            model = { ...model, guessedLetters: newSet };
            // 2. mistakes
            model = { ...model, mistakes };
            this.gameModel = model;
            // Обновляем локальный state
            this.updateState();
        } finally {
            this.isSyncingFromServer = false;
        }
    }

    /**
     * Синхронизация локальной модели -> UIHandler.
     * Если мы угадали букву локально, нужно отправить это на сервер.
     */
    private syncToUIHandler() {
        const newUIState: PracticeHangmanUIState = {
            guessedLetters: Array.from(this.gameModel.guessedLetters),
            mistakes: this.gameModel.mistakes,
        };
        // Запишем в MobX-поле uiHandler.uiState
        this.uiHandler.uiState = newUIState;
    }
}