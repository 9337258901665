// AnagramLearnViewModel.swift -> AnagramLearnViewModel.ts

import { AnagramState } from "../../../../../domain/Exercises/Anagram/AnagramState";
import {useEffect, useState} from "react";
import {AnagramLearnInteractor} from "../../../../../domain/Exercises/Anagram/AnagramLearnInteractor";

interface AnagramLearnViewModelArgs {
    interactor: AnagramLearnInteractor;
}

export class AnagramLearnViewModel {
    private interactor: AnagramLearnInteractor;
    private _state: AnagramState;
    private callbacks: Array<(state: AnagramState) => void> = [];

    constructor({ interactor }: AnagramLearnViewModelArgs) {
        // Теперь интерактор передается извне, мы просто сохраняем ссылку на него.
        this.interactor = interactor;
        this._state = this.interactor.state;

        this.interactor.onStateChange = (newState) => {
            this._state = newState;
            this.callbacks.forEach(cb => cb(this._state));
        };
    }

    get state() {
        return this._state;
    }

    get displayedWord() {
        return this._state.displayedWord;
    }

    selectLetter(at: number) {
        this.interactor.selectLetter(at);
    }

    removeLetterFromAnswer(at: number) {
        this.interactor.removeLetterFromAnswer(at);
    }

    goToNext() {
        this.interactor.goToNext();
    }

    subscribe(callback: (state: AnagramState) => void) {
        this.callbacks.push(callback);
        return () => {
            this.callbacks = this.callbacks.filter(cb => cb !== callback);
        };
    }
}


// В компонентах React, использующих AnagramLearnViewModel, мы можем сделать хук для подписки.
export function useAnagramLearnViewModel(vm: AnagramLearnViewModel): AnagramState {
    const [state, setState] = useState(vm.state);
    useEffect(() => {
        const unsub = vm.subscribe(setState);
        return unsub;
    }, [vm]);
    return state;
}