import { MatchingPairsCreateUseCaseProtocol } from "./MatchingPairsCreateUseCaseProtocol";
import {Exercise, ExerciseMatchingPairs, MatchingPairItem} from "../../../models";
import { EnhancedMatchingPairInput } from "./EnhancedMatchingPairInput";
import {MediaUploading} from "../../../interfaces/MediaTypes";
import {ImageUploading} from "../../../../data/implementations/Files/ImageUploader";
import CompositionRoot from "../../../../compositionRoot";

export class MatchingPairsCreateUseCaseImpl
    implements MatchingPairsCreateUseCaseProtocol
{
    mediaUploader: MediaUploading
    imageUploader: ImageUploading

    constructor() {
        this.mediaUploader = CompositionRoot.mediaUploading
        this.imageUploader = CompositionRoot.imageUploading
    }

    async loadData() {
        // Если нужна предзагрузка
    }


    async createExercises(inputs: EnhancedMatchingPairInput[]): Promise<Exercise[]> {
        const finalPairs: MatchingPairItem[] = []; // Используем корректный тип MatchingPairItem[]

        for (const pair of inputs) {
            const leftElement = await this.uploadIfNeeded(pair.left);
            const rightElement = await this.uploadIfNeeded(pair.right);

            // Создаём MatchingPairItem для текущей пары
            const finalPair = new MatchingPairItem(leftElement, rightElement);
            finalPairs.push(finalPair);
        }

        // Перемешиваем правые элементы пар, чтобы сделать задание более интересным
        const rightItems = finalPairs.map((p) => p.right);
        this.shuffle(rightItems); // Перемешивание
        const rightItemsOrder = rightItems.map((r) => r.id); // Получаем порядок после перемешивания

        // Создаём ExerciseMatchingPairs
        const exerciseData = new ExerciseMatchingPairs(finalPairs, rightItemsOrder);

        // Создаём Exercise
        const exercise: Exercise = {
            id: crypto.randomUUID().toUpperCase(), // Генерируем уникальный ID
            type: {
                kind: 'matchingPairs', // Указываем тип упражнения
                data: exerciseData,    // Добавляем данные упражнения
            },
        };

        return [exercise]; // Возвращаем массив упражнений (здесь одно упражнение)
    }

    isAllPairsValid(inputs: EnhancedMatchingPairInput[]): boolean {
        if (inputs.length === 0) return false;
        for (const input of inputs) {
            if (!this.isValidElement(input.left) || !this.isValidElement(input.right)) {
                return false;
            }
        }
        return true;
    }

    copyFileToTemp(url: URL): URL {
        // Swift создает копию во временной директории.
        // В JS/TS браузере полноценного доступа к файловой системе нет.
        // Можно просто вернуть url, т.к. нет нужды реально копировать.
        return url;
    }

    // ----------------------------------------------------------------
    // Ниже методы, аналогичные Swift

    private async uploadIfNeeded(element: { text?: string; fileURL?: URL }): Promise<any> {
        let contentURL: string | undefined;

        if (element.fileURL) {
            let data: Blob;
            try {
                const response = await fetch(element.fileURL.toString());
                data = await response.blob();
            } catch (err) {
                console.error("Error fetching file:", err);
                data = new Blob();
            }

            const mimeType = data.type;  // <-- MIME-тип файла

            if (mimeType.startsWith("image/")) {
                contentURL = await this.imageUploader.uploadImage(data, crypto.randomUUID());
            } else if (mimeType.startsWith("audio/") || mimeType.startsWith("video/")) {
                // Можно разделять audio/video отдельно, но у вас всё идёт через mediaUploader
                // Если хотите разный аплоад для аудио/видео, сделайте ещё одну проверку
                const ext = mimeType.split("/")[1] || "bin";
                // Например, audio/mpeg => mpeg,  video/mp4 => mp4
                contentURL = await this.mediaUploader.uploadFile(data, crypto.randomUUID(), ext);
            } else {
                // Прочие форматы, загружаем как "application/octet-stream"
                contentURL = await this.mediaUploader.uploadFile(data, crypto.randomUUID(), "bin");
            }
        }

        const textValue = element.text?.trim();
        const finalText = textValue && textValue.length > 0 ? textValue : undefined;

        return {
            id: crypto.randomUUID().toUpperCase(),
            text: finalText,
            contentURL: contentURL,
        };
    }

    private isValidElement(element: { text?: string; fileURL?: URL }): boolean {
        const hasText = element.text && element.text.trim().length > 0;
        const hasFile = !!element.fileURL;
        return hasText || hasFile;
    }

    private isImageFile(ext: string): boolean {
        return ["png", "jpg", "jpeg"].includes(ext);
    }

    private isMediaFile(ext: string): boolean {
        return ["mp3", "mp4", "mov", "wav", "m4a"].includes(ext);
    }

    private shuffle<T>(array: T[]): void {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }
}