import React from 'react';
import { observer } from 'mobx-react-lite';
import { PracticeTableViewModel } from './PracticeTableViewModel';
import { CellCoordinate } from '../../../../../domain/Exercises/Table/Models/CellCoordinate';
import { ElementView } from '../Base/ElementView';

interface PracticeTableViewProps {
    viewModel: PracticeTableViewModel;
}

export const PracticeTableView: React.FC<PracticeTableViewProps> = observer(({ viewModel }) => {
    const lineColor = 'rgba(128,128,128,0.5)';
    const lineThickness = 2;
    const columnWidth = 120;
    const rowHeight = 60;

    // Пример вычисления maxRows
    const maxRows = viewModel.state.practiceModel.columns.reduce(
        (acc, col) => Math.max(acc, col.items.length),
        0
    );

    /**
     * Основной стиль для ячеек (и заголовка, и тела).
     * - Без левой границы у первого столбца,
     * - С тонкой левой границей у всех последующих столбцов.
     */
    function getCellStyle(colIndex: number): React.CSSProperties {
        return {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: columnWidth,
            height: rowHeight,
            borderLeft: colIndex > 0 ? `${lineThickness}px solid ${lineColor}` : 'none',
        };
    }

    return (
        // Внешний контейнер — для выравнивания по центру
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            {/* Внутренний контейнер: "обёртка" таблицы, чтобы ширина была равна сумме колонок */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'fit-content',       // ширина "по содержимому"
                }}
            >
                {/* Header row (с горизонтальной линией) */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: `${lineThickness}px solid ${lineColor}`, // линия только под заголовками
                    }}
                >
                    {viewModel.state.practiceModel.columns.map((col, colIndex) => {
                        const headerElement = col.header;
                        return (
                            <div
                                key={`header-${colIndex}`}
                                style={getCellStyle(colIndex)}
                            >
                                <ElementView
                                    element={headerElement}
                                    isStable={true}
                                    updatePlaceholder={() => {}}
                                    onTap={() => {}}
                                />
                            </div>
                        );
                    })}
                </div>

                {/* Body rows */}
                {Array.from({ length: maxRows }).map((_, rowIndex) => (
                    <div key={rowIndex} style={{ display: 'flex', flexDirection: 'row' }}>
                        {viewModel.state.practiceModel.columns.map((col, colIndex) => {
                            if (rowIndex < col.items.length) {
                                const item = col.items[rowIndex];
                                const coord: CellCoordinate = CellCoordinate.create(colIndex, rowIndex);
                                const isStable = viewModel.state.practiceModel.stableCells.has(coord);

                                return (
                                    <div
                                        key={`cell-${rowIndex}-${colIndex}`}
                                        style={getCellStyle(colIndex)}
                                    >
                                        <ElementView
                                            element={item}
                                            isStable={isStable}
                                            updatePlaceholder={(newVal) =>
                                                viewModel.updateUserInput(colIndex, rowIndex, newVal)
                                            }
                                            onTap={() => {}}
                                        />
                                    </div>
                                );
                            } else {
                                // Пустая ячейка, если в этой колонке меньше элементов, чем maxRows
                                return (
                                    <div
                                        key={`empty-${rowIndex}-${colIndex}`}
                                        style={getCellStyle(colIndex)}
                                    />
                                );
                            }
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
});